.loadMore {
    text-align: center;
    margin: 4rem 0 0 0;
    button {
        background: #242731;
        height: 5.6rem;
        border-radius: 1.2rem;
        padding: 1.8rem 2.2rem;
        color: #808191;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
        border: none;
        transition: all 0.3s;
        &:hover {
            background: #3f8cff;
            color: #fff;
        }
    }
}

.image {
    width: 4rem;
}

.results {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #808191;
    font-weight: 500;
    margin: 0 0 2rem 2rem;
}

.list {
    & > div > div > div > div {
        & > div {
            height: 100%;
            & > div {
                height: 100%;
                margin-bottom: 0 !important;
                padding-bottom: 1.5rem !important;
                & > div {
                    height: 100%;
                }
            }
        }
    }
}

.tabContent {
    border: 2px solid #3d4151;
    border-radius: 1.2rem;
    overflow: hidden;
    & > div {
        padding: 2rem;
        background: linear-gradient(123.66deg, rgba(178, 179, 189, 0.5) 0.69%, rgba(36, 39, 49, 0) 42.42%);
        height: 100%;
        & > div {
            height: 100%;
            & > div {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }

    .collegeHeader {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        min-height: 7.2rem;
        & > div:nth-of-type(1) {
            width: 6.4rem;
            height: 6.4rem;
            flex: 1 0 6.4rem;
            max-width: 6.4rem;
            border-radius: 50%;
            padding: 0;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;
            background: rgba(0, 0, 0, 0.1);
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        h3 {
            white-space: normal;
            font-size: 1.8rem;
            line-height: 2.2rem;
            color: #fff;
            font-weight: 600;
            margin: 1rem 0;
        }
        .buttons {
            flex: 1 0 8rem;
            max-width: 8rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 -0.3rem 0 1rem;
            button {
                width: 3.3rem;
                height: 3.1rem;
                border-radius: 0.8rem;
                border: 1px solid rgba(228, 228, 228, 0.1);
                margin: 0 0.3rem;
                &.checked {
                    background-color: red;
                }
            }
        }
    }

    .description {
        margin: 2rem 0 0 0;
        .flex {
            display: flex;
            justify-content: space-between;
            .misc,
            .links {
                p {
                    margin: 0;
                    font-weight: 500;
                    a {
                        color: #808191;
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            .links {
                padding-left: 1rem;
                p {
                    text-align: right;
                }
            }
            .misc {
                padding-right: 1rem;
            }
        }
        .buttons {
            button {
                margin-top: 2rem;
                height: 4.4rem;
                border-radius: 0.8rem;
                border: none;
                text-align: center;
                background: #3f8cff;
                transition: all 0.3s;
                font-size: 1.4rem;
                text-align: center;
                display: block;
                font-weight: 500;
                text-shadow: none;
                width: 100%;
                &:hover {
                    color: #3f8cff;
                    background: #fff;
                }
            }
        }
    }
}

/* rwd */
@media only screen and (max-width: 1420px) {
    .tabContent .collegeHeader {
        flex-wrap: wrap;
        & > div:nth-of-type(1) {
            order: 1;
        }
        h3 {
            order: 3;
            flex: 1 0 100%;
            max-width: 100%;
        }
        .buttons {
            order: 2;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .tabContent .collegeHeader {
        flex-wrap: nowrap;
        & > div:nth-of-type(1) {
            order: 1;
        }
        h3 {
            order: 2;
            flex: unset;
        }
        .buttons {
            order: 3;
            flex: 1 0 8rem;
            max-width: 8rem;
        }
    }
}

@media only screen and (max-width: 1050px) {
    .tabContent .collegeHeader {
        flex-wrap: wrap;
        & > div:nth-of-type(1) {
            order: 1;
        }
        h3 {
            order: 3;
            flex: 1 0 100%;
            max-width: 100%;
        }
        .buttons {
            order: 2;
        }
    }
}

@media only screen and (max-width: 756px) {
    .tabContent .collegeHeader {
        flex-wrap: nowrap;
        & > div:nth-of-type(1) {
            order: 1;
        }
        h3 {
            order: 2;
            flex: unset;
        }
        .buttons {
            order: 3;
            flex: 1 0 8rem;
            max-width: 8rem;
        }
    }
}

@media only screen and (max-width: 690px) {
    .tabContent .collegeHeader {
        flex-wrap: wrap;
        & > div:nth-of-type(1) {
            order: 1;
        }
        h3 {
            order: 3;
            flex: 1 0 100%;
            max-width: 100%;
        }
        .buttons {
            order: 2;
        }
    }
}

@media only screen and (max-width: 575px) {
    .tabContent .collegeHeader {
        flex-wrap: nowrap;
        & > div:nth-of-type(1) {
            order: 1;
        }
        h3 {
            order: 2;
            flex: unset;
        }
        .buttons {
            order: 3;
            flex: 1 0 8rem;
            max-width: 8rem;
        }
    }
}

@media only screen and (max-width: 380px) {
    .tabContent .collegeHeader {
        flex-wrap: wrap;
        & > div:nth-of-type(1) {
            order: 1;
        }
        h3 {
            order: 3;
            flex: 1 0 100%;
            max-width: 100%;
        }
        .buttons {
            order: 2;
        }
    }
}
