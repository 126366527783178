.result{ width:100vw; min-height:100vh; display:flex; flex-direction:column; justify-content:center; align-items:center; background-image:url('../../resources/images/bg-error.jpg'); background-repeat:no-repeat; background-position:center; background-size:cover;
    &>div{ margin:0; }
    .image{ padding-top:14rem; display:block; background:url('../../resources/images/top100-logo.png') no-repeat center top; background-size:auto 10rem;
        &:after{ content:'404'; font-size:28rem; line-height:23rem; color:#fff; font-weight:600; }
        svg{ display:none !important; }
    }
    h1{ font-size:5.4rem; line-height:6rem; color:#fff; margin:2rem 0 1rem 0; font-weight:600; }
    p{ font-size:1.6rem; line-height:2.4rem; color:#fff; margin:1rem 0; font-weight:400; }
    button{ height:5.6rem; border-radius:1.2rem; background:#3F8CFF; border:none; padding:1rem 3.5rem; font-weight:600; color:#fff; font-size:1.4rem; text-shadow:none; margin:4rem 0 0 0; transition:all .3s;
        &:hover{ color:#3F8CFF; background:#fff; }
    }
}


/* rwd */

@media only screen and (max-width: 800px) {
    .result{ 
        .image{ padding-top:12rem; background-size:auto 8rem;
            &:after{ font-size:20rem; line-height:16rem;}
        }
    }
}

@media only screen and (max-height: 750px) {
    .result{ 
        .image{ padding-top:12rem; background-size:auto 8rem;
            &:after{ font-size:20rem; line-height:16rem;}
        }
    }
}

@media only screen and (max-width: 550px) {
    .result{ 
        .image{ padding-top:10rem; background-size:auto 6rem;
            &:after{ font-size:16rem; line-height:11rem;}
        }
        h1{ font-size:4.4rem; line-height:5rem; }
    }
}

@media only screen and (max-height: 600px) {
    .result{ 
        .image{ padding-top:10rem; background-size:auto 6rem;
            &:after{ font-size:16rem; line-height:11rem;}
        }
        h1{ font-size:4.4rem; line-height:5rem; }
    }
}

@media only screen and (max-width: 420px) {
    .result{ 
        .image{ padding-top:10rem; background-size:auto 6rem;
            &:after{ font-size:12rem; line-height:8rem;}
        }
        h1{ font-size:2.6rem; line-height:3.6rem; }
        button{ margin-top:2rem; }
    }
}