.header{ height:25rem; width:100%; padding:3rem; display:flex; justify-content:center; align-items:center; background:#153456;
  div{ height:100%; width:100%; }
  img{ width:100%; height:100%; object-fit:contain; }
}

.sport{ display:flex; align-items:center; font-size:1.4rem; line-height:2rem; color: #808191; font-weight:500; margin:0 3.5rem; padding:3rem 0 0 0;
  img{ width:3.2rem; height:3.2rem; margin-right:1rem; }
}

.name{ padding:0.6rem 0 2rem 0; margin:0 3.5rem; border-bottom:1px solid rgba(255,255,255,.15);
  h1{ font-size:2.6rem; line-height:3.2rem; margin:0; color:#fff; font-weight:500; font-family:'Poppins', sans-serif; }
}

.data{ padding:2rem 0; margin:0 3.5rem; border-bottom:1px solid rgba(255,255,255,.15);
  h2{ font-size:1rem; line-height:1.6rem; color: #808180; font-weight:700; font-family: 'Poppins', sans-serif; margin:0 0 0.3rem 0; text-transform:uppercase; }
  p{ font-size:1.6rem; line-height:2.4rem; color: #fff; font-weight:500; font-family: 'Poppins', sans-serif; margin:0 0 0 0; }
  &:last-child{ border-bottom:none; padding-bottom:4rem; }
}


/* rwd */
@media only screen and (max-width: 950px){
  .header{ flex:1 0 24rem; max-width:24rem; height:100%; background:transparent; }
  .body{ margin-left:-3rem; }
}


@media only screen and (max-width: 600px){
  .body{ margin:-2rem 0 0 0; }
}


@media only screen and (max-width: 500px){
  .name h1{ font-size:2rem; line-height:2.6rem; }
}