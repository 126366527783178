.header {
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;
        .headerBackgroundImage {
            // background-image: url('../../resources/images/headerEvents.jpg'); // loaded dynamically
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        button {
            height: 5.6rem;
            border-radius: 1.2rem;
            border: 1px solid #555;
            font-size: 1.4rem;
            font-weight: 600;
            margin: 0 0 0 -1px;
            &:hover,
            &[ant-click-animating-without-extra-node='true'] {
                background: #3f8cff;
                border: 1px solid #3f8cff !important;
                color: #fff;
            }
            &.share,
            &.upload {
                margin: 0 0 0 0.8rem;
                display: flex;
                align-items: center;
                span {
                    width: 1.7rem;
                    height: 1.7rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0.7rem 0 0;
                    svg {
                        display: block !important;
                        width: 1.7rem;
                        height: 1.7rem;
                        z-index: 3;
                        color: transparent;
                    }
                }
            }
        }
    }
}

.player {
    position: relative;
    header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .picture {
            flex: 1 0 25.6rem;
            max-width: 25.6rem;
            & > div {
                width: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        .playerInfo {
            flex: 1;
            padding: 0 2rem;
            h1 {
                font-size: 4.6rem;
                line-height: 5rem;
                color: #fff;
                font-weight: 600;
                margin: 4.5rem 0 0 0;
            }
            ul.stats {
                padding: 0;
                margin: 2.5rem 0 0 0;
                list-style: none;
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                li {
                    padding: 0 2.5rem 0 0;
                    margin: 0 2rem 0 0;
                    border-right: 1px solid rgba(228, 228, 228, 0.3);
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                    white-space: nowrap;
                    font-weight: 500;
                    label {
                        font-size: 1.3rem;
                        line-height: 1.6rem;
                        display: block;
                        text-transform: uppercase;
                        color: #808191;
                        margin: 0 0 0.5rem 0;
                        font-weight: 600;
                    }
                    &:last-of-type {
                        border-right: none;
                        padding-right: 0;
                        margin-right: 0;
                    }
                }
            }
            .misc {
                p {
                    margin: 1rem 0 0 0;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    color: #fff;
                    font-weight: 500;
                }
            }
        }
        .playerStats {
            margin: 3.5rem 0;
            flex: 1 0 100%;
            max-width: 100%;
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                list-style: none;
                li {
                    margin: 0.5rem;
                    padding: 1.2rem 2rem;
                    border-radius: 1.2rem;
                    background: rgba(36, 39, 49, 1);
                    color: rgba(255, 255, 255, 0.4);
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    font-weight: 500;
                    label {
                        color: #fff;
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
    h2 {
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 500;
        color: #fff;
        margin: 3rem 0;
    }
}

.playerCode {
    background: #242731;
    border-radius: 1.2rem;
    border: 1px solid rgba(228, 228, 228, 0.1);
    padding: 5rem 3rem;
    margin: 4rem 0 10rem 0;
    h2 {
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 500;
        text-align: center;
        margin: 0;
    }
    p {
        font-size: 1.4rem;
        line-height: 2.3rem;
        text-align: center;
        margin: 2rem auto 2rem auto;
        color: #808191;
        max-width: 27rem;
    }
    div {
        margin: 3rem auto 0 auto;
        width: 17rem !important;
        height: 17rem !important;
        display: block;
    }
}

/* mobile */
.mobileHeaderBackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 33rem;
    display: none;
    .headerBackgroundImage {
        background-image: url('../../resources/images/headerEvents-mob.jpg');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
    .headerBackgroundGradient {
        background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}
.topNavi {
    display: flex;
    justify-content: space-between;
    margin: 0 -0.5rem 2.5rem -0.5rem;
    .buttonDiv {
        flex: 1 0 50%;
        max-width: 50%;
        padding: 0 0.5rem;
        button {
            border: none;
            width: 100%;
            height: 5.6rem;
            background: #242731;
            color: #a0a1ab;
            border-radius: 1.2rem;
            font-size: 1.6rem;
            line-height: 2rem;
            padding: 1.8rem 2rem 1.8rem 5.6rem;
            position: relative;
            text-align: left;
            font-weight: 500;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
                &:before {
                    opacity: 1;
                }
            }
            &:before {
                content: '';
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                left: 1.7rem;
                top: 0;
                width: 3rem;
                height: 100%;
                opacity: 0.4;
            }
        }
        .events:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-document.svg');
            background-size: 1.8rem auto;
            background-position: 0.6rem center;
        }
        .tasks:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-tasks.svg');
            background-size: 3rem auto;
            background-position: center;
        }
    }
}

/* rwd */
@media only screen and (max-width: 1100px) {
    .player header {
        .playerInfo h1 {
            margin-top: 3.5rem;
        }
        .picture {
            flex: 1 0 18rem;
            max-width: 18rem;
            height: 18rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .mobileHeaderBackground {
        display: block;
    }
}

@media only screen and (max-width: 550px) {
    .player {
        h2 {
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
        }
        header {
            flex-direction: column;
            align-items: center;
            .playerInfo {
                padding: 0;
                h1 {
                    margin-top: 1rem;
                    font-size: 3rem;
                    line-height: 3.6rem;
                    text-align: center;
                }
                ul {
                    justify-content: center;
                }
                .misc {
                    text-align: center;
                }
            }
            .playerStats {
                order: 2;
                ul {
                    justify-content: center;
                    li {
                        font-size: 1.5rem;
                        padding: 1rem 1.7rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .player .records .recordsData .record {
        flex: 1 0 33.33%;
        max-width: 33.33%;
    }
    .mobileHeaderBackground {
        display: block;
    }
    .share {
        background-image: url('../../resources/images/ico-share.svg') !important;
    }
}
