h2 {
    margin: 6rem 0 4rem 0;
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 500;
    color: #fff;
}

.multioption {
    & > div > div > div > span {
        align-items: center;
    }

    & > div > div > div {
        margin-bottom: 0.4rem;
    }

    & > div {
        padding-top: 1.2rem !important;
    }
}
