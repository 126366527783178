.title {
    font-size: 2.6rem;
    font-weight: 500;
}

.steps {
    margin-left: 2rem;
    margin-top: 2rem;
    svg {
        color: #fff;
    }
}

.buttons {
    padding-top: 4rem;
    padding-bottom: 0;
    .next {
        height: 5.6rem;
        background: #3f8cff;
        border-radius: 1.2rem;
        color: #fff;
        font-weight: 500;
        padding: 2rem 4rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
        margin-top: 2rem;
        &:hover {
            background: #095cb5 !important;
            border-color: #095cb5 !important;
            color: #fff;
        }
    }
    .back {
        height: 5.6rem;
        background: #242731;
        border-color: #242731;
        border-radius: 1.2rem;
        color: #808191;
        font-weight: 500;
        padding: 2rem 4rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
        margin-top: 2rem;
        margin-left: 2rem;
        &:hover {
            background: #20232c !important;
            border-color: #20232c !important;
            color: #808191;
        }
    }
}

.tableLayout {
    display: flex;
    flex-direction: column;
    header {
        flex: 1 0 2.6rem;
        max-height: 2.6rem;
    }
    .table {
        flex: 1 0 calc(100vh - 34rem);
        max-height: calc(100vh - 34rem);
        overflow: auto;
        & > div {
            height: 100%;
            & > div {
                height: 100%;
                & > div {
                    height: 100%;
                    & > div {
                        height: 100%;
                        & > div {
                            overflow: auto !important;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .buttons {
        flex: 1 0 11.6rem;
        max-height: 11.6rem;
    }
}

.dragger {
    height: 16rem;
    & > span > div:nth-of-type(1) {
        border: 1px dashed #636878 !important;
        border-radius: 1.2rem !important;
        background: #2d3038 !important;
        transition: all 0.3s;
        &:hover {
            border-color: #fff !important;
        }
    }
    button {
        border: none;
        padding: 0;
        font-size: 1.4rem;
        font-weight: 500;
        span {
            display: block;
            margin: 0 auto 1.5rem auto;
            width: 2.8rem;
            height: 2rem;
            svg {
                width: inherit;
                height: auto;
            }
        }
        &:hover {
            color: #fff;
        }
        &:focus {
            color: #fff;
        }
    }
    & > span > div:nth-of-type(2) {
        margin: 1.5rem 0 0 0;
        & > div > div {
            margin-top: 0;
            & > div {
                padding: 0;
                font-weight: 500;
                font-size: 1.4rem;
                & > div {
                    justify-content: flex-start;
                }
            }
            &:hover > div {
                background: transparent;
            }
        }
    }
}

.template {
    float: right;
    height: 5rem;
    border-radius: 1.2rem;
    border: 1px solid rgba(228, 228, 228, 0.2);
    &[ant-click-animating-without-extra-node='true'] {
        background: #3f8cff;
        border-color: #3f8cff;
    }
    &:hover {
        background: #3f8cff;
        border-color: #3f8cff;
        color: #fff;
    }
    &:focus {
        background: transparent;
        border: 1px solid rgba(228, 228, 228, 0.2) !important;
        color: #fff;
    }
    width: 180px;
    color: white;
}

.another {
    float: right;
    margin-right: 2rem;
    height: 5rem;
    border-radius: 1.2rem;
    border: 1px solid rgba(228, 228, 228, 0.2);
    &[ant-click-animating-without-extra-node='true'] {
        background: #3f8cff;
        border-color: #3f8cff;
    }
    &:hover {
        background: #3f8cff;
        border-color: #3f8cff;
        color: #fff;
    }
    width: 220px;
    color: white;
}

.inviteSelected {
    float: right;
    margin-right: 2rem;
    height: 5rem;
    border-radius: 1.2rem;
    border: 1px solid rgba(228, 228, 228, 0.2);
    &[ant-click-animating-without-extra-node='true'] {
        background: #3f8cff;
        border-color: #3f8cff;
    }
    &:hover {
        background: #3f8cff;
        border-color: #3f8cff;
        color: #fff;
    }
    width: 160px;
    color: white;
}

.issues {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 600;
    color: #db3737;
}

.importedUsers {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 600;
    color: #7fba7a;
}

.alreadyExist {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 600;
    color: #cf8e2c;
}

.playersSelected {
    float: right;
    font-size: 14px;
    font-weight: 500;
    color: #808191;
}

.table {
    background: transparent;
    margin: 2rem 0 0 0;
    border-radius: 0.8rem;
    thead {
        tr {
            th {
                background: #2d3038;
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: #fff;
                border-bottom: none;
                padding: 2rem 1.5rem;
                &:before {
                    display: none;
                }
            }
        }
    }
    tbody {
        & > tr {
            & > td {
                padding: 1.5rem 1.5rem;
                border-bottom: none;
                & > div {
                    margin: 0;
                }
                a {
                    color: inherit;
                    &:hover {
                        color: inherit;
                        text-decoration: underline;
                    }
                }
                input[type='text'],
                input[type='email'],
                input[type='number'],
                input {
                    background-color: transparent !important;
                    border: 1px solid transparent;
                    padding: 0 1rem;
                    height: 3.4rem;
                    border-radius: 0.4rem;
                }
            }
            &:nth-of-type(odd) td {
                background: #1f2128;
            }
            &:nth-of-type(even) td {
                background: #242731;
            }
            &:last-of-type {
                td:first-of-type {
                    border-bottom-left-radius: 1.2rem;
                }
                td:last-of-type {
                    border-bottom-right-radius: 1.2rem;
                }
            }
        }
    }
}

/* rwd */
@media only screen and (max-width: 767px) {
    .title {
        position: absolute;
        left: 2rem;
        right: 2rem;
        text-align: center;
    }
    .template {
        display: none;
    }
    .another {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .playersSelected {
        margin-top: 1rem;
        float: none;
        display: block;
    }
    .table {
        margin: 3rem 0 0 0;
    }
}

@media only screen and (max-width: 500px) {
    .title {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 400px) {
    .title {
        text-align: right;
    }
}

.rowWithErrors {
    color: red;
}
