.editableTable {
    tr {
        cursor: pointer;
    }
}



.statistics{
  h2{ display:flex; justify-content:space-between; align-items:center;
    button {
      height: 5.5rem;
      border-radius: 1.2rem;
      border: 1px solid rgba(228, 228, 228, 0.1);
      display: flex;
      align-items: center;
      span {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
          svg {
              width: 2rem;
              height: 2rem;
          }
      }
      &:hover {
          background: #3f8cff;
          color: #fff;
          border: 1px solid #3f8cff;
      }
    }
  }

  .table{ padding:0 0 4rem 0; overflow-x:auto;
    table{ background:#1F2128; margin:0 0 0 0; min-width:80rem;
      thead{
        tr{
          th{ font-size:1.1rem; line-height:1.6rem; color:#fff; text-transform:uppercase; border-bottom:none; text-align:left; padding:2rem 1.5rem; }
        }
      }
      tbody{
        &>tr{
          &>td{ padding:1.5rem 1.5rem; border-bottom:none; color:#fff; text-align:left;
            a{ color:inherit;
              &:hover{ color:inherit; text-decoration:underline; }
            }
          }
          &:nth-of-type(odd) td{ background:#1F2128; }
          &:nth-of-type(even) td{ background:#242731; }
          &:last-of-type{
            td:first-of-type{ border-bottom-left-radius:1.2rem; }
            td:last-of-type{ border-bottom-right-radius:1.2rem; }
          }
        }
      }
    }
  
    ul{
      li{ font-weight:600;
        a{ transition:all .3s; }
      }
    }
  }
}

/* rwd */
@media only screen and (max-width: 400px) {
  .statistics h2{ flex-direction:column; align-items:flex-start;
    button{ margin-top:1.5rem; }
  }
}