.form {
    max-width: 75rem;
    margin: 0 0 8rem 0;

    h2 {
        margin: 6rem 0 4rem 0;
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 500;
        color: #fff;
    }

    .buttons {
        margin: 4rem 0 0 0;
    }

    .multioption {
        & > div > div > div > span {
            align-items: center;
        }

        & > div > div > div {
            margin-bottom: 0.4rem;
        }

        & > div {
            padding-top: 1.2rem !important;
        }
    }
}

.label {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-weight: bold;
}

.labelContent {
    font-size: 1.7rem;
}
