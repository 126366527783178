.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        font-size: 4rem;
        line-height: 5rem;
        color: #ffffff;
        font-weight: 600;
        margin: 0.5rem 0;
    }
    button {
        height: 5.6rem;
        border-radius: 1.2rem;
        border: 1px solid rgba(228, 228, 228, 0.1);
        font-size: 1.4rem;
        line-height: 2.4rem;
        color: #fff;
        font-weight: 700;
        transition: all 0.3s;
        span {
            width: 1.7rem;
            height: 1.7rem;
            margin-right: 0.7rem;
            color: transparent;
            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &:hover {
            background-color: #3f8cff;
            color: #fff;
        }
    }
    .live {
        margin-right: 0.8rem;
    }
    & > div {
        padding-left: 2rem;
        display: flex;
    }
}
.subheader {
    width: 100%;
    h2{
        font-size:1.8rem;
        margin:0;
    }
}

.selected span {
    color: #5f75ee !important;
}

.unselected span {
    color: #808191 !important;
}

.select {
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
}

.table {
    margin-top: -1.5rem;
    & > div > div > div {
        background: transparent;
    }
    & > div > div > ul {
        margin-bottom: 8rem !important;
    }
    table {
        margin-top: 2rem;
        thead {
            background: rgba(35, 37, 44, 0.3);
            th {
                color: #fff;
                font-size: 1.2rem;
                font-weight: 500;
                letter-spacing: 1px;
                text-transform: uppercase;
                background-color: #2a2e3a;
                border: none;
                padding: 1.7rem 1.7rem;
                white-space: break-spaces;
                &:before {
                    display: none;
                }
            }
            th:nth-of-type(1) {
                width: 10.2rem !important;
                min-width: 10.2rem !important;
                max-width: 10.2rem !important;
            }
        }
        tbody {
            tr {
                td {
                    border-bottom: none;
                    padding: 2rem 1.5rem;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    font-weight: 500;
                    img {
                        margin: -2rem 0;
                    }
                    & > a {
                        height: 100%;
                        display: block;
                        & > div {
                            height: 100%;
                            display: block;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                    & > div {
                        height: 100%;
                        display: block;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                &:hover {
                    &:nth-of-type(odd) td {
                        background-color: #1f2128;
                    }
                    &:nth-of-type(even) td {
                        background-color: #2a2e3a;
                    }
                }
                td:nth-of-type(1) {
                    width: 10.2rem !important;
                    min-width: 10.2rem !important;
                    max-width: 10.2rem !important;
                }
            }
            tr:nth-of-type(odd) td {
                background-color: #1f2128;
            }
            tr:nth-of-type(even) td {
                background-color: #2a2e3a;
            }
        }
    }
}

/* rwd */
@media only screen and (max-width: 1380px) {
    .tabs {
        & > div > div {
            flex-wrap:wrap;
            .filter {
                margin-top: 2rem;
                .select {
                    margin-left: 0;
                    margin-right: 1.5rem;
                }
            }
            &:nth-of-type(1){
                &>div:nth-of-type(1){ flex:1 0 calc(100% - 50px); max-width:calc(100% - 50px); }
                &>div:nth-of-type(2){ flex:1 0 46px; max-width:46px; position:absolute; right:0;
                    button{ padding:2px 16px; }
                }
                &>div:nth-of-type(3){ flex:1 0 100%; max-width:100%; }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .select {
        margin-left: 0;
        margin-right: 1.5rem;
    }
}

@media only screen and (max-width: 900px) {
    .header p {
        font-size: 3rem;
        line-height: 4rem;
    }
    .subheader  h2{
        font-size:1.4rem;
    }
}

@media only screen and (max-width: 767px) {
    .share {
        background-image: url('../../../../resources/images/ico-share.svg') !important;
    }
}

@media only screen and (max-width: 550px) {
}

@media only screen and (max-width: 360px) {
    .select {
        width: 12rem !important;
    }
}
