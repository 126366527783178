.toolbar, .mobHeader {
    margin:7rem 0 0 0;
    h1 {
        font-size: 4.8rem;
        line-height: 6.4rem;
        color: #fff;
        font-weight: 700;
        margin: 0 0 0 0;
    }
    p {
        font-size: 1.4rem;
        line-height: 2rem;
        color: #fff;
        font-weight: 500;
        margin: 0 0 0 0;
    }
}

.mobHeader{ display:none; margin:2rem 0 4rem 0; }

.table {
    padding: 0 0 4rem 0;
    overflow-x: auto;
    table {
        background: #1f2128;
        margin: 0 0 0 0;
        thead {
            tr {
                th {
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                    color: #fff;
                    font-weight:700;
                    border-bottom: none;
                    background:#2D3038;
                    padding: 2rem 1.5rem;
                    text-align:left;
                }
            }
        }
        tbody {
            & > tr {
                & > td {
                    padding: 1.5rem 1.5rem;
                    border-bottom: none;
                    a {
                        color: inherit;
                        &:hover {
                            color: inherit;
                            text-decoration: underline;
                        }
                    }
                    button{ border-radius:0.4rem; }
                }
                &:nth-of-type(odd) td {
                    background: #1f2128;
                }
                &:nth-of-type(even) td {
                    background: #242731;
                }
                &:last-of-type {
                    td:first-of-type {
                        border-bottom-left-radius: 1.2rem;
                    }
                    td:last-of-type {
                        border-bottom-right-radius: 1.2rem;
                    }
                }
            }
        }
    }

    ul {
        li {
            font-weight: 600;
            a {
                transition: all 0.3s;
            }
        }
    }
}


/* tags */
.tagOrange, .tagGreen, .tagRed, .tagYellow{ border:none; height:2.6rem; padding:0.4rem 1rem; font-size:1.3rem; line-height:1.8rem; white-space:nowrap; text-align:center; border-radius:0.4rem; }
.tagOrange{ color:#e89a3c; background:#4b2d21; }
.tagRed{ color:#F5617E; background:#823947; }
.tagGreen{ color:#48A67A; background:#1C553A; }
.tagYellow{ color:#e8d639; background:#4b4621; }



/* rwd */
@media only screen and (max-width: 767px) {
    .toolbar{ display:none; }
    .mobHeader{ display:block; }
}