.btnDelete {
    height: 5.6rem;
    width: 17rem;
    border-radius: 0.4rem;
    border: none;
    background: #c51134;
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 1.5rem 2.4rem;
    color: #fff;
    font-weight: 500;
    margin: 3.2rem 0 1.2rem 0;
    border-radius: 1.2rem;
    transition: all 0.3s;
    &:hover {
        color: #c51134;
        background: #fff;
    }
}

.row {
    margin-bottom: 2rem;
}
.label {
    margin-top: 1.5rem;
    color: #fff !important;
    font-size: 1.7rem;
    div > input {
        color: #fff !important;
    }
}
.labelAux {
    color: #fff !important;
    font-size: 1.7rem;
}

.collapse {
    border: none;
    background: rgba(53, 56, 69, 0.4);
    border-radius: 1.2rem;
    & > div {
        border-bottom: none !important;
    }
    .panel {
        & > div:nth-of-type(1) {
            padding: 1.2rem;
            padding-right: 2.5rem;
            padding-left: 1.5rem;
            background: #353845;
            border-radius: 1.2rem !important;
            & > span:nth-of-type(1) {
                order: 10;
                margin-right: 0;
                float: right;
                padding-top: 1.5rem;
                svg {
                    width: 2rem;
                    height: 1.2rem;
                }
            }
            .data {
                flex: 1;
                order: 2;
                padding: 0 2rem 0 0;
                h3 {
                    font-size: 1.7rem;
                    line-height: 2.2rem;
                    color: #fff;
                    font-weight: 600;
                    margin: 0;
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                p {
                    font-size: 1.3rem;
                    line-height: 2.2rem;
                    color: #686868;
                    font-weight: 600;
                    margin: 0;
                    span {
                        padding-right: 0.8rem;
                        margin-right: 0.8rem;
                        border-right: 1px solid #686868;
                        line-height: 1.4rem;
                        display: inline-block;
                    }
                    span:last-of-type {
                        margin-right: 0;
                        padding-right: 0;
                        border-right: none;
                    }
                }
            }
            & > div:nth-last-of-type(1) {
                order: 4;
                display: flex;
                ul {
                    display: flex;
                }
                button {
                    margin: 0 1.2rem;
                    width: 3.3rem;
                    height: 3.1rem;
                    border: 1px solid rgba(228, 228, 228, 0.1);
                    border-radius: 0.8rem;
                    padding: 0;
                    svg {
                        width: 1.4rem;
                        height: auto;
                    }
                }
            }
            .extra {
                display: flex;
                align-items: center;
            }
        }
        & > div:nth-of-type(2) {
            padding: 2.5rem 2.5rem 1.2rem 2.5rem;
            background: #242731;
            border: 2px solid #353845;
            border-bottom-right-radius: 1.2rem;
            border-bottom-left-radius: 1.2rem;
            border-top: none;
            margin-top: -1rem;
            .stats {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -2rem;
                p {
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    color: #fff;
                    margin: 0 2rem;
                    font-weight: 600;
                    label {
                        font-size: 1.2rem;
                        line-height: 1.8rem;
                        color: #808080;
                        font-weight: 500;
                    }
                }
            }
            .hr {
                margin: 1.5rem -2rem;
                border-top: 1px solid #353845;
            }
        }
    }
    .link {
        margin-left: 2rem;
    }
}

.sectionTitle {
    color: #fff;
    font-weight: bold;
}

.date {
    text-align: end;
    margin-top: auto;
    margin-bottom: auto;
}
