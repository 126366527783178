.empty {
    font-size: 1.8rem;
    margin: 1rem 0 3rem 0;
    text-align: center;
    font-weight: 300;
}

.list {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    display: block;
    margin-left: auto;
    margin-right: auto;
    .user {
        display: flex;
        align-items: center;
        margin: 0 2rem 0 0;
        p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
                display: block;
                margin-top: 0.2rem;
            }
        }
        .avatar {
            flex: 1 0 6.4rem;
            max-width: 6.4rem;
            min-width: 6.4rem;
            margin-right: 1rem;
        }
        .email {
            color: #808191;
        }
    }
}

.addUserButton {
    float: right;
    color: #3f8cff;
    margin-top: -5rem;
    &:before {
        background: transparent !important;
    }
    &:focus,
    &:hover {
        background: transparent;
    }
}

/* rwd */
@media only screen and (max-width: 550px) {
    .list {
        flex-direction: column;
        .user {
            margin: 0;
        }
    }
    .addUserButton {
        max-width: 15rem;
        margin: 1rem 0 0 2.5rem;
    }
}

@media only screen and (max-width: 400px) {
    .btn {
        padding: 1rem 3rem !important;
    }
}
