.empty {
    font-size: 1.8rem;
    margin: 1rem 0 3rem 0;
    text-align: center;
    font-weight: 300;
    color: #fff;
}

.tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filter {
        margin: 0.8rem 1rem 2.5rem 1rem;
        & > div {
            background: #242731 !important;
        }
        & > span {
            background: #242731 !important;
        }
    }

    .myNotifications {
        margin: 2.3rem 0 4rem 1rem;
    }

    .flex {
        display: flex;
        align-items: flex-start;
        .flex {
            flex-wrap: wrap;
        }
    }
}

.searchPanel {
    flex: 1;
    .search {
        margin: 0.2rem 0.3rem 2rem;
        max-width: 50rem;
        & > span > span {
            border: none;
        }
        span {
            border-color: transparent !important;
            border: none !important;
        }

        input {
            width: 100% !important;
            font-size: 1.4rem;
            line-height: 2rem;
            color: #fff;
            font-weight: 600;
            padding: 0.8rem 2rem 0.8rem 4rem !important;
            height: 3.6rem;
            background-color: transparent !important;
            background-image: url('../../../resources/images/ico-search.svg');
            background-repeat: no-repeat;
            background-position: left center;
        }
        & > span > span:nth-of-type(2) {
            left: 0;
            background-color: transparent;
            color: #fff;
            button {
                visibility: visible;
                border: none !important;
                width: auto !important;
                & > span {
                    display: none !important;
                }
            }
        }
    }
}

.loadMore {
    text-align: center;
    margin: 4rem 0 0 0;
    button {
        background: #242731;
        height: 5.6rem;
        border-radius: 1.2rem;
        padding: 1.8rem 2.2rem;
        color: #808191;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
        border: none;
        transition: all 0.3s;
        &:hover {
            background: #3f8cff;
            color: #fff;
        }
    }
}
