/* header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;

        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
}

/* mobile search */
.mobileSearch {
    width: 40rem;
    .searchMob {
        & > span {
            border-radius: 1.2rem;
            overflow: hidden;
            & > span {
                border: none;
                padding: 0;
                background: #32343a !important;
                box-shadow: none !important;
                input {
                    padding: 0 0 0 1rem;
                }
            }
            & > span:nth-of-type(1) {
                span {
                    width: 1.4rem;
                }
            }
            & > span:nth-of-type(2) {
                button {
                    background-image: url('../../resources/images/ico-search.svg') !important;
                    background-position: center !important;
                }
            }
        }
    }
}

/* search */
.search {
    padding: 0;
    width: 100% !important;
    max-width: 48rem;
    & > div {
        width: 100%;
        & > div {
            width: 100%;
            & > span {
                width: 100%;
                margin-right: 0;
            }
        }
    }
    & > div > div > span > span > span {
        border: none;
        & > span {
            border: none;
        }
        & > span:nth-of-type(1) {
            background-color: #32343a;
            border-radius: 0.8rem !important;
            box-shadow: none !important;
        }
        & > span:nth-of-type(2) {
            padding: 0 0 0 0.8rem !important;
            background-color: transparent;
            button {
                border: none;
                height: 4.4rem;
                background: #3f8cff;
                color: #fff;
                border-radius: 0.8rem !important;
                padding: 1rem 2.5rem !important;
                line-height: 2.4rem;
                font-size: 1.4rem;
                font-weight: 700;
                transition: all 0.3s;
                &:hover {
                    background: #fff;
                    color: #3f8cff;
                    span {
                        color: #3f8cff;
                    }
                }
                span {
                    transition: all 0.3s;
                    color: #fff;
                }
            }
        }
    }
    input {
        width: 100% !important;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: #fff;
        font-weight: 600;
        padding: 0.6rem 1.4rem 0.6rem 4rem !important;
        height: 3.1rem;
        background-color: #32343a;
        background-image: url('../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    & > span > span:nth-of-type(2) {
        left: 0;
        background-color: transparent;
        color: #fff;
        button {
            visibility: visible;
            border: none !important;
            width: auto !important;
            & > span {
                display: none !important;
            }
        }
    }
}

/* banner */
.collegeConnectInfo {
    background: linear-gradient(346deg, rgba(197, 31, 54, 1) 0%, rgba(30, 47, 110, 1) 100%);
    border-radius: 2.4rem;
    max-width: 110rem;
    .flex {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 4.5rem 4.5rem 4.5rem 4.5rem;
        background-image: url('../../resources/images/bg-collegeconnect.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;
        position: relative;
    }
    h2 {
        font-family: 'Akrobat', 'Poppins', sans-serif;
        font-size: 1.5rem;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        margin: 0;
        font-weight: 400;
        transform: rotate(270deg);
        position: absolute;
        left: 4.3rem;
        top: 50%;
        margin-top: 7.7rem;
        width: 15rem;
        text-align: center;
        transform-origin: left top;
        display: none;
    }
    .top100sports {
        width: 1.5rem;
        max-width: 1.5rem;
        height: 13rem;
        background: url('../../resources/images/college-connect-label.svg') no-repeat center;
        background-size: contain;
        margin: 0 1.5rem 0 0;
        flex: 1 0 1.5rem;
    }
    .txt {
        h1 {
            font-family: 'agency-fb', 'Poppins', sans-serif;
            color: #fff;
            margin: 0;
            text-align: left;
            font-size: 5.8rem;
            line-height: 6rem;
            text-transform: uppercase;
            span {
                display: block;
                font-size: 7.8rem;
                line-height: 8rem;
                font-weight: 700;
                -webkit-text-stroke-width: 2px;
                -webkit-text-stroke-color: rgba(255, 255, 255, 0.6);
                color: transparent;
            }
        }
        p {
            font-family: 'Akrobat', 'Poppins', sans-serif;
            max-width: 46rem;
            font-size: 2rem;
            line-height: 3rem;
            font-weight: 400;
            color: #fff;
            margin: 0;
            text-align: left;
        }
    }
    .txt:nth-of-type(2) {
        padding-right: 4rem;
    }
}

/* tabs */
.tabs {
    margin-top: 2rem;
    padding-bottom: 6rem;
    & > div:nth-of-type(1) {
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        margin: 0;
        &:before {
            display: none;
        }
        & > div:nth-of-type(1) {
            margin: 0.6rem 0.8rem 0 0;
            height: 6rem;
            border: none !important;
            & > div {
                width: auto;
                & > div {
                    font-size: 1.6rem;
                    font-weight: 500;
                    text-align: center;
                    width: 100%;
                    color: #ffffff;
                    margin-left: 0;
                    margin-right: 2rem;
                    padding: 0 1rem;
                    background-color: transparent !important;
                }
            }
        }
    }
    & > div:nth-of-type(2) > div > div {
        margin-top: 2rem;
    }

    .add {
        height: 4.4rem;
        background: #242731;
        border-radius: 1.3rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
        border: none;
        padding: 1.4rem 2.4rem;
        color: #fff;
        font-weight: 500;
        transition: all 0.3s;
        &:hover {
            background: #32343a;
        }
    }
}

.tools {
    & > button,
    & > div {
        margin-left: 1rem;
    }
    .filters {
        & > span {
            background-color: transparent !important;
        }
        & > div {
            padding: 1.2rem 3.5rem 1.2rem 2.2rem !important;
            background-color: #242731 !important;
            border-color: transparent !important;
            box-shadow: none !important;
            height: 4.4rem !important;
            & > span {
                color: #fff !important;
            }
        }
    }
    .favorite {
        width: 5.4rem;
        height: 4.4rem;
        border: none;
        background: #242731;
        border-radius: 1.2rem;
    }
}

.tab {
    margin-top: 3rem;
}

.legend {
    margin: 2rem 0 0 0;
    .flex {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: flex;
            align-items: center;
            margin: 3rem 4rem 0 0;
            font-weight: 500;
            span {
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 50%;
                margin: 0 1rem 0 0;
                padding: 0 0 0 2rem;
                &.pin1 {
                    background-color: #3f8cff;
                }
                &.pin2 {
                    background-color: #293a88;
                }
                &.pin3 {
                    background-color: #feda03;
                }
                &.pin4 {
                    background-color: #2dc387;
                }
                &.pin5 {
                    background-color: #ff9f38;
                }
                &.pin6 {
                    background-color: #af47d3;
                }
                &.pin7 {
                    background-color: #bd1c2e;
                }
                &.pin8 {
                    background-color: #ff8787;
                }
                &.pin9 {
                    background-color: #1c6758;
                }
            }
        }
    }
}

/* rwd */
@media only screen and (max-width: 1100px) {
    .collegeConnectInfo {
        .top100sports {
            position: absolute;
            top: 5rem;
            left: 4.5rem;
        }
        h2 {
            margin-top: 0;
            top: 17.4rem;
        }
        .flex {
            flex-wrap: wrap;
        }
        .txt p {
            max-width: 100%;
            margin-top: 1rem;
        }
        .txt:nth-of-type(2) {
            flex: 1 0 100%;
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 1050px) {
    .tabs > div:nth-of-type(1) {
        flex-direction: column;
        & > div:nth-of-type(3) {
            margin: 2rem 0 2rem 0;
        }
    }
    .tools {
        & > div {
            margin-left: 0;
        }
        .favorite {
            margin: 0 1rem 0 0;
        }
    }
}

@media only screen and (max-width: 630px) {
    .mobileSearch {
        width: 30rem;
    }
}

@media only screen and (max-width: 510px) {
    .mobileSearch {
        width: 20rem;
    }
}

@media only screen and (max-width: 450px) {
    .collegeConnectInfo {
        .top100sports {
            left: 2.5rem;
            top: 4rem;
        }
        h2 {
            left: 2.3rem;
            top: 15.3rem;
        }
        .flex {
            padding: 3rem 3rem 3rem 5.5rem;
        }
        .txt {
            h1 {
                font-size: 4.8rem;
                span {
                    font-size: 6.2rem;
                    line-height: 6rem;
                }
            }
        }
    }
    .tabs > div:nth-of-type(1) > div:nth-of-type(1) > div > div {
        margin: 0 0.3rem 0 0;
    }
}

@media only screen and (max-width: 380px) {
    .mobileSearch {
        width: 15rem;
    }
}

@media only screen and (max-width: 350px) {
    .collegeConnectInfo {
        .flex {
            padding: 3rem;
        }
        .top100sports {
            display: none;
        }
        h2 {
            display: block;
            position: static;
            transform: none;
            text-align: left;
        }
        .txt p {
            font-size: 1.8rem;
            line-height: 2.6rem;
        }
    }
}
