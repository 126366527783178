.layout {
    min-height: 100vh;
    background: #1d1e23;

    .navigation {
        background: #1f2128;
        min-height: 100vh;
        padding-bottom: 3rem;
        border-right: 1px solid rgba(255, 255, 255, 0.05);
        & > div {
            height: 100vh;
            position: fixed;
            overflow-y: auto;
            width: inherit;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            & > ul {
                margin-bottom: 5rem;
            }
        }
    }

    .navigationClosed {
        background: #1f2128;
        border-right: 1px solid rgba(255, 255, 255, 0.05);
        & > div {
            height: 100vh;
            position: fixed;
            overflow-y: auto;
            width: inherit;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            & > ul {
                margin-bottom: 5rem;
            }
        }

        .top {
            padding: 2.4rem 1.7rem;
        }

        ul {
            li ul li {
                padding: 0 1rem !important;
                justify-content: center;

                span {
                    width: 2rem !important;
                    height: 2rem !important;
                    line-height: 2rem !important;
                }

                span:nth-of-type(2) {
                    display: none !important;
                }
            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.4rem 2rem 2.4rem 3.4rem;
        transition: all 0.3s;
        position: relative;

        .logo {
            width: 6.8rem;
            height: auto;
        }

        .toggleOpen,
        .toggleClose {
            background-color: transparent;
            border: none;
            width: 3rem;
            height: 3rem;
            padding: 0;
            background-size: 3.4rem auto;
            background-repeat: no-repeat;
            background-position: center;
            text-indent: -999rem;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
                transform: scale(1.1);
            }
        }

        .toggleClose {
            background-image: url('../../resources/images/ico-menuCollapseClose.svg');
        }

        .toggleOpen {
            position: absolute;
            right: -0.7rem;
            background-image: url('../../resources/images/ico-menuCollapseOpen.svg');
            background-color: #1f2128;
            border-radius: 50%;
            z-index: 1;
        }
    }

    .header {
        margin: 0;
        padding: 5rem 3rem 0 3rem;
        height: auto;
        line-height: 0;
        position: relative;
        z-index: 3;
        background: transparent;

        .topMobile {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                z-index: 2;
                .logo {
                    width: 5.8rem;
                    height: 6.4rem;
                    background: url('../../resources/images/top100-logo.png') no-repeat center;
                    background-size: contain;
                }
                .toggle {
                    margin-left: 1.4rem;
                    width: 4.4rem;
                    height: 4.4rem;
                    background-image: url('../../resources/images/drawerOpen.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: auto 1rem;
                    visibility: visible;
                }
            }

            .buttons {
                button {
                    border: none;
                    background-image: url('../../resources/images/ico-filter.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 2.1rem auto;
                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    /** TODO: @Michal - we have restored main section styles **/

    .main {
        background-color: #1d1e23;
        .subheader {
            padding: 3rem 6rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            position: relative;
            & > div {
                padding: 0;
            }
        }
        .content {
            z-index: 2;
            padding: 3rem 0;
            margin: 0 6rem;
        }

        .footer {
            padding: 0 6rem 0;
        }

        .spinner {
            margin-top: 35rem;

            span {
                font-size: 64px;

                i {
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
}

/* drawer */
.drawer {
    .top {
        padding: 5rem 0 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .logo {
        width: 5.8rem;
        height: 6.4rem;
        background: url('../../resources/images/top100-logo.png') no-repeat center;
        background-size: contain;
    }
    .toggle {
        width: 4.4rem;
        height: 4.4rem;
        background: url('../../resources/images/drawerClose.svg') no-repeat center;
        background-size: 1.4rem auto;
        svg {
            display: none;
        }
    }
}

@media only screen and (max-width: 960px) {
    .layout .main {
        .subheader {
            padding: 3rem 3rem;
        }
        .content {
            margin: 0 3rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .layout {
        .header {
            padding: 5rem 1.5rem 0 1.5rem;
        }
        .main {
            .content {
                margin: 0 1.5rem;
            }
        }
    }
}
