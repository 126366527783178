.link {
    cursor: pointer;
}

.button {
    float: right;
}

.sports {
    font-size: 20px;
}
