.userInfo {
    margin-top: 4rem;
}

.label {
    display: block !important;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #b2b3bd;
    margin: 0 0 1.1rem 0 !important;
    font-weight: 500;
}

.remove {
    margin-top: 1.5rem;
    svg {
        width: 14px;
        height: 14px;
    }
}

/* rwd */

@media only screen and (max-width: 580px) {
    .btn {
        padding: 1rem 3rem !important;
    }
}

@media only screen and (max-width: 550px) {
    .btn {
        margin: 1rem 0.5rem !important;
    }
}

@media only screen and (max-width: 490px) {
    .btn {
        padding: 1rem 2rem !important;
    }
}
