.buttons{ display:flex; justify-content:flex-end;
  .flex{ display:flex; margin:0 0 0 0.8rem; }
 }


 @media only screen and (max-width: 550px) {
  .buttons{ flex-wrap:wrap; justify-content:center;
    .flex{ max-width:100%; flex:1 0 100%; justify-content:center; margin-left:0; }
  }
 }


 @media only screen and (max-width: 400px) {
     .buttons{ 
       .flex{
         button{ padding:1rem 3rem !important; }
       }
     }
 }