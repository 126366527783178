.header {
    margin-top: -0.1rem;
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;
        .headerBackgroundImage {
            background-image: url('../../../resources/images/headerEvents.jpg');
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .content {
        padding: 0;
    }
    .tools {
        margin: -0.8rem 0;
        button {
            width: 5.6rem;
            height: 5.6rem;
            border-radius: 1.2rem;
            border: 1px solid rgba(228, 228, 228, 0.2);
            color: transparent;
            &[ant-click-animating-without-extra-node='true'] {
                background: #3f8cff;
                border-color: #3f8cff;
            }
            &:hover {
                background: #3f8cff;
                border-color: #3f8cff;
            }
        }
        .sort {
            margin-left: 1.4rem;
            & > div {
                border-radius: 1.2rem;
                border: 1px solid rgba(228, 228, 228, 0.2);
                background: transparent;
                & > span {
                    color: #fff;
                    padding-right: 3rem;
                }
            }
            & > span {
                background: transparent;
            }
            &:hover > div {
                border: 1px solid rgba(228, 228, 228, 0.2);
            }
        }
        .addTeamButton {
            width: 140px;
            margin-right: 10px;
            color: white;
        }

        .selectedView {
            background-color: #3f8cff !important;
        }

        .unselectedView {
            background-color: transparent !important;
        }
    }
}

.search {
    & > span > span {
        border: none;
    }
    input {
        width: 50rem !important;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #fff;
        font-weight: 600;
        padding: 0.8rem 2rem 0.8rem 4rem !important;
        height: 3.6rem;
        background-color: transparent !important;
        background-image: url('../../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    & > span > span:nth-of-type(2) {
        left: 0;
        background-color: transparent;
        color: #fff;
        button {
            visibility: visible;
            border: none !important;
            width: auto !important;
            //   &:after{ content:'Submit'; font-size:1.3rem; font-weight:600; transition:all .3s; }
            //   &:hover:after{ color:#fff; }
            & > span {
                display: none !important;
            }
        }
    }
}

.toolbar {
    padding: 0;
    margin: 0 0 3rem 0;
    .sports,
    .organizations {
        margin-left: 1.6rem;
        & > div {
            padding: 1.8rem 4rem 1.8rem 2.2rem !important;
        }
    }
}

.orgName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.4rem;
    line-height: 2.6rem;
    color: #fff;
    margin: 0 0 5rem 0;
    .logo {
        width: 8.6rem;
        height: 8.6rem;
        border-radius: 50%;
        flex: 1 0 8.6rem;
        max-width: 8.6rem;
        background: #fff;
        margin: 0 2rem 0 0;
        padding: 1rem;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.mobileFilter {
    & > div:nth-of-type(2) {
        background: #1a1a1a;
        & > div:nth-of-type(1) {
            background: #1a1a1a;
            & > div:nth-of-type(1) {
                background: #1a1a1a;
                & > div:nth-of-type(2) {
                    background: #1a1a1a;
                    padding: 5rem 1rem 2rem 2rem;
                }
            }
        }
    }
    ul {
        border-right: none;
        color: transparent;
        background: transparent;
        li {
            color: #fff;
            margin: 1rem 0;
            & > div {
                padding: 0 !important;
            }
            ul li div {
                padding: 0 1rem !important;
                margin: 0;
            }
        }
    }
}

/* rwd */
@media only screen and (max-width: 1230px) {
    .search input {
        width: 38rem !important;
    }
}

@media only screen and (max-width: 850px) {
    .search input {
        width: 32rem !important;
    }
}

.selected span {
    color: #5f75ee !important;
}

.unselected span {
    color: #808191 !important;
}
