@import url('https://p.typekit.net/p.css?s=1&k=igm8sqo&ht=tk&f=30684.30685&a=32276116&app=typekit&e=css');
@font-face {
    font-family: 'agency-fb';
    src: url('https://use.typekit.net/af/9ce0b8/00000000000000003b9ae623/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/9ce0b8/00000000000000003b9ae623/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/9ce0b8/00000000000000003b9ae623/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

.header {
    .buttons {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin: 0 0 -1rem 0;
        .avatar {
            flex: 1;
            position: relative;
            font-size: 2.7rem;
            display: flex;
            align-items: center;
            .image {
                width: 5.6rem;
                height: 5.6rem;
                flex: 1 0 5.6rem;
                max-width: 5.6rem;
                margin-right: 1.5rem;

                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
            .parentName {
                font-size: 1.8rem;
                line-height: 2.7rem;
                font-weight: 600;
            }
        }
        button {
            height: 4.4rem;
            border-radius: 1.2rem;
            border: 1px solid #555;
            font-size: 1.4rem;
            font-weight: 600;
            margin: 0 0 1rem -1px;
            &:hover,
            &[ant-click-animating-without-extra-node='true'] {
                background: #3f8cff;
                border: 1px solid #3f8cff !important;
                color: #fff;
            }
            &.share,
            &.upload {
                margin: 0 0 0 0.8rem;
                display: flex;
                align-items: center;
                span {
                    width: 1.7rem;
                    height: 1.7rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0.7rem 0 0;
                    svg {
                        display: block !important;
                        width: 1.7rem;
                        height: 1.7rem;
                        z-index: 3;
                        color: transparent;
                    }
                }
            }
        }
    }
    button {
        background-image: none !important;
        .image {
            background-image: url('../../resources/images/profile-placeholder.png') !important;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

/* mobile */
.players {
    font-size: 2.2rem;
    line-height: 3.2rem;
    font-weight: 500;
    margin: 5rem 0 2rem 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    button {
        height: 4.4rem;
        background: #242731;
        border-radius: 0.8rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
        border: none;
        padding: 1.4rem 2.4rem;
        color: #fff;
        font-weight: 500;
        transition: all 0.3s;
        &:hover {
            background: #3f8cff;
            color: #fff;
        }
    }
}

div.card {
    margin-bottom: 3rem;
    border-radius: 2.4rem;
    overflow: hidden;
    border: none;
    background-color: #242731 !important;
    &:hover .description:after {
        opacity: 1;
    }
    & > div:nth-of-type(2) {
        padding: 2rem;
        cursor: pointer;
    }
    .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        & > div {
            height: inherit;
        }
    }
    .imageHigh {
        height: 24rem;
        img {
            object-fit: cover;
        }
        & > div {
            height: inherit;
        }
    }
    .imageMedium {
        background: #fff;
        padding: 1rem;
        height: 17rem;
        & > div {
            height: inherit;
        }
    }
    .meta {
        color: #fff;
        & > div:nth-of-type(1) {
            & > div:nth-of-type(1) {
                font-size: 1.8rem;
                line-height: 2.4rem;
                min-height: 4.8rem;
                margin: 0 0 1.6rem 0;
            }
        }
    }
    .footer {
        display: flex;
        align-items: center;
        margin: 2.5rem -2rem 0 -2rem;
        padding: 2.2rem 2rem 0.2rem 2rem;
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: #808191;
        font-weight: 500;
        .ico {
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            background: #0049c6;
            margin-right: 0.6rem;
        }
    }
}

.addUser {
    height: 4.4rem;
    margin-left: 1rem;
    & > div {
        height: 4.4rem !important;
        background: transparent !important;
        border: 1px solid rgba(228, 228, 228, 0.1) !important;
        line-height: 2rem;
        padding: 1.1rem 2rem 1.1rem 1.6rem !important;
        transition: all 0.3s;
        & > span {
            color: #fff !important;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.05) !important;
        }
    }
    & > span {
        background: transparent !important;
        right: 1.6rem !important;
    }
}

/* tabs */
.tabs {
    padding: 0 0 4rem 0;
    & > div:before {
        display: none;
    }
    & > div:nth-of-type(1) {
        margin: 0 0 4rem 0;
    }
    & > div:nth-of-type(1) > div > div > div {
        margin-left: 0 !important;
        margin-right: 3.5rem !important;
        height: 5.6rem;
        border-radius: 0 !important;
        background: transparent !important;
        border: none !important;
        padding: 0 !important;
        width: auto;
        & > div {
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 500;
            text-align: center;
            color: #fff;
        }
        &:hover > div {
            color: #fff;
        }
    }
}

/* cards */
.cards {
    margin: 0 0 0 0;
    max-width: 106rem;
    & > div:nth-of-type(1),
    & > div:nth-of-type(2),
    & > div:nth-of-type(3) {
        flex: 1 0 20%;
        max-width: 20%;
    }

    & > div:nth-of-type(1) {
        .card {
            background: linear-gradient(
                216.33deg,
                rgba(63, 140, 255, 0.62) 2.5%,
                rgba(63, 140, 255, 0.79) 20.57%,
                #3f8cff 47.82%
            );
            .button:hover {
                color: #3f8cff;
            }
            .gradient {
                background: linear-gradient(
                    216.33deg,
                    rgba(63, 140, 255, 0.9) 0%,
                    rgba(63, 140, 255, 0.8) 40%,
                    #3f8cff 60%
                );
            }
        }
    }

    & > div:nth-of-type(2) {
        .card {
            background: linear-gradient(
                216.33deg,
                rgba(196, 17, 52, 0.83) 2.5%,
                rgba(196, 17, 52, 0.72) 20.57%,
                #c41134 47.82%
            );
            .button:hover {
                color: #c41134;
            }
            .gradient {
                background: linear-gradient(
                    216.33deg,
                    rgba(196, 17, 52, 0.9) 0%,
                    rgba(196, 17, 52, 0.8) 40%,
                    #c41134 60%
                );
            }
        }
    }

    & > div:nth-of-type(3) {
        .card {
            background: linear-gradient(
                216.33deg,
                rgba(128, 129, 145, 0.9) 2.5%,
                rgba(128, 129, 145, 0.78) 20.57%,
                #808191 47.82%
            );
            .button:hover {
                color: #808191;
            }
            .gradient {
                background: linear-gradient(
                    216.33deg,
                    rgba(128, 129, 145, 0.9) 0%,
                    rgba(128, 129, 145, 0.8) 40%,
                    #808191 60%
                );
            }
        }
    }

    & > div:nth-of-type(4) {
        flex: 1 0 40%;
        max-width: 40%;
        padding-left: 10rem !important;
        h2 {
            padding: 0 0 2.4rem 4.2rem;
            background-position: left top;
            background-repeat: no-repeat;
            background-image: url('../../resources/images/ico-coach.svg');
            background-size: 2.8rem auto;
            border-bottom: 0.4rem solid rgba(228, 228, 228, 0.1);
            white-space: nowrap;
            display: flex;
            justify-content: space-between;
            font-size: 2.4rem;
            line-height: 3.2rem;
        }
        h3 {
            font-size: 1.4rem;
            line-height: 1.6rem;
            text-transform: uppercase;
            color: #808191;
            font-weight: 600;
            margin: 2.2rem 0 0.5rem 0;
        }
        p {
            font-size: 1.8rem;
            line-height: 2.4rem;
            color: #fff;
            font-weight: 500;
            margin: 0;
            a {
                color: inherit;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .card {
        padding: 7rem 3rem 3rem 3rem;
        height: 100%;
        border-radius: 2rem;

        .logo {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: auto;
            overflow: hidden;
            .gradient {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 2;
            }
            & > div {
                position: relative;
                z-index: 1;
                height: 100%;
                padding: 1rem;
                align-items: center;
                justify-content: center;
                & > div {
                    display: none !important;
                }
                & > img {
                    filter: saturate(0);
                }
            }
        }

        & > div {
            padding: 0;
            height: 100%;
            & > div {
                height: 100%;
                position: relative;
                z-index: 2;
                & > div {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    & > div:nth-of-type(1) {
                        font-size: 1.6rem;
                        line-height: 2.1rem;
                        font-weight: 600;
                        overflow: visible;
                        text-overflow: unset;
                        white-space: normal;
                        margin-bottom: 0;
                        span {
                            font-family: 'agency-fb', sans-serif;
                            font-size: 7rem;
                            line-height: 6.6rem;
                            font-weight: 700;
                            display: block;
                            margin: 0 0 0.5rem 0;
                        }
                    }
                }
            }
        }
        .button {
            height: 3.6rem;
            border-radius: 0.7rem;
            border: 1px solid rgba(228, 228, 228, 0.4);
            font-size: 1.3rem;
            line-height: 2rem;
            padding: 0.7rem 1rem;
            text-align: center;
            font-weight: 600;
            width: 100%;
            &:hover {
                background: #fff;
                border-color: #fff;
            }
            span.mob {
                display: none;
            }
        }
    }
}

/* sports */
.misc {
    .card {
        margin: 0 0 1.4rem 0;
        .sportSection {
            & > div {
                & > div:nth-of-type(1) {
                    flex: 1;
                    max-width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        & > div > div > div {
            display: flex;
            justify-content: space-between;
            & > div {
                margin: 0;
            }
            button {
                height: 4.4rem;
                border-radius: 0.8rem;
                border: 1px solid rgba(228, 228, 228, 0.2);
                font-size: 1.4rem;
                line-height: 2rem;
                padding: 1.2rem 4rem;
                text-align: center;
                font-weight: 600;
                width: 100%;
                &:hover {
                    background: #3f8cff;
                    border-color: #3f8cff;
                    color: #fff;
                }
            }
        }
        .title {
            font-size: 2.6rem;
            line-height: 3rem;
            color: #fff;
            font-weight: 600;
            margin: 0 2rem 0 0;
            white-space: normal;
            &:hover {
                text-decoration: underline;
            }
        }

        .sport {
            display: flex;
            flex: 1 0 100%;
            justify-content: space-between;
            .affiliate {
                display: flex;
                align-items: center;
                .logo {
                    width: 12rem;
                    flex: 1 0 12rem;
                    max-width: 12rem;
                    height: 12rem;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            .sportDetails {
                margin: 0 0 0 3rem;
                font-size: 2.6rem;
                line-height: 3rem;
                .sportLogo {
                    margin: 2rem 0 0 0;
                    display: flex;
                    align-items: center;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: #fff;
                    img {
                        width: 2.5rem;
                        height: 2.5rem;
                        margin-right: 1rem;
                    }
                }
            }

            .rank {
                display: flex;
                flex: 1 0 24rem;
                max-width: 24rem;
                margin: 0 4rem;
                & > span {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .avatar {
                        border-radius: 50%;
                        position: relative;
                        padding: 2px;
                        z-index: 2;
                        &:before {
                            content: '';
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                        span {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            position: relative;
                            z-index: 2;
                        }
                    }
                    .playerRank {
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 600;
                        position: relative;
                        z-index: 3;
                    }
                    p {
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                        color: #fff;
                        font-weight: 600;
                        text-align: center;
                        margin: 0.5rem 0 0 0;
                    }
                }
                & > span:nth-child(1) {
                    flex: 1 0 9rem;
                    max-width: 9rem;
                    order: 2;
                    margin: 0 1.3rem;
                    .avatar {
                        width: 9rem;
                        height: 9rem;
                        span {
                            border: 5px solid #242731;
                        }
                        &:before {
                            background: linear-gradient(180deg, rgba(253, 202, 0, 1) 0%, rgba(253, 202, 0, 0) 100%);
                        }
                    }
                    .playerRank {
                        width: 2.4rem;
                        height: 2.4rem;
                        line-height: 2.4rem;
                        color: #000;
                        font-size: 1.6rem;
                        background: #fdca00;
                        margin-top: -1.3rem;
                    }
                }
                & > span:nth-child(2),
                & > span:nth-child(3) {
                    flex: 1 0 6.2rem;
                    max-width: 6.2rem;
                    .avatar {
                        width: 6.2rem;
                        height: 6.2rem;
                    }
                    .playerRank {
                        width: 1.7rem;
                        height: 1.7rem;
                        line-height: 1.7rem;
                        font-size: 1.3rem;
                        margin-top: -1rem;
                    }
                }
                & > span:nth-child(2) {
                    order: 1;
                    .avatar {
                        span {
                            border: 4px solid #242731;
                        }
                        &:before {
                            background: linear-gradient(180deg, rgba(199, 199, 199, 1) 0%, rgba(199, 199, 199, 0) 100%);
                        }
                    }
                    .playerRank {
                        color: #000;
                        background: #c7c7c7;
                    }
                }
                & > span:nth-child(3) {
                    order: 3;
                    .avatar {
                        span {
                            border: 4px solid #242731;
                        }
                        &:before {
                            background: linear-gradient(180deg, rgba(207, 126, 51, 1) 0%, rgba(207, 126, 51, 0) 100%);
                        }
                    }
                    .playerRank {
                        width: 1.7rem;
                        height: 1.7rem;
                        line-height: 1.7rem;
                        color: #fff;
                        font-size: 1.3rem;
                        background: #cf7e33;
                    }
                }
            }

            .manage {
                flex: 1 0 14rem;
                max-width: 14rem;
            }
        }
    }
}

/* table */
.table {
    background: transparent;
    margin: 2rem 0 4rem 0;
    border-radius: 0.8rem;
    table {
        background: #1f2128;
        margin: 0 0 0 0;
        thead {
            tr {
                th {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    color: #fff;
                    border-bottom: none;
                    padding: 2rem 1.5rem;
                }
            }
        }
        tbody {
            & > tr {
                & > td {
                    padding: 1.5rem 1.5rem;
                    border-bottom: none;
                    a {
                        color: inherit;
                        &:hover {
                            color: inherit;
                            text-decoration: underline;
                        }
                    }
                }
                &:nth-of-type(odd) td {
                    background: #1f2128;
                }
                &:nth-of-type(even) td {
                    background: #242731;
                }
                &:last-of-type {
                    td:first-of-type {
                        border-bottom-left-radius: 1.2rem;
                    }
                    td:last-of-type {
                        border-bottom-right-radius: 1.2rem;
                    }
                }
            }
        }
    }

    .name {
        color: #fff;
    }
    a {
        text-decoration: underline;
        color: inherit;
        &:hover {
            text-decoration: none !important;
        }
    }
}

/* rwd */
@media only screen and (max-width: 1350px) {
    .cards {
        & > div:nth-of-type(4) {
            padding-left: 2rem !important;
        }
        .card {
            padding: 7rem 1.5rem 3rem 1.5rem;
        }
    }
}

@media only screen and (max-width: 1350px) {
    .misc .card .sport {
        flex-wrap: wrap;
        .affiliate {
            order: 1;
            flex: 1 0 calc(100% - 14rem);
            padding-right: 4rem;
        }
        .manage {
            order: 2;
        }
        .rank {
            order: 3;
            margin-top: 2rem;
            flex: 1 0 100%;
            max-width: 100%;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .cards {
        & > div:nth-of-type(4) {
            padding-left: 0.9rem !important;
            order: 1;
            flex: 1 0 100%;
            max-width: 60rem;
            margin: 0 0 4rem 0;
        }
        & > div:nth-of-type(1),
        & > div:nth-of-type(2),
        & > div:nth-of-type(3) {
            order: 2;
            flex: 1 0 33.33%;
            max-width: 33.33%;
        }
        .card {
            padding: 7rem 3rem 3rem 3rem;
        }
    }
}

@media only screen and (max-width: 1050px) {
    .misc .card .sport {
        flex-wrap: wrap;
        .affiliate {
            order: 1;
            flex: 1 0 100%;
            padding-right: 0;
        }
        .manage {
            order: 3;
        }
        .rank {
            order: 2;
            margin: 2rem 0 4rem 0;
            justify-content: flex-start;
        }
        .sportDetails .title {
            margin-right: 0;
        }
    }
}

@media only screen and (max-width: 900px) {
    .cards {
        .card {
            padding: 7rem 1.5rem 3rem 1.5rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .cards {
        .card {
            padding: 7rem 3rem 3rem 3rem;
        }
    }
}

@media only screen and (max-width: 650px) {
    .cards {
        .card {
            padding: 7rem 1.5rem 3rem 1.5rem;
        }
    }
}

@media only screen and (max-width: 600px) {
    .misc .card {
        .title {
            text-align: left;
            margin: 0;
            display: block;
        }
        & > div > div > div {
            display: block;
            text-align: center;
            button {
                max-width: 30rem;
            }
        }
        .sportLogo {
            margin-bottom: 2rem;
        }
    }
}

@media only screen and (max-width: 550px) {
    .cards {
        .card {
            padding: 3rem 1rem 2rem 1rem;
            text-align: center;
            .button {
                span.des {
                    display: none;
                }
                span.mob {
                    display: inline-block;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .misc .card .sport {
        .sportDetails {
            margin: 0;
            justify-content: center;
            .sportLogo {
                justify-content: center;
            }
            .title {
                text-align: center;
            }
        }
        .affiliate {
            flex-direction: column;
            .logo {
                margin: 0 0 1rem 0;
                height: auto;
                flex: unset;
                max-width: 100%;
                width: 100%;
                img {
                    object-fit: unset;
                    width: 100%;
                    height: auto;
                    max-width: 20rem;
                }
            }
        }
        .rank {
            justify-content: center;
        }
        .manage {
            margin: 0 auto;
        }
    }
}

@media only screen and (max-width: 450px) {
    .cards {
        & > div:nth-of-type(1),
        & > div:nth-of-type(2),
        & > div:nth-of-type(3) {
            flex: 1 0 50%;
            max-width: 50%;
            margin-bottom: 1.8rem;
        }
    }
}
