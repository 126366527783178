.header {
    margin: -3rem -3rem -3rem -3rem;
    padding: 2rem 3rem;
    .player {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .avatar {
            display: flex;
            align-items: center;
            .picture {
                flex: 1 0 7.2rem;
                max-width: 7.2rem;
                width: 7.2rem;
                height: 7.2rem;
                span {
                    display: block;
                    width: inherit;
                    height: inherit;
                }
                svg,
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            h1 {
                margin: 0 0 0 1.8rem;
                font-size: 1.8rem;
                line-height: 2.4rem;
                font-weight: 600;
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        & > div {
            margin-bottom: 0;
        }
        button {
            height: 5.6rem;
            border-radius: 1.2rem;
            border: 1px solid #555;
            font-size: 1.4rem;
            font-weight: 700;
            margin: 0 0 0 0.8rem;
            padding: 0.4rem 2rem;
            &:hover,
            &[ant-click-animating-without-extra-node='true'] {
                background: #3f8cff;
                border: 1px solid #3f8cff !important;
                color: #fff;
            }
            &.add {
                margin: 0 0 0 0.8rem;
                display: flex;
                align-items: center;
                span {
                    width: 1.7rem;
                    height: 1.7rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0.7rem 0 0;
                    svg {
                        display: block !important;
                        width: 1.7rem;
                        height: 1.7rem;
                        z-index: 3;
                        color: transparent;
                    }
                }
            }
        }
    }
}

.media {
    padding: 1rem 0 4rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 500;
        margin: 0;
        color: #fff;
    }

    .selects {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        & > div {
            margin-left: 1rem;
        }
    }
}

.photosList {
    padding-bottom: 5rem;
    & > div > div > div > div {
        margin-bottom: 1.4rem;
        &:nth-of-type(7n + 1) {
            flex: 1 0 66.67%;
            max-width: 66.67%;
        }
        &:nth-of-type(7n + 2) {
            flex: 1 0 33.33%;
            max-width: 33.33%;
        }
        &:nth-of-type(7n + 3) {
            flex: 1 0 33.33%;
            max-width: 33.33%;
        }
        &:nth-of-type(7n + 4) {
            flex: 1 0 66.67%;
            max-width: 66.67%;
        }
        &:nth-of-type(7n + 5) {
            flex: 1 0 33.33%;
            max-width: 33.33%;
        }
        &:nth-of-type(7n + 6) {
            flex: 1 0 33.33%;
            max-width: 33.33%;
        }
        &:nth-of-type(7n + 7) {
            flex: 1 0 33.33%;
            max-width: 33.33%;
        }
    }
    .card {
        background: #32343a;
        position: relative;
        border: none;
        cursor: pointer;
        border-radius: 1rem;
        overflow: hidden;
        & > div {
            margin: 0;
            padding: 0;
        }
        & > div:nth-of-type(2) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0 3rem 4rem 3rem;
            z-index: 2;
        }
        .meta {
            font-size: 1.8rem;
            line-height: 2.6rem;
            color: #fff;
            font-weight: 500;
        }
        .image {
            height: 35rem;
            overflow: hidden;
            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
            .gradient {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 100%);
            }
        }
        &:hover {
            & > div:nth-of-type(2) {
                &:after {
                    background-color: #095cb5;
                }
            }
        }
    }
}

.card.cardVideo {
    & > div:nth-of-type(2) {
        &:after {
            content: '';
            width: 4.4rem;
            height: 4.4rem;
            border-radius: 50%;
            background: #3f8cff url(../../resources/images/ico-play.svg) no-repeat;
            background-position: 1.5rem center;
            background-size: 1.6rem auto;
            margin: 2rem 0 0 0;
            transition: all 0.3s;
        }
    }
}

.cardSelected.cardVideo {
    border-radius: 1rem;
    border: none;
    overflow: hidden;
    background: #32343a;
    position: relative;
    cursor: pointer;
    &:after {
        content: '';
        border: 6px solid #3f8cff;
        position: absolute;
        left: -1px;
        top: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 1rem;
    }
    & > div {
        margin: 0;
        padding: 0;
    }
    & > div:nth-of-type(2) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 3rem 4rem 3rem;
    }
    .meta {
        font-size: 1.8rem;
        line-height: 2.6rem;
        color: #fff;
        font-weight: 500;
    }
    .image {
        height: 35rem;
        overflow: hidden;
        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    &:hover {
        & > div:nth-of-type(2) {
            &:after {
                background-color: #095cb5;
            }
        }
    }
}

.cardSelected.cardPicture {
    border-radius: 1rem;
    overflow: hidden;
    border: none;
    background: #32343a;
    position: relative;
    cursor: pointer;
    &:after {
        content: '';
        border: 6px solid #3f8cff;
        position: absolute;
        left: -1px;
        top: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 1rem;
    }
    & > div {
        padding: 0;
        margin: 0;
    }
    & > div:nth-of-type(2) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 3rem 4rem 3rem;
    }
    .meta {
        font-size: 1.8rem;
        line-height: 2.6rem;
        color: #fff;
        font-weight: 500;
    }
    .image {
        height: 35rem;
        overflow: hidden;
        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    &:hover {
        & > div:nth-of-type(2) {
            &:after {
                background-color: #095cb5;
            }
        }
    }
}

/* RWD */
@media only screen and (min-width: 1450px) {
    .photosList > div > div > div {
        & > div:nth-of-type(10n + 1) {
            flex: 1 0 40%;
            max-width: 40%;
        }
        & > div:nth-of-type(10n + 2) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(10n + 3) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(10n + 4) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(10n + 5) {
            flex: 1 0 30%;
            max-width: 30%;
        }
        & > div:nth-of-type(10n + 6) {
            flex: 1 0 40%;
            max-width: 40%;
        }
        & > div:nth-of-type(10n + 7) {
            flex: 1 0 25%;
            max-width: 25%;
        }
        & > div:nth-of-type(10n + 8) {
            flex: 1 0 25%;
            max-width: 25%;
        }
        & > div:nth-of-type(10n + 9) {
            flex: 1 0 25%;
            max-width: 25%;
        }
        & > div:nth-of-type(10n + 10) {
            flex: 1 0 25%;
            max-width: 25%;
        }
    }
}

@media only screen and (min-width: 1700px) {
    .photosList > div > div > div {
        & > div:nth-of-type(13n + 1) {
            flex: 1 0 34%;
            max-width: 34%;
        }
        & > div:nth-of-type(13n + 2) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(13n + 3) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(13n + 4) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(13n + 5) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(13n + 6) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(13n + 7) {
            flex: 1 0 22%;
            max-width: 22%;
        }
        & > div:nth-of-type(13n + 8) {
            flex: 1 0 34%;
            max-width: 34%;
        }
        & > div:nth-of-type(13n + 9) {
            flex: 1 0 20%;
            max-width: 20%;
        }
        & > div:nth-of-type(13n + 10) {
            flex: 1 0 20%;
            max-width: 20%;
        }
        & > div:nth-of-type(13n + 11) {
            flex: 1 0 20%;
            max-width: 20%;
        }
        & > div:nth-of-type(13n + 12) {
            flex: 1 0 20%;
            max-width: 20%;
        }
        & > div:nth-of-type(13n + 13) {
            flex: 1 0 20%;
            max-width: 20%;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .header .player {
        flex-direction: column;
        align-items: flex-start;
        .avatar {
            margin-bottom: 2rem;
        }
        .buttons {
            button {
                margin: 0 0.8rem 0 0;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .photosList {
        & > div > div > div {
            margin-left: -0.8rem !important;
            margin-right: -0.8rem !important;
            & > div {
                margin-bottom: 0;
                &:nth-of-type(4n + 1),
                &:nth-of-type(4n + 2),
                &:nth-of-type(4n + 3),
                &:nth-of-type(4n + 4) {
                    flex: 1 0 100%;
                    max-width: 100%;
                }
                &:nth-of-type(3n + 2),
                &:nth-of-type(3n + 3) {
                    flex: 1 0 50%;
                    max-width: 50%;
                }
                & > div {
                    padding: 0 0.8rem !important;
                }
            }
        }
        .card {
            & > div:nth-of-type(2) {
                padding: 0 2rem 3rem 2rem;
            }
            .image {
                height: 30rem;
            }
        }
    }
}

@media only screen and (max-width: 961px) {
    .header {
        margin: -3rem -3rem 0 -3rem;
    }
}

@media only screen and (max-width: 900px) {
    .media {
        flex-wrap: wrap;
        h2 {
            flex: 1 0 100%;
            max-width: 100%;
        }
        .selects {
            flex: 1 0 100%;
            max-width: 100%;
            margin-top: 3rem;
            & > div {
                margin: 0 1rem 0 0;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .header {
        padding: 0;
        margin: 0;
        .player {
            .buttons {
                button.add {
                    background-image: none;
                    padding: 0;
                    height: 3.6rem;
                    margin-left: 2rem;
                    border: none !important;
                    span {
                        margin-right: 0;
                        width: 2.1rem;
                        height: 2.1rem;
                        svg {
                            display: block;
                            width: 2.1rem;
                            height: 2.1rem;
                        }
                    }
                }
                button.select {
                    background-image: none;
                    height: 3.6rem;
                    font-weight: 500;
                    padding: 0.4rem 1.5rem;
                    border: 1px solid #fff;
                }
                button {
                    background-image: none !important;
                    height: 3.6rem;
                    font-weight: 500;
                    padding: 0.4rem 1.5rem;
                    border: 1px solid #fff !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .header .buttons {
        flex-wrap: wrap;
        max-width: 20rem;
        button {
            order: 2;
        }
        & > button:nth-of-type(1),
        & > button:nth-of-type(2) {
            margin-top: 1rem !important;
            margin-bottom: -5rem !important;
        }
    }
    .media {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
            margin: 0 0 2rem 0;
            font-size: 2.1rem;
            line-height: 2.6rem;
        }

        .selects {
            margin: 0 -0.5rem;
            justify-content: center;
            & > div {
                margin: 1rem 0.5rem;
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .photosList .card {
        & > div:nth-of-type(2) {
            padding: 0 2rem 2rem 2rem;
        }
        .image {
            height: 21rem;
        }
        .meta > div > div {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
}

.selected {
    background-color: #3f8cff !important;
    color: #fff !important;
}

.loadMore {
    text-align: center;
    margin-top: 12;
    height: 32;
    line-height: 32px;
}

.sports {
    width: 150px;
}

.changeSport {
    margin: 0 0.8rem 0 0;
    display: flex;
    align-items: center;
}

.seasons {
    width: 155px;
    height: 56px;
    border: 1px solid rgba(228, 228, 228, 0.1);
    border-radius: 12px;
    margin-right: 2rem;
}

.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: 7rem;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    span {
        font-size: 64px;

        i {
            background-color: #fff;
        }
    }
}
