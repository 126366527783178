.modal {
    width: 100% !important;
    height: 100%;
    padding: 3rem 3rem;
    top: 0 !important;
    background: rgba(0, 0, 0, 0.8);
    & > div:nth-of-type(2) {
        height: 100%;
        background: transparent;
        & > div:nth-of-type(1) {
            height: 100% !important;
            padding: 0;
            & > div {
                height: 100% !important;
                display: flex;
                justify-content: center;
                align-items: center;
                video {
                    height: auto !important;
                    width: auto !important;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
    .footer {
        position: absolute;
        left: 0;
        top: 0;
        .buttonLeft {
            padding: 1rem 2rem !important;
            margin-left: 0 !important;
        }
        .buttonRight {
            padding: 1rem 2rem !important;
        }
    }
}
