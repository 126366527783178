.userInfo {
    margin-top: 4rem;
}

.search {
    margin: 4rem 0 2rem 0;
    & > span {
        width: 100%;
        display: block;
        height: 4.4rem;
        & > span {
            width: 100%;
            border: none;
            &:focus {
                box-shadow: none;
            }
        }
    }
    span {
        border-color: transparent !important;
        border: none !important;
    }
    input {
        width: 100%;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #fff;
        font-weight: 600;
        padding: 0.8rem 2rem 0.8rem 4rem !important;
        height: 3.6rem;
        background-color: transparent !important;
        background-image: url('../../../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    & > span > span:nth-of-type(2) {
        left: 0;
        background-color: transparent;
        color: #fff;
        button {
            visibility: visible;
            border: none !important;
            width: auto !important;
            & > span {
                display: none !important;
            }
        }
    }
}

.nofound {
    font-size: 1.8rem;
    margin: 1rem 0 3rem 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 300;
}

.userList {
    display: flex;
    justify-content: space-between;
    .user {
        display: flex;
        align-items: center;
        margin: 0 2rem 0 0;
        p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
                display: block;
                margin-top: 0.2rem;
            }
        }
        .avatar {
            flex: 1 0 6.4rem;
            max-width: 6.4rem;
            min-width: 6.4rem;
            margin-right: 1rem;
        }
        .email {
            color: #808191;
        }
    }
}

.addUserButton {
    float: right;
    color: #3f8cff;
    &:before {
        background: transparent !important;
    }
    &:focus,
    &:hover {
        background: transparent;
    }
}

/* rwd */
@media only screen and (max-width: 550px) {
    .userList {
        flex-direction: column;
        .user {
            margin: 0;
        }
    }
    .addUserButton {
        max-width: 15rem;
        margin: 1rem 0 0 2.5rem;
    }
}

@media only screen and (max-width: 400px) {
    .btn {
        padding: 1rem 3rem !important;
    }
}

.newRow {
    display: block;
}
