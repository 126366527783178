@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
        font-size: 3.6rem;
        line-height: 4rem;
        color: #fff;
        font-weight: 500;
        margin: 0 0 0 0;
        position: relative;
        z-index: 2;
    }

    button {
        height: 5.6rem;
        border-radius: 1.2rem;
        border: 1px solid #555;
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0 0 0 0.8rem;
        display: flex;
        align-items: center;
        span {
            width: 2.2rem;
            height: 2.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 1rem 0 0;
            svg {
                display: block !important;
                width: 2.2rem;
                height: 2.2rem;
                z-index: 3;
                color: transparent;
            }
        }
        &:hover,
        &[ant-click-animating-without-extra-node='true'] {
            background: #3f8cff;
            border: 1px solid #3f8cff !important;
            color: #fff;
        }
    }

    .back {
        width: 4.4rem;
        height: 4.4rem;
        margin: 1rem 0 0 2rem;
        background-image: none !important;
        span {
            width: 4.4rem;
            height: 4.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                display: block !important;
                width: 2.4rem;
                height: 2.4rem;
                z-index: 3;
                color: transparent;
            }
        }
    }
}
.subheader {
    width: 100%;
    h2 {
        font-size: 1.8rem;
        margin: 0;
    }
}

.divider {
    margin: 2rem 4rem 3.5rem 4rem;
    background: #e4e4e4;
    opacity: 0.1;
    min-width: auto;
    width: auto;
}

.wristbands {
    .wristband {
        justify-content: flex-start;
        .xxx {
            display: none;
        }
        .logo {
            display: none;
        }
        .qr {
            margin-right: 2rem;
            height: 7.2rem;
        }
        .name {
            p:nth-of-type(1) {
                font-size: 2.1rem;
                line-height: 2.6rem;
                margin: 0;
            }
            p:nth-of-type(2) {
                font-size: 1.2rem;
                line-height: 1.8rem;
                margin: 0;
            }
            p:nth-of-type(3) {
                margin: 0;
                display: none;
            }
        }
        .event {
            display: none;
            p {
                font-size: 1.3rem;
                line-height: 1.6rem;
                margin: 0;
            }
        }
        .divider {
            display: none;
        }
    }
}

.loadMore {
    text-align: center;
    margin: 4rem 0 0 0;
    button {
        background: #242731;
        height: 5.6rem;
        border-radius: 1.2rem;
        padding: 1.8rem 2.2rem;
        color: #808191;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
        border: none;
        transition: all 0.3s;
        &:hover {
            background: #3f8cff;
            color: #fff;
        }
    }
}

.checkbox {
    border: none;
    margin-bottom: 1rem;
}

/* rwd */
@media only screen and (max-width: 900px) {
    .header h1 {
        font-size: 3rem;
        line-height: 4rem;
    }
    .subheader h2 {
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 767px) {
    .header {
        margin-left: -14.6rem;
        margin-top: -1.3rem;
        height: 8rem;
        width: 100vw;
        padding: 0;
        position: relative;
        background: #1d1e23;
        z-index: 2;
        button.back {
            background-image: none !important;
            width: 4.4rem;
            height: 4.4rem;
            margin: 1rem 0 0 2rem;
            span {
                width: 4.4rem;
                height: 4.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    display: block !important;
                    width: 2.4rem;
                    height: 2.4rem;
                    z-index: 3;
                    color: transparent;
                }
            }
        }
        h1 {
            width: 100%;
            text-align: center;
            padding-right: 6.4rem;
            margin-top: 0.5rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .header {
        h1 {
            font-size: 2.6rem;
            line-height: 3.4rem;
            padding-right: 4.4rem;
        }
    }
}

@media only screen and (max-width: 450px) {
    .wristbands .wristband {
        flex-direction: column;
        align-items: flex-start;
        .qr {
            margin: 0 0 1rem 0;
        }
    }
}

/* print */
.print {
    background-color: #fff !important;
}

@media only print {
    @page {
        size: 11in 1in;
        margin: 0 0 0 0;
    }
    body {
        height: auto;
        width: auto;
        overflow: hidden;

        #root {
            height: auto;
            width: auto;
        }
    }

    .wristbands {
        width: 11in;
        margin: 0 auto;
        .wristband {
            height: 1in !important;
            justify-content: space-between;
            border-bottom: none;
            padding: 0;
            align-items: center;
            .xxx {
                display: block;
                height: 100%;
                img {
                    height: 100%;
                    width: auto;
                }
            }
            .logo {
                display: block;
            }
            .qr {
                height: 100%;
                margin-right: 0;
                position: relative;
                & > div {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                svg {
                    width: 100% !important;
                    height: auto !important;
                    margin-top: 1rem;
                }
            }
            .name {
                position: relative;
                p:nth-of-type(1) {
                    font-weight: 600;
                    font-size: 2.1rem;
                    line-height: 2.6rem;
                    margin: 0;
                }
                p:nth-of-type(2) {
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    margin: 0;
                }
                p:nth-of-type(3) {
                    display: block;
                }
                &:after {
                    content: '';
                    width: 1px;
                    height: 0.56in;
                    background: #898989;
                    position: absolute;
                    top: 50%;
                    bottom: 0;
                    right: 0;
                    margin-top: -0.28in;
                }
            }
            .event {
                display: block;
                padding-left: 1.5%;
                p {
                    font-size: 1.3rem;
                    line-height: 1.6rem;
                    margin: 0;
                }
            }
            & > div:nth-of-type(2) {
                img {
                    float: right;
                }
            }

            & > div:nth-of-type(2) {
                flex: 1 0 23.7%;
                max-width: 23.7%;
                padding-right: 2%;
            }
            .event {
                flex: 1 0 22%;
                max-width: 22%;
                padding-right: 2%;
            }
            .logo {
                flex: 1 0 8.3%;
                max-width: 8.3%;
                padding-right: 1.5%;
                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }
            .qr {
                flex: 1 0 8.3%;
                max-width: 8.3%;
                padding-right: 1%;
            }
            .name {
                flex: 1 0 19.2%;
                max-width: 19.2%;
            }
            & > div:last-of-type {
                flex: 1 0 18.5%;
                max-width: 18.5%;
            }
        }
        .wristband,
        .wristband p {
            font-family: 'Source Sans Pro', sans-serif;
            color: #000 !important;

            &.hidden {
                display: none;
            }
        }

        .loadMore {
            display: none;
        }
    }
    .checkbox {
        display: none;
    }
}
