.prospects {
    .collapse {
        border: none;
        background: rgba(53, 56, 69, 0.4);
        border-radius: 1.2rem;
        & > div {
            border-bottom: none !important;
        }
        .panel {
            & > div:nth-of-type(1) {
                display: flex;
                justify-content: space-between;
                border-radius: 0;
                padding: 1.2rem 2.5rem;
                align-items: center;
                background: #353845;
                border-radius: 1.2rem;
                & > span:nth-of-type(1) {
                    order: 10;
                    margin-right: 0;
                    svg {
                        width: 2rem;
                        height: 1.2rem;
                    }
                }
                .avatar {
                    order: 1;
                    flex: 1 0 4.4rem;
                    max-width: 4.4rem;
                    margin: 0 1rem 0 0;
                }
                .data {
                    flex: 1;
                    order: 2;
                    padding: 0 2rem 0 0;
                    h3 {
                        font-size: 1.7rem;
                        line-height: 2.2rem;
                        color: #fff;
                        font-weight: 600;
                        margin: 0;
                    }
                    p {
                        font-size: 1.3rem;
                        line-height: 2.2rem;
                        color: #686868;
                        font-weight: 600;
                        margin: 0;
                        span {
                            padding-right: 0.8rem;
                            margin-right: 0.8rem;
                            border-right: 1px solid #686868;
                            line-height: 1.4rem;
                            display: inline-block;
                        }
                        span:last-of-type {
                            margin-right: 0;
                            padding-right: 0;
                            border-right: none;
                        }
                    }
                }
                .committed {
                    flex: 1;
                    font-size: 1.3rem;
                    line-height: 1.8rem;
                    color: #3dbaa2;
                    font-weight: 500;
                    margin: 0;
                    background-image: url('../../../resources/images/ico-committed.svg');
                    padding: 0 2rem 0 2.7rem;
                    background-repeat: no-repeat;
                    background-size: 1.8rem auto;
                    background-position: left top;
                    order: 3;
                }
                & > div:nth-last-of-type(1) {
                    order: 4;
                    display: flex;
                    ul {
                        display: flex;
                    }
                    button {
                        margin: 0 1.2rem;
                        width: 3.3rem;
                        height: 3.1rem;
                        border: 1px solid rgba(228, 228, 228, 0.1);
                        border-radius: 0.8rem;
                        padding: 0;
                        svg {
                            width: 1.4rem;
                            height: auto;
                        }
                    }
                }
                .extra {
                    display: flex;
                    align-items: center;
                }
            }
            & > div:nth-of-type(2) {
                padding: 2.5rem 2.5rem 1.2rem 2.5rem;
                background: #242731;
                border: 2px solid #353845;
                border-bottom-right-radius: 1.2rem;
                border-bottom-left-radius: 1.2rem;
                border-top: none;
                margin-top: -1rem;
                .stats {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -2rem;
                    p {
                        font-size: 1.2rem;
                        line-height: 1.8rem;
                        color: #fff;
                        margin: 0 2rem;
                        font-weight: 600;
                        label {
                            font-size: 1.2rem;
                            line-height: 1.8rem;
                            color: #808080;
                            font-weight: 500;
                        }
                    }
                }
                .hr {
                    margin: 1.5rem -2rem;
                    border-top: 1px solid #353845;
                }
                .rank {
                    display: flex;
                    margin: 1.7rem 0;
                    strong {
                        font-size: 4.5rem;
                        line-height: 5.4rem;
                        font-weight: 600;
                        color: rgba(128, 129, 145, 0.6);
                        margin: 0 1rem 0 0;
                    }
                    span {
                        color: #fff;
                        font-size: 1.5rem;
                        line-height: 1.7rem;
                        font-weight: 600;
                        max-width: 7rem;
                        padding: 0.8rem 0;
                    }
                }
                table {
                    thead {
                        tr {
                            th {
                                background: #3d4151;
                                padding: 0.8rem 1.5rem;
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                background: #242731;
                                padding: 0.8rem 1.5rem;
                            }
                        }
                    }
                }
            }
            .notes {
                max-width: 50rem;
                display: flex;
                justify-content: space-between;
                & > div {
                    flex: 1;
                    max-width: 100%;
                    padding-right: 1rem;
                    margin: 0;
                }
                .note {
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    color: #9495a5;
                    padding: 0.5rem 1rem;
                    height: 2.8rem;
                    background: #353845 !important;
                    border-radius: 0.8rem;
                    font-weight: 400;
                }
                button {
                    height: 2.8rem;
                    background: transparent;
                    padding: 0;
                    text-transform: lowercase;
                    font-weight: 400;
                    color: #808191;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
        .link {
            margin-left: 2rem;
        }
    }
}

.card {
    background: #32343a;
    position: relative;
    border: none;
    cursor: pointer;
    border-radius: 1rem;
    overflow: hidden;
    & > div {
        margin: 0;
        padding: 0;
    }
    & > div:nth-of-type(2) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 3rem 4rem 3rem;
        z-index: 2;
    }
    .meta {
        font-size: 1.8rem;
        line-height: 2.6rem;
        color: #fff;
        font-weight: 500;
        height: 5.6rem;
    }
    .image {
        height: 35rem;
        overflow: hidden;
        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
        .gradient {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 100%);
        }
    }
    &:hover {
        & > div:nth-of-type(2) {
            &:after {
                background-color: #095cb5;
            }
        }
    }
}

.card.cardVideo {
    & > div:nth-of-type(2) {
        display: flex;
        justify-content: space-between;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            width: 4.4rem;
            height: 4.4rem;
            border-radius: 50%;
            background: #3f8cff url(../../../resources/images/ico-play.svg) no-repeat;
            background-position: 1.5rem center;
            background-size: 1.6rem auto;
            margin: 0 0 0 0;
            transition: all 0.3s;
        }
    }
}

.select {
    width: 20rem;
}

/* rwd */
@media only screen and (max-width: 1420px) {
    .card.cardVideo > div:nth-of-type(2) {
        flex-direction: column;
        &:after {
            order: 0;
        }
        & > div {
            order: 1;
            margin: 1rem 0 0 0;
        }
    }
}

@media only screen and (max-width: 1180px) {
    .prospects .collapse .panel > div:nth-of-type(1) {
        flex-wrap: wrap;
        .committed {
            order: 11;
            flex: 1 0 100%;
            max-width: 100%;
            margin: 1rem 0 0 5.4rem;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .prospects .collapse .panel {
        & > div:nth-of-type(2) {
            & > div > div > div {
                display: flex;
                flex-direction: column;
                & > div {
                    flex: unset;
                    max-width: 100%;
                }
                & > div:nth-of-type(2) {
                    margin: 3rem 0 0 0;
                    max-width: 36rem;
                }
            }
        }
    }

    .card.cardVideo > div:nth-of-type(2) {
        flex-direction: row;
        &:after {
            order: 2;
        }
        & > div {
            order: 1;
            margin: 0 0 0 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .prospects {
        width: calc(100vw - 6rem);
    }
}

@media only screen and (max-width: 581px) {
    .prospects .collapse .panel > div:nth-of-type(1) {
        flex-wrap: wrap;
        & > div:nth-last-of-type(1) {
            order: 10;
            flex: 1 0 100%;
            max-width: 100%;
            margin: 0.5rem 0 0 5.4rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .prospects {
        width: calc(100vw - 3rem);
    }
}

@media only screen and (max-width: 400px) {
    .card.cardVideo > div:nth-of-type(2) {
        flex-direction: column;
        &:after {
            order: 0;
        }
        & > div {
            order: 1;
            margin: 1rem 0 0 0;
        }
    }
}
