.list {
    margin: 2rem 0 0 0;
    & > div > div > div > div {
        & > div {
            height: 100%;
            & > div {
                height: 100%;
                margin-bottom: 0 !important;
                padding-bottom: 1.5rem !important;
                & > div {
                    height: 100%;
                }
            }
        }
    }
}

.image {
    width: 4rem;
}

.loadMore {
    text-align: center;
    margin: 4rem 0 0 0;
    button {
        background: #242731;
        height: 5.6rem;
        border-radius: 1.2rem;
        padding: 1.8rem 2.2rem;
        color: #808191;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
        border: none;
        transition: all 0.3s;
        &:hover {
            background: #3f8cff;
            color: #fff;
        }
    }
}

.tabContent {
    border: 2px solid #3d4151;
    border-radius: 1.2rem;
    overflow: hidden;
    & > div {
        padding: 2rem;
        background: #242731;
        height: 100%;
        & > div {
            height: 100%;
            & > div {
                height: 100%;
                display: flex;
                flex-direction: column;
                & > div:nth-of-type(2) {
                    flex: 1;
                }
            }
        }
    }

    .collegeHeader {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        min-height: 7.2rem;
        & > div:nth-of-type(1) {
            width: 6.4rem;
            height: 6.4rem;
            flex: 1 0 6.4rem;
            max-width: 6.4rem;
            border-radius: 50%;
            padding: 0;
            overflow:hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;
            background: rgba(0, 0, 0, 0.4);
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        h3 {
            white-space: normal;
            font-size: 1.8rem;
            line-height: 2.2rem;
            color: #fff;
            font-weight: 600;
            margin: 1rem 0;
        }
        .buttons {
            flex: 1 0 8rem;
            max-width: 8rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 -0.3rem 0 1rem;
            button {
                width: 3.3rem;
                height: 3.1rem;
                border-radius: 0.8rem;
                border: 1px solid rgba(228, 228, 228, 0.1);
                margin: 0 0.3rem;
                &.checked {
                    background-color: red;
                }
            }
        }
    }

    .description {
        padding: 0.5rem 0 0 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        label {
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: #808080;
            font-weight: 600;
            display: block;
        }
        p {
            font-size: 2.1rem;
            line-height: 2.4rem;
            font-weight: 500;
            color: #fff;
            margin: 0 0 2rem 0;
        }
        .flex {
            display: flex;
            margin: 0.8rem -0.3rem 2rem -0.3rem;
            .box {
                padding: 0 0.3rem;
                .content {
                    background: #353845;
                    border-radius: 0.8rem;
                    padding: 1rem;
                }
                label {
                    margin: 0 0 0.3rem 0;
                }
                p {
                    margin: 0;
                }
            }
        }
        .academic .flex {
            .box {
                flex: 1 0 33.33%;
                max-width: 33.33%;
            }
        }
        .details {
            margin: 0 0 2rem 0;
            p {
                color: #fff;
                font-size: 1.3rem;
                line-height: 2rem;
            }
        }
        .buttons {
            button {
                height: 4.4rem;
                border-radius: 0.8rem;
                border: none;
                text-align: center;
                background: #3f8cff;
                transition: all 0.3s;
                font-size: 1.4rem;
                text-align: center;
                display: block;
                font-weight: 500;
                text-shadow: none;
                width: 100%;
                &:hover {
                    color: #3f8cff;
                    background: #fff;
                }
            }
        }
    }
}
