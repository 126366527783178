.header {
    margin-top: -0.1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;
        .headerBackgroundImage {
            background-image: url('../../../../resources/images/headerEvents.jpg');
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .tools {
        display: flex;
        justify-content: flex-end;
        margin: -0.8rem 0;
        button {
            display: flex;
            align-items: center;
            height: 5.6rem;
            border-radius: 1.2rem;
            border: 1px solid rgba(228, 228, 228, 0.2);
            margin-left: 1.5rem;
            transition: all 0.3s;
            &:hover {
                background: #3f8cff;
                border-color: #3f8cff;
                color: #fff;
            }
            & > span {
                margin-right: 1rem;
            }
            &.add {
                & > span > svg {
                    width: 2.1rem;
                    height: 2.1rem;
                }
            }
            &.print {
                & > span > svg {
                    width: 2.1rem;
                    height: 2.1rem;
                }
            }
            &.code {
                & > span > svg {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }
}
button.back {
    color: transparent !important;
    width: 3.2rem !important;
    height: 3.2rem !important;
}

.searchField {
    padding: 0;
    & > div > div > span > span > span > span {
        border: none;
    }
    input {
        width: 50rem !important;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #fff;
        font-weight: 600;
        padding: 0.8rem 2rem 0.8rem 4rem !important;
        height: 3.6rem;
        background-color: transparent !important;
        background-image: url('../../../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    & > div > div > span > span > span > span:nth-of-type(2) {
        left: 0;
        background-color: transparent;
        color: #fff;
        button {
            visibility: visible;
            border: none !important;
            width: auto !important;
            // &:after {
            //     content: 'Submit';
            //     font-size: 1.3rem;
            //     font-weight: 600;
            //     transition: all 0.3s;
            // }
            // &:hover:after {
            //     color: #fff;
            // }
            & > span {
                display: none !important;
            }
        }
    }
}

.searchFieldMobile {
    padding: 1rem 2rem 0 1.5rem;
    width: 100vw;
    box-shadow: none;
    background: transparent;
    & > span {
        border: 1px solid #434343;
        & > span {
            border: none;
        }
        & > span:nth-of-type(2) {
            display: none;
        }
    }
    input {
        font-size: 1.4rem;
        line-height: 2rem;
        color: #fff;
        font-weight: 600;
        padding: 0.8rem 0 0.8rem 4rem !important;
        height: 3.6rem;
        background-color: transparent !important;
        background-image: url('../../../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    input + span {
        display: none;
    }
}

.toolbar {
    padding: 0;
    margin: 0 0 3rem 0;
    & > div > span {
        display: flex;
        align-items: center;
    }
    button {
        display: inline-flex;
        height: 5.6rem;
        padding: 2rem 1.6rem 2rem 2.4rem;
        color: #808191;
        border-radius: 1.2rem;
        align-items: center;
        font-weight: 600;
        span {
            opacity: 0.4;
            width: 1.5rem;
            height: 1.5rem;
            margin: 0 1rem 0 0;
            transition: all 0.3s;
            svg {
                color: transparent;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &:hover {
            background: #3f8cff;
            color: #fff;
            span {
                opacity: 1;
            }
            a {
                color: #fff;
            }
        }
    }
    .sports,
    .organizations {
        margin-left: 1.6rem;
        & > div {
            padding: 1.8rem 4rem 1.8rem 2.2rem !important;
        }
    }
}

.mobileBtn {
    margin: 0 0 0 -1rem;
    width: 20rem;
    button {
        width: 1rem;
        height: 4.4rem;
        border: none;
        background-image: none !important;
        svg {
            color: transparent;
            width: auto;
            height: 2.4rem;
            display: block !important;
        }
    }
    input {
        background-color: transparent !important;
    }
}

.searchBar {
    width: 20rem;
    margin-right: 6rem;
}

.teamMembers {
    h1 {
        font-size: 3.6rem;
        line-height: 4rem;
        margin: 6rem 0 0 0;
        font-weight: 500;
    }
    .tools {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5rem 0;
        button {
            font-size: 1.4rem;
            font-weight: 500;
            color: #808191;
            height: 5.6rem;
            background: #242731;
            border-radius: 1.2rem;
            margin: 0 0.7rem 0 0;
            flex: 1 0 15.6rem;
            max-width: 15.6rem;
            text-align: center;
            border: none;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
            }
        }
    }

    .table {
        padding: 0 0 10rem 0;
        overflow-x: auto;
        table {
            background: #1f2128;
            margin: 0 0 4rem 0;
            thead {
                tr {
                    th {
                        font-size: 1.1rem;
                        line-height: 1.6rem;
                        color: #fff;
                        text-transform: uppercase;
                        border-bottom: none;
                        padding: 2rem 1.5rem;
                        background: #23252c;
                        &:first-of-type {
                            border-top-left-radius: 1.2rem;
                        }
                        &:last-of-type {
                            border-top-right-radius: 1.2rem;
                        }
                    }
                }
            }
            tbody {
                & > tr {
                    & > td {
                        padding: 1.5rem 1.5rem;
                        border-bottom: none;
                        a {
                            color: inherit;
                            &:hover {
                                color: inherit;
                                text-decoration: underline;
                            }
                        }
                    }
                    &:nth-of-type(odd) td {
                        background: #1d1e23;
                    }
                    &:nth-of-type(even) td {
                        background: #242731;
                    }
                    &:last-of-type {
                        td:first-of-type {
                            border-bottom-left-radius: 1.2rem;
                        }
                        td:last-of-type {
                            border-bottom-right-radius: 1.2rem;
                        }
                    }
                }
            }
        }

        ul {
            li {
                font-weight: 600;
                a {
                    transition: all 0.3s;
                }
            }
        }
    }
}

.mobileButtons {
    display: flex;
    align-items: center;
    button {
        margin-left: 1rem;
    }
    button.dots {
        background-image: none !important;
        svg {
            display: block !important;
            width: 3rem;
            height: 3rem;
        }
    }
    button.search {
        background-image: none !important;
        svg {
            display: block !important;
            width: 2.2rem;
            height: 2.2rem;
        }
    }
    .searchMobile {
        display: none;
    }
}

.mobileFilter {
    & > div:nth-of-type(2) {
        background: #1a1a1a;
        & > div:nth-of-type(1) {
            background: #1a1a1a;
            & > div:nth-of-type(1) {
                background: #1a1a1a;
                & > div:nth-of-type(2) {
                    background: #1a1a1a;
                    padding: 5rem 1rem 2rem 2rem;
                }
            }
        }
    }
    ul {
        border-right: none;
        color: transparent;
        background: transparent;
        li {
            color: #fff;
            margin: 1rem 0;
            & > div {
                padding: 0 !important;
            }
            ul li div {
                padding: 0 1rem !important;
                margin: 0;
            }
        }
    }
}

/* rwd */
@media only screen and (max-width: 1230px) {
    .searchField input {
        width: 38rem !important;
    }
    .header {
        flex-direction: column;
        align-items: flex-start;
        .searchField {
            flex: auto;
            max-width: 100%;
        }
        .tools {
            flex: auto;
            max-width: 100%;
            margin-top: 0.5rem;
            button {
                margin-left: 0;
                margin-right: 1rem;
            }
        }
    }
}

@media only screen and (max-width: 1020px) {
    .teamMembers .tools button {
        flex: none;
        max-width: none;
    }
}

@media only screen and (max-width: 850px) {
    .searchField input {
        width: 32rem !important;
    }
}

@media only screen and (max-width: 550px) {
    .teamMembers h1 {
        font-size: 2.4rem;
        line-height: 3.2rem;
    }
}

@media only screen and (max-width: 500px) {
    .teamMembers .tools {
        flex-wrap: wrap;
        justify-content: flex-start;
        button {
            margin: 0.7rem 0.7rem 0 0;
        }
    }
}

.select {
    right: 1.1rem;
}

.selected span {
    color: #5f75ee !important;
}

.unselected span {
    color: #808191 !important;
}
