.label {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-weight: bold;
}

.labelContent {
    font-size: 1.7rem;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
}
