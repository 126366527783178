.content{ margin:0 auto; max-width:100rem; padding:6rem 3rem 14rem 7rem; position:relative; z-index:5;
  h1{ font-family:Akrobat, sans-serif; font-size:4rem; line-height:4.8rem; color:#fff; margin:5rem 0 4rem 0; font-weight:700; }
  h2{ font-family:Akrobat, sans-serif; font-size:2.4rem; line-height:3rem; color:#fff; margin:8rem 0 2rem 0; font-weight:800; }
  h3{ font-family:Akrobat, sans-serif; font-size:2.0rem; line-height:2.8rem; color:#fff; margin:4rem 0 2rem 0; font-weight:700; }
  h4{ font-family:Akrobat, sans-serif; font-size:1.8rem; line-height:2.4rem; color:#fff; margin:2rem 0 2rem 0; font-weight:700; }
  p, li{ font-size:1.4rem; line-height:2.6rem; color:#fff; font-weight:400; }
  p{ margin:2rem 0; }
  ul{ margin:3rem 0; }
  a{ color:inherit; text-decoration:underline;
    &:hover{ text-decoration:none; }
  }
}


/* rwd */
@media only screen and (max-width: 900px) {
  .content{ padding:6rem 3rem 14rem 3rem; }
}


@media only screen and (max-width: 768px) {
  .content{ padding:6rem 2rem 14rem 2rem; }
}