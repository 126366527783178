.form {
    max-width: 76rem;
    h1 {
        display: none;
    }
    .space {
        width: 100%;
        display: block;
    }
    button.save {
        margin-top: 4.2rem;
    }
}

.photo {
    display: flex;
    justify-content: flex-start;
    margin: 3.5rem 0 2rem 0;
    .picture {
        flex: 1 0 21rem;
        max-width: 21rem;
        & > div {
            width: 100%;
            img {
                width: 100%;
                height: auto;
                border-radius: 50%;
            }
        }
        .upload {
            height: 4rem;
            font-size: 1.4rem;
            font-weight: 600;
            border-radius: 1.2rem;
            background: #32343a;
            margin-bottom: 24px;
            & > span {
                padding: 0;
            }
            &:hover {
                border-color: #777;
            }
        }
        .image {
            margin-bottom: 2.2rem;
        }
    }
}

.buttons {
    margin: 1rem 0 10rem 0;
    & > div > div > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button.back {
            margin-top: 4rem;
        }
        button.save {
            height: 5.6rem;
            border-radius: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.4rem;
            line-height: 2.4rem;
            font-weight: 500;
            background: transparent;
            border: 1px solid rgba(228, 228, 228, 0.1);
            padding: 0 3rem;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
                border: 1px solid #3f8cff;
            }
            span {
                margin: 0 1rem 0 0;
                svg {
                    width: 2.1rem;
                    height: 2.1rem;
                }
            }
        }
        a button {
            font-size: 1.4rem;
            &:hover {
                background: transparent;
            }
        }
    }
}

/* rwd */
@media only screen and (max-width: 768px) {
    .form:nth-of-type(1) > div,
    .form:nth-of-type(2) > div {
        & > div:nth-of-type(1) {
            flex: 1;
        }
        & > div:nth-of-type(2) {
            flex: 1 0 22rem;
            max-width: 22.2rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .form:nth-of-type(1),
    .form:nth-of-type(2) {
        & > div {
            display: block;
            & > div:nth-of-type(1) {
                flex: 1;
                max-width: 100%;
            }
            & > div:nth-of-type(2) {
                flex: 1;
                max-width: 100%;
            }
        }
        .save {
            margin: -1rem 0 2.5rem 0;
        }
    }
}
