.registrationForm{ padding:0 0 10rem 0;
  .row{ max-width:130rem; margin:0 auto; padding:0 3rem 0 6rem;
    .flex{ display:flex; justify-content:space-between; align-items:flex-start;
      aside{ flex:1 0 38rem; max-width:38rem; position:relative; z-index:2; }
      .content{ flex:1; max-width:80rem; position:relative; z-index:2; }
    }
  }

  aside{ background:rgba(0,0,0,0.2); border-radius:1.2rem; overflow:hidden; }

  .content{
    .header{ padding:0 0 0 0;
      h1{ font-family:'agency-fb', sans-serif; font-size:5.8rem; line-height:6.4rem; color:#fff; font-weight:700; text-transform:uppercase; margin:0 0 2rem 0; }
      p{ font-family:Akrobat, sans-serif; font-size:2rem; line-height:3rem; color:#fff; max-width:52rem; margin:2rem 0; }
    }
  }

  .input > div:nth-of-type(2) > div > div > div, .select > div:nth-of-type(1), input[type='text'], input[type='password'], input[type='mail'], input[type='number']{ background-color:#fff !important; color:#1F2128; margin:0 0 0.5rem 0; }

  .input > div:nth-of-type(2) > div > div > div > div input{ color:#1F2128; font-weight:600; }

  .select > span{ background-color:transparent;
    &>span{ filter:invert(1); }
  }
}


/* buttons */
.buttons{ margin:4rem 0 0 0; display:flex; align-items:center; flex-wrap:wrap;
  &>div{ margin-bottom:0; }
  button:last-child{ margin-right:0; }
  .btnMain{ height:4.6rem; border-radius:0.4rem; border:none; background:#3f8cff; font-size: 1.4rem; line-height: 1.6rem; padding: 1.5rem 2.4rem; color: #fff; font-weight: 500; margin:0.5rem 1.2rem 0.5rem 0; transition: all 0.3s;
    &:hover {
      color: #3f8cff;
      background: #fff;
    }
  }
  .btnSecondary{ height:4.6rem; border-radius:0.4rem; border:none; background:#153456; font-size: 1.4rem; line-height: 1.6rem; padding: 1.5rem 2.4rem; color: #fff; font-weight: 500; margin:0.5rem 1.2rem 0.5rem 0; transition: all 0.3s;
    &:hover {
      color: #153456;
      background: #fff;
    }
  }
}



/* rwd */
@media only screen and (max-width: 1050px){
  .registrationForm .row .flex aside{ flex:1 0 30rem; max-width:30rem; }
}


@media only screen and (max-width: 950px){
  .registrationForm .row .flex{ display:block;
    aside{ max-width:100%; margin:0 0 4rem 0; display:flex; align-items:stretch; }
    .content{ max-width:100%; padding:0; }
  }
}


@media only screen and (max-width: 900px){
	.registrationForm .row{ padding:0 3rem; }
}


@media only screen and (max-width: 750px){
	.registrationForm .row{ padding:0 2rem; }
}


@media only screen and (max-width: 600px){
  .registrationForm{
    .content .header{
      h1{ font-size:4.8rem; line-height:5.4rem; }
      p{ font-size:1.8rem; line-height:2.8rem; }
    }
    .row .flex{ display:block;
      aside{ display:block; }
    }
  }
}


@media only screen and (max-width: 400px){
  .registrationForm{
    .content .header{
      h1{ font-size:4.0rem; line-height:4.8rem; }
      p{ font-size:1.6rem; line-height:2.4rem; }
    }
    .form{
      h2{ font-size:2.2rem; line-height:3.0rem; }
      h3{ font-size:1.8rem; line-height:2.6rem; }
    }
  }
}