.header {
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;
        .headerBackgroundImage {
            background-image: url('../../resources/images/headerEvents.jpg');
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .headerBackground + div{ padding:0; }
    
    h1 {
        font-size: 2rem;
        line-height: 4rem;
        color: #fff;
        font-weight: 500;
        margin: 5.4rem 0 0 0;
        position: relative;
        z-index: 2;
    }
    p {
        font-size: 1.6rem;
        line-height: 2.6rem;
        color: #fff;
        font-weight: 300;
        margin: 1rem 0 0 0;
        position: relative;
        z-index: 2;
    }
}

.subheader {
    margin: 0 0 5rem 0;
    .shcontent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -2rem -1.5rem 0 -1.5rem;
        & > .flex {
            display: flex;
            justify-content: space-between;
            margin: 0 -0.3rem;
            & > div {
                padding: 0 0.3rem;
                margin: 0;
            }
        }
        & > div {
            margin: 0;
            padding: 0 1.5rem;
        }
        .days {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            max-width: 29rem;
            flex: 1 0 29rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            & > div {
                flex: 1 0 7rem;
                max-width: 7rem;
                padding: 0 1rem;
                margin-bottom: 0;
                max-height: 2rem;
                & > div > div {
                    max-height: 2rem;
                    min-height: 2rem;
                }
            }
            label {
                span {
                    color: #808191;
                    font-size: 1.4rem;
                    line-height: 2rem;
                    font-weight: 600;
                }
                input[type='checkbox'] {
                    width: 1.7rem;
                    height: 1.7rem;
                    border: 2px solid #e1e1e1;
                    border-radius: 0.4rem;
                }
            }
        }
        .button {
            height: 5.6rem;
            background-color: #3f8cff;
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 600;
            color: #fff;
            border: none;
            border-radius: 1.2rem;
            padding: 1.8rem 2rem;
            width: 100%;
            text-align: center;
            transition: all 0.3s;
            text-shadow: none;
            &:hover {
                background: #095cb5;
            }
        }
        .select {
            & > div {
                background-color: #242731;
            }
            & > span {
                background-color: #242731;
                right: 1.1rem;
            }
        }
    }
}

.loadMore {
    text-align: center;
    margin: 4rem 0 0 0;
    button {
        background: #242731;
        height: 5.6rem;
        border-radius: 1.2rem;
        padding: 1.8rem 2.2rem;
        color: #808191;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
        border: none;
        transition: all 0.3s;
        &:hover {
            background: #3f8cff;
            color: #fff;
        }
    }
}

.seasonsHeader{ font-size:2.4rem; line-height:3.2rem; margin:0 0 7rem 0; font-weight:500; }

.seasonsList {
    padding: 0 0 10rem 0;
    ul {
        li {
            position: relative;
            border-bottom: none !important;
            margin: 0 0 3rem 0;
            &:nth-of-type(1) {
                background: linear-gradient(115.99deg, #6c5dd3 -1.16%, #242731 51.13%);
            }
            background: #242731;
            border-radius: 2.4rem;
            padding: 2.5rem 3rem 2.5rem 3rem;
            .complete {
                height: 36px;
                background: #3f8cff;
                border-radius: 1rem;
                font-size: 1.4rem;
                line-height: 1.6rem;
                border: none;
                color: #fff;
                padding:0.6rem 1rem;
                transition: all 0.3s;
                text-shadow: none;
                text-align:center;
                &:hover {
                    background: #095cb5;
                }
            }
            .chartButton {
                height: 36px;
                border: 1px solid #808191;
                border-radius: 0.5rem;
                color: transparent;
                padding: 0.5rem 1rem;
            }
            .event {
                display: flex;
                & > div:nth-of-type(1) {
                    .details {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                    }
                }
                & > div:nth-of-type(2) {
                    flex: 1;
                    padding-left: 2.5rem;
                }
            }

            .seasonTitle {
                margin:0;
                display:flex;
                align-items:center;
                justify-content:flex-start;
                .sportName {
                    font-family: Poppins;
                    flex:1 0 11rem;
                    max-width:11rem;
                    p{ margin:0; text-align:center; }
                    p:nth-of-type(1){ font-size:1.4rem; line-height:2rem; letter-spacing:3px; color:#fff; font-weight:400; }
                    p:nth-of-type(2){ font-size:3.6rem; line-height:3.4rem; color:#fff; font-weight:600; margin-top:0.5rem; }
                }
                .btns{ flex:1 0 18rem; max-width:18rem; display:flex; justify-content:flex-end; align-items:center; margin-left:auto; padding-left:4rem;
                    &>a{ order:2; margin-left:1rem; }
                    &>button{ order:1; }
                }
                .dateTitle {
                    color: #808191;
                }
                .dates {
                    margin-left: 7rem;
                    display:flex;
                    flex:1;
                    max-width:46rem;
                }
                .date {
                    flex:1 0 50%;
                    padding-left: 4rem;
                    font-size: 50%;
                    font-size:1.4rem;
                    line-height:2.1rem;
                    font-weight:500;
                    span{ white-space:nowrap; }
                }
                a:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
}

.completedEvents {
    ul {
        li {
            position: relative;
            border-bottom: none !important;
            margin: 0 0 3rem 0;
            background: #242731;
            border-radius: 2.4rem;
            padding: 2rem 12.5rem 2rem 2rem;
            .arrow {
                content: '';
                background: url('../../resources/images/arrowForward.svg') no-repeat;
                background-position: center;
                background-size: contain;
                width: 1.8rem;
                height: 3.6rem;
                position: absolute;
                right: 3rem;
                top: 50%;
            }
            .register {
                position: absolute;
                right: 2rem;
                top: 2rem;
                height: 4.4rem;
                background: #242731;
                border-radius: 1.3rem;
                font-size: 1.4rem;
                line-height: 1.6rem;
                border: 1px solid rgba(228, 228, 228, 0.1);
                border-radius: 12px;
                padding: 1.4rem 2.4rem;
                color: #fff;
                font-weight: 500;
                transition: all 0.3s;
                text-shadow: none;
                &:hover {
                    background: #3f8cff;
                }
            }
            .event {
                display: flex;
                & > div:nth-of-type(1) {
                    flex: 1 0 24rem;
                    max-width: 24rem;
                    border-radius: 1.6rem;
                    height: 20rem;
                    border: 1px solid #333333;
                    overflow: hidden;
                    .details {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        .address {
                            flex: 1 0 13rem;
                            height: 13rem;
                            & > div {
                                width: 100%;
                                height: 100%;
                                padding: 2rem 2rem;
                            }
                            p {
                                margin: 0;
                                font-size: 1.3rem;
                                line-height: 2rem;
                                color: #fff;
                                font-weight: 500;
                            }
                            .orgLogo {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                    .date {
                        background: #1f2128;
                        flex: 1 0 7rem;
                        max-height: 7rem;
                        padding: 1.5rem 2rem;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        & > div:nth-of-type(1) {
                            font-size: 4rem;
                            line-height: 3.4rem;
                            color: #fff;
                            font-weight: 600;
                        }
                        & > div:nth-of-type(2) {
                            padding-left: 1rem;
                            & > p:nth-of-type(1) {
                                font-size: 1.9rem;
                                line-height: 2.2rem;
                                color: #fff;
                                font-weight: 600;
                                margin: 0;
                            }
                            & > p:nth-of-type(2) {
                                font-size: 1.2rem;
                                line-height: 1.6rem;
                                color: #fff;
                                font-weight: 600;
                                margin: 0;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
                & > div:nth-of-type(2) {
                    flex: 1;
                    padding-left: 2.5rem;
                }
            }
            &:nth-of-type(4n + 1) .event > div:nth-of-type(1) {
                background: #3b3e47;
            }
            &:nth-of-type(4n + 2) .event > div:nth-of-type(1) {
                background: #3b3e47;
            }
            &:nth-of-type(4n + 3) .event > div:nth-of-type(1) {
                background: #3b3e47;
            }
            &:nth-of-type(4n + 4) .event > div:nth-of-type(1) {
                background: #3b3e47;
            }

            .eventTitle {
                font-size: 3rem;
                line-height: 3.2rem;
                color: #fff;
                margin: 3rem 0 0 0;
                font-weight: 500;
                .category {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 0 0 0.5rem 0;
                    font-size: 1.4rem;
                    line-height: 2rem;
                    color: #808191;
                    font-weight: 500;
                    img {
                        width: auto;
                        height: 3.2rem;
                        margin: 0 0.7rem 0 0;
                    }
                    span {
                        width: 3.2rem;
                        height: 3.2rem;
                        margin: 0 0.7rem 0 0;
                        svg {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                a:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
            .eventAvatar {
                flex: 1 0 24rem;
                max-width: 24rem;
            }
            .eventDescription {
                padding-top: 2.5rem;
                margin-top: 2.5rem;
                border-top: 1px solid rgba(228, 228, 228, 0.1);
                .eventFlex {
                    margin: 0 -2.5rem 0 -2.5rem;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    & > div {
                        padding: 0 2.5rem;
                        border-right: 1px solid #5b5e69;
                        flex: auto;
                        &:last-of-type {
                            border-right: none;
                        }
                        h4 {
                            font-size: 1rem;
                            line-height: 1.2rem;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            color: #808191;
                            font-weight: 700;
                            margin: 0 0 0.5rem 0;
                        }
                        h4 + div {
                            font-size: 1.4rem;
                            line-height: 2rem;
                            color: #fff;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

.eventsListMobile {
    ul {
        li {
            position: relative;
            border-bottom: none !important;
            margin: 0 0 1.4rem 0;
            background: #242731;
            border-radius: 1.8rem;
            display: flex;
            flex-direction: column;
            padding: 0;
            overflow: hidden;
            & > div:nth-of-type(1) {
                flex: auto;
                padding: 1.5rem 2rem;
                width: 100%;
                .headerFlex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    .date {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        & > div:nth-of-type(1) {
                            font-size: 4rem;
                            line-height: 3.4rem;
                            color: #fff;
                            font-weight: 600;
                        }
                        & > div:nth-of-type(2) {
                            padding-left: 1rem;
                            & > p:nth-of-type(1) {
                                font-size: 1.9rem;
                                line-height: 2.2rem;
                                color: #fff;
                                font-weight: 600;
                                margin: 0;
                            }
                            & > p:nth-of-type(2) {
                                font-size: 1.2rem;
                                line-height: 1.6rem;
                                color: #fff;
                                font-weight: 600;
                                margin: 0;
                                text-transform: uppercase;
                            }
                        }
                    }
                    & > div:nth-of-type(2) {
                        display: flex;
                        justify-content: flex-end;
                        padding-left: 2rem;
                        & > div {
                            flex: none;
                            width: auto;
                            h4 {
                                font-size: 1rem;
                                line-height: 1.2rem;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                color: #ffffff;
                                font-weight: 400;
                                margin: 0 0 0.2rem 0;
                            }
                            h4 + div {
                                font-size: 1.8rem;
                                line-height: 2.4rem;
                                color: #fff;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            &:nth-of-type(4n + 1) > div:nth-of-type(1) {
                background: #3dbaa2;
            }
            &:nth-of-type(4n + 2) > div:nth-of-type(1) {
                background: #ff7a68;
            }
            &:nth-of-type(4n + 3) > div:nth-of-type(1) {
                background: #6c5dd3;
            }
            &:nth-of-type(4n + 4) > div:nth-of-type(1) {
                background: #808191;
            }

            .eventDetails {
                font-size: 3rem;
                line-height: 3.2rem;
                color: #fff;
                font-weight: 500;
                flex: auto;
                width: 100%;
                padding: 2.5rem 0 1rem 0;
                .eventTitle {
                    padding: 0 2rem;
                    .category {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin: 0 0 0.5rem 0;
                        font-size: 1.4rem;
                        line-height: 2rem;
                        color: #808191;
                        font-weight: 500;
                        span {
                            width: 2.4rem;
                            height: 2.4rem;
                            margin: 0 0.7rem 0 0;
                            svg {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                        img {
                            height: 3.2rem;
                            width: auto;
                            margin: 0 0.7rem 0 0;
                        }
                    }
                    .category + a {
                        font-size: 2.2rem;
                        line-height: 2.5rem;
                        font-weight: 500;
                    }
                }
            }
            .eventDescription {
                padding: 1.5rem 2rem 0 2rem;
                margin-top: 1.5rem;
                border-top: 1px solid rgba(228, 228, 228, 0.1);
                h4 {
                    font-size: 1rem;
                    line-height: 1.2rem;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    color: #808191;
                    font-weight: 700;
                    margin: 0 0 0.5rem 0;
                }
                h4 + div {
                    font-size: 1.4rem;
                    line-height: 2rem;
                    color: #fff;
                    font-weight: 700;
                }
            }
        }
    }
}

.ico24 {
    & > span {
        margin: 0 -0.2rem;
        width: 2.4rem !important;
    }
}

/* mobile */
.mobileHeaderBackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 33rem;
    .headerBackgroundImage {
        background-image: url('../../resources/images/headerEvents-mob.jpg');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
    .headerBackgroundGradient {
        background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}
.topNavi {
    display: flex;
    justify-content: space-between;
    margin: 0 -0.5rem 2.5rem -0.5rem;
    .buttonDiv {
        flex: 1 0 33%;
        max-width: 33%;
        padding: 0 0.5rem;
        button {
            border: none;
            width: 100%;
            height: 5.6rem;
            background: #242731;
            color: #a0a1ab;
            border-radius: 1.2rem;
            font-size: 1.6rem;
            line-height: 2rem;
            padding: 1.8rem 2rem 1.8rem 5.6rem;
            position: relative;
            text-align: left;
            font-weight: 500;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
                &:before {
                    opacity: 1;
                }
            }
            &:before {
                content: '';
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                left: 1.7rem;
                top: 0;
                width: 3rem;
                height: 100%;
                opacity: 0.4;
            }
            &.selected {
                color: #fff;
                background: #3f8cff;
                &:before {
                    opacity: 1;
                }
            }
        }
        .events:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-document.svg');
            background-size: 1.8rem auto;
            background-position: 0.6rem center;
        }
        .tasks:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-tasks.svg');
            background-size: 3rem auto;
            background-position: center;
        }
        .lives:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-eventsLive.svg');
            background-size: 3rem auto;
            background-position: center;
        }
    }
}
.mobileFilter {
    & > div:nth-of-type(2) {
        background: #1a1a1a;
        & > div:nth-of-type(1) {
            background: #1a1a1a;
            & > div:nth-of-type(1) {
                background: #1a1a1a;
                & > div:nth-of-type(2) {
                    background: #1a1a1a;
                }
            }
        }
    }
    .menu {
        background: transparent;
    }
    .subheader {
        padding: 7rem 2rem 2rem 2rem;
        .shcontent {
            & > div {
                margin: 0;
                width: 100%;
            }
            .flex {
                display: block;
                & > div {
                    padding: 0;
                    margin: 0 0 2rem 0;
                    &:last-of-type {
                        margin: 0;
                    }
                }
                .select {
                    width: 100% !important;
                }
            }
            .days {
                margin: 2rem 0;
                flex: auto;
                & > div {
                    margin: 1rem 0;
                }
            }
            .button {
                margin-top: 3rem;
                width: 100%;
            }
        }
    }
}

.search {
    & > span > span {
        border: none;
    }
    input {
        width: 50rem !important;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #fff;
        font-weight: 600;
        padding: 0.8rem 2rem 0.8rem 4rem !important;
        height: 3.6rem;
        background-color: transparent !important;
        background-image: url('../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    & > span > span:nth-of-type(2) {
        left: 0;
        background-color: transparent;
        color: #fff;
        button {
            visibility: visible;
            border: none !important;
            width: auto !important;
            & > span {
                display: none !important;
            }
        }
    }
}

.tabs {
    margin-top: -2rem;
    margin-bottom: 2rem;
    & > div:before {
        display: none;
    }
    & > div:nth-of-type(1) > div > div > div {
        width: 15.6rem;
        margin-left: 0 !important;
        margin-right: 0.8rem !important;
        height: 5.6rem;
        border-radius: 1.2rem !important;
        background: #242731 !important;
        border: none !important;
        & > div {
            font-size: 1.4rem;
            font-weight: 500;
            text-align: center;
            width: 100%;
            color: #808191;
        }
        &:hover > div {
            color: #fff;
        }
    }
}

.tabsMobile {
    margin-top: -2rem;
    margin-bottom: 2rem;
    & > div:before {
        display: none;
    }
    & > div:nth-of-type(1) > div > div > div {
        margin-left: 0 !important;
        margin-right: 0.8rem !important;
        height: 5.6rem;
        border-radius: 1.2rem !important;
        background: #242731 !important;
        border: none !important;
        & > div {
            font-size: 1.4rem;
            font-weight: 500;
            text-align: center;
            width: 100%;
            color: #808191;
        }
        &:hover > div {
            color: #fff;
        }
    }
}

.createSeason {
    float: right;
    width: 166px;
    height: 56px;
    background: #242731;
    border-radius: 12px;
    color: #808191;
    border: none;
    &:hover {
        color: #fff;
    }
}


.modal{
    &>div>div{ background:#242731; padding:4rem 2.5rem 1rem 2.5rem; }
    &>div>div:nth-of-type(1){ border-bottom:none;
        &>div{ font-size:2.8rem; line-height:3.6rem; font-weight:600; padding-right:3rem; }
    }
    &>div:nth-of-type(2) button{ top:1rem; right:1rem; }
}

/* RWD */
@media only screen and (max-width: 1400px) {
    .subheader .shcontent .days {
        flex: 1 0 22rem;
        max-width: 22rem;
    }
}

@media only screen and (max-width: 1280px) {
    .subheader {
        margin: 0 0 3.5rem;
        .shcontent {
            flex-wrap: wrap;
            justify-content: flex-start;
            & > div {
                padding-bottom: 1.5rem;
            }
            .days {
                flex: 1 0 29rem;
                max-width: 29rem;
            }
        }
    }
    .eventsList,
    .completedEvents {
        ul li .eventDescription .eventFlex {
            flex-direction: column;
            margin: 0;
            & > div {
                border-right: none;
                padding: 0;
                margin: 0.8rem 0;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .seasonsList ul li .seasonTitle .dates{ margin-left:0; }
}

@media only screen and (max-width: 1060px) {
    .eventsList ul li .event > div:nth-of-type(1) {
        flex: 1 0 16rem;
        max-width: 16rem;
        height: auto;
    }
}

@media only screen and (max-width: 900px) {
    .seasonsList ul li .seasonTitle{ flex-wrap:wrap;
        .sportName{
            p:nth-of-type(1), p:nth-of-type(2){ text-align:left; }
        }
        .btns{ flex:1 0 100%; max-width:100%; border-top:1px solid rgba(255,255,255,.1); margin-top:1.5rem; padding-top:2rem; }
        .dates{ flex-direction:column; align-items:flex-end; text-align:right;
            .date{ padding-left:0; }
        }
    }
}

@media only screen and (max-width: 880px) {
    .eventsList ul li,
    .completedEvents ul li {
        padding: 2rem;
        &:after {
            display: none;
        }
        .event {
            flex-direction: column;
            & > div:nth-of-type(1) {
                flex: auto;
                width: 100%;
                max-width: 24rem;
            }
            & > div:nth-of-type(2) {
                padding-left: 0;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .seasonsHeader{ display:none; }
    .tabsMobile{ margin:4rem 0 0 0; }
    .seasonsList ul li .seasonTitle{ flex-wrap:wrap;
        .sportName{
            p:nth-of-type(1), p:nth-of-type(2){ text-align:center; }
        }
        .btns{ flex:1 0 18rem; max-width:18rem; border-top:none; margin-top:0; padding-top:0; }
        .dates{ flex-direction:row; align-items:center; text-align:left;
            .date{ padding-left:4rem; }
        }
    }
}

@media only screen and (max-width: 650px) {
    .seasonsList ul li .seasonTitle{ flex-wrap:wrap;
        .sportName{
            p:nth-of-type(1), p:nth-of-type(2){ text-align:left; }
        }
        .btns{ flex:1 0 100%; max-width:100%; border-top:1px solid rgba(255,255,255,.1); margin-top:1.5rem; padding-top:2rem; }
        .dates{ flex-direction:column; align-items:flex-end; text-align:right;
            .date{ padding-left:0; }
        }
    }
}

@media only screen and (max-width: 600px) {
    .topNavi .buttonDiv {
        button {
            font-size: 1.4rem;
            padding: 1.8rem 1.8rem 1.8rem 5rem;
            &:before {
                left: 1.2rem;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .topNavi .buttonDiv {
        button {
            padding: 1.8rem 1.8rem 1.8rem 1.8rem;
            text-align: center;
            &:before {
                display: none;
            }
        }
    }
    .seasonsList ul li{ padding:1.5rem 2rem;
        .seasonTitle .sportName p:nth-of-type(1){ letter-spacing:1px; }
    }
}

@media only screen and (max-width: 390px) {
    .topNavi .buttonDiv {
        flex: auto;
        max-width: 100%;
    }
}
