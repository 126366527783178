.headerBackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 42rem;
    .headerBackgroundImage {
        // background-image: url('../../resources/images/headerEvents.jpg'); // loaded dynamically
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
    .headerBackgroundGradient {
        background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}
