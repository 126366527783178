.form{ max-width:80rem; padding-bottom:6rem; }

.photo{
  margin:0 0 2.4rem 0;
}

.buttons{ margin:2rem 0 0 0; display:flex; justify-content:space-between; align-items:center;
  .save{ height:5.6rem; font-size:1.4rem; display:flex; align-items:center; justify-content:center; border-radius:1.2rem; border:none; font-weight:600; color:#fff; padding:1rem 2.5rem;
    &>span{ width:2.2rem; height:2.2rem; margin:0 1.2rem 0 0;
      svg{ width:100%; height:100%; object-fit:contain; }
    }
  }
  .delete{ height:5.6rem; font-size:1.4rem; display:flex; align-items:center; justify-content:center; border-radius:1.2rem; background:#a00; border:none; font-weight:600; color:#fff; padding:1rem 2.5rem;
    &>span{ width:2.2rem; height:2.2rem; margin:0 1.2rem 0 0;
      svg{ width:100%; height:100%; object-fit:contain; }
    }
    &:hover{ background:#800; }
  }
  .back{ height:5.6rem; font-size:1.4rem; display:flex; align-items:center; justify-content:center; border-radius:1.2rem; border:none; font-weight:600; color:#bbb; padding:1rem 2.5rem; margin-left:-2.5rem;
    &:hover{ color:#fff; }
  }
}