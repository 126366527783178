.data{ display:flex; justify-content:space-between;
  &>div{ flex:4; padding:0 0.5rem;
    &>div:nth-of-type(2)>div>div>div{ border:none; box-shadow:none; }
  }
  label{ text-align:center; width:100%; justify-content:center; }
  input{ padding:1.8rem 1.5rem; }
  &>div:nth-last-of-type(1), &>div:nth-last-of-type(2), &>div:nth-last-of-type(3){ flex:5; }
}

/* rwd */
@media only screen and (max-width: 740px) {
  .data{ flex-wrap:wrap;
    &>div{ flex:1 0 16.66%; }
    &>div:nth-last-of-type(1), &>div:nth-last-of-type(2), &>div:nth-last-of-type(3){ flex:1 0 33.33%; }
  
  }
}

@media only screen and (max-width: 575px) {
  .data{ flex-wrap:wrap;
    &>div{ display:block;
      &>div:nth-of-type(1){ display:block; width:100%; }
    }
  }
}


@media only screen and (max-width: 500px) {
  .data{ flex-wrap:wrap;
    &>div{ flex:1 0 33.33%; }
    &>div:nth-last-of-type(1), &>div:nth-last-of-type(2), &>div:nth-last-of-type(3){ flex:1 0 33.33%; }
  
  }
}