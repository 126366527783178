.pin {
    display: block;
    width: 34px;
    height: 48px;
    cursor: pointer;

    position: absolute;
    transform: translate(-50%, -100%);
    background-position: center;

    background: url(../../../../resources/images/t100-mapmarker-0.svg) no-repeat;

    &.pin1 {
        background: url(../../../../resources/images/t100-mapmarker-1.svg) no-repeat;
    }

    &.pin2 {
        background: url(../../../../resources/images/t100-mapmarker-2.svg) no-repeat;
    }

    &.pin3 {
        background: url(../../../../resources/images/t100-mapmarker-3.svg) no-repeat;
    }

    &.pin4 {
        background: url(../../../../resources/images/t100-mapmarker-4.svg) no-repeat;
    }

    &.pin5 {
        background: url(../../../../resources/images/t100-mapmarker-5.svg) no-repeat;
    }

    &.pin6 {
        background: url(../../../../resources/images/t100-mapmarker-6.svg) no-repeat;
    }

    &.pin7 {
        background: url(../../../../resources/images/t100-mapmarker-7.svg) no-repeat;
    }

    &.pin8 {
        background: url(../../../../resources/images/t100-mapmarker-8.svg) no-repeat;
    }

    &.pin9 {
        background: url(../../../../resources/images/t100-mapmarker-9.svg) no-repeat;
    }
}
