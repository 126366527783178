.flex{ display:flex; justify-content:space-between; max-width:106rem;
    .contact{ flex:1 0 40%; max-width:40%; padding-left:10rem; }
    .children{ flex:1 0 60%; max-width:60%; }
}

.contact { max-width:60rem; font-size: 16px; margin:0 0 8rem 0;
    .header {
        h2{
            padding:0 0 2.4rem 4.2rem;
            margin:0 0 1rem 0;
            font-size:2.4rem;
            line-height:3.2rem;
            background-position:left 0.4rem;
            background-repeat:no-repeat;
            background-size:auto 2.4rem;
            border-bottom:0.4rem solid rgba(228,228,228,0.1);
            white-space:nowrap;
            background-image: url('../../resources/images/ico-playerProfile.svg');
        }
    }
    .field {
        label {
            color: rgba(255, 255, 255, 0.5);
            text-transform: uppercase;
        }
        h3 {
            font-size: 1.4rem;
            line-height: 1.6rem;
            text-transform: uppercase;
            color: #808191;
            font-weight: 600;
            margin: 2.2rem 0 0.5rem 0;
        }
        p {
            font-size: 1.8rem;
            line-height: 2.4rem;
            color: #fff;
            font-weight: 500;
            margin: 0;
            word-break:break-all;
            a {
                color: inherit;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.children { margin:0 0 6rem 0;
    h2{
        margin:0 0 3rem 0;
        font-size:2.4rem;
        line-height:3.2rem;
        display:none;
    }
    .childrenGrid{
        &>div>div>ul{ display:grid; grid-template-columns:33.33% 33.33% 33.33%; grid-template-rows:1fr; gap:0; grid-template-areas:". . .";
            .child{ border-bottom:none; padding:9px; margin:0;
                &>div{
                    height:100%;
                    width:100%;
                    border-radius: 2.4rem;
                    overflow: hidden;
                    border: none;
                    background-color: #242731 !important;
                    margin-bottom:0;

                    &>div:nth-of-type(2) > div > div > div:nth-of-type(1){ white-space:unset; text-overflow:unset; word-break:break-word; overflow:visible; }
                    a{ color:inherit;
                        &:hover{ text-decoration:underline; }
                    }

                    &:hover .description:after {
                        opacity: 1;
                    }
                    & > div:nth-of-type(2) {
                        padding: 2rem;
                        cursor: pointer;
                    }
                    .image {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        & > div {
                            height: inherit;
                        }
                    }
                    .imageHigh {
                        height: 24rem;
                        img {
                            object-fit: cover;
                        }
                        & > div {
                            height: inherit;
                        }
                    }
                    .imageMedium {
                        background: #fff;
                        padding: 1rem;
                        height: 17rem;
                        & > div {
                            height: inherit;
                        }
                    }
                    .meta {
                        color: #fff;
                        & > div:nth-of-type(1) {
                            & > div:nth-of-type(1) {
                                font-size: 1.8rem;
                                line-height: 2.4rem;
                                min-height: 4.8rem;
                                margin: 0 0 1.6rem 0;
                            }
                        }
                    }
                    .sportLogo {
                        width: 2.5rem;
                        height: 2.5rem;
                        margin-right: 1rem;
                    }
                    .footer {
                        display: flex;
                        align-items: center;
                        margin: 2.5rem -2rem 0 -2rem;
                        padding: 2.2rem 2rem 0.2rem 2rem;
                        border-top: 1px solid rgba(255, 255, 255, 0.05);
                        font-size: 1.2rem;
                        line-height: 1.8rem;
                        color: #808191;
                        font-weight: 500;
                        .ico {
                            width: 0.8rem;
                            height: 0.8rem;
                            border-radius: 50%;
                            background: #0049c6;
                            margin-right: 0.6rem;
                        }
                    }
                    .description{
                        ul{ padding:0; margin:0.8rem 0 0 0; }
                    }
                }
            }
            
        }
    }
}

.parents, .events {
    margin:3rem 0 10rem 0;
    
    h3{
        margin:0 0 3rem 0;
        font-size:2.4rem;
        line-height:3.2rem;
    }

    .table {
        table {
            background: #1f2128;
            margin: 0 0 0 0;
            thead {
                tr {
                    th {
                        font-size: 1.4rem;
                        line-height: 2.4rem;
                        color: #fff;
                        border-bottom: none;
                        padding: 2rem 1.5rem;
                    }
                }
            }
            tbody {
                & > tr {
                    & > td {
                        padding: 1.5rem 1.5rem;
                        border-bottom: none;
                        a {
                            color: inherit;
                            &:hover {
                                color: inherit;
                                text-decoration: underline;
                            }
                        }
                        span.live{ color:#0fad96; font-weight:500; }
                    }
                    &:nth-of-type(odd) td {
                        background: #1f2128;
                    }
                    &:nth-of-type(even) td {
                        background: #242731;
                    }
                    &:last-of-type {
                        td:first-of-type {
                            border-bottom-left-radius: 1.2rem;
                        }
                        td:last-of-type {
                            border-bottom-right-radius: 1.2rem;
                        }
                    }
                }
            }
            a{ color:inherit;
                &:hover{ text-decoration:underline; }
            }
        }
        .actions {
            span {
                font-size: 3rem;
                color: white;
            }
        }

        .name {
            color: #3F8CFF;
            font-weight:500;
            font-size:1.4rem;
            line-height:2.1rem;
            text-decoration:none;
            &:hover{ text-decoration:underline; color:#3F8CFF; }
        }

        .actions {
            border:none;
            padding:0 1rem;
            height:3rem;
            span {
                font-size: 3rem;
                height:3rem;
                color: #fff;
            }
            &:hover, &:focus{ background:transparent !important; }
        }
    }
}

.events .table table thead{
    th{ width:25%; }
}



/* rwd */
@media only screen and (max-width: 1350px) {
    .flex .contact{ padding-left:2rem; }
}


@media only screen and (max-width: 1200px){
    .flex{ flex-wrap:wrap;
        .contact{ order:1; flex:1 0 100%; max-width:60rem; margin:0 0 4rem 0; padding-left:0.9rem; }
        .children{ order:2; flex:1 0 100%; max-width:100%; }
    }
}



@media only screen and (max-width: 990px) {
    .children .childrenGrid > div > div > ul{
        grid-template-columns: 50% 50%;
        grid-template-areas: ". ."
    }
}


@media only screen and (max-width: 768px) {
    .children .childrenGrid > div > div > ul{
        grid-template-columns: 1fr;
        grid-template-areas: ".";
        gap: 0;
        .child{ height:9rem; margin:0 0 7px 0;
            &>div{ border-radius:1.2rem; display:flex; align-items:center;
                &>div:nth-of-type(1){ flex:1 0 9rem; height:9rem; max-width:9rem;
                    &>div{ width:100%; height:100%; }
                }
                &>div:nth-of-type(2){ flex:1; max-width:100%; padding:0.5rem 1.6rem;
                    a{ font-size:1.8rem; line-height:2.4rem; font-weight:500; }
                    &>div>div>div:nth-of-type(2)>div{ display:flex; flex-wrap:wrap;
                        &>div{ margin:0 1.5rem 0 0; padding-right:1.5rem; position:relative;
                            &:before{ content:''; width:1px; height:1.5rem; position:absolute; right:0; top:5px; background:rgba(255,255,255,.2); }
                        }
                        ul{ list-style:none; margin:0;
                            li{ margin-right:2rem; }
                            li:last-of-type{ margin-right:0; }
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 450px) {
    .children .childrenGrid > div > div > ul .child > div > div:nth-of-type(2) > div > div{
        & > div:nth-of-type(1){ margin-bottom:0.3rem; }
        & > div:nth-of-type(2) > div{ display:block;
            &>div{ margin:0 0 0.3rem 0; padding-right:0;
                &:before{ display:none; }
            }
        }
    }
}