.search {
    & > span > span {
        border: none;
    }
    input {
        width: 50rem !important;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #fff;
        font-weight: 600;
        padding: 0.8rem 2rem 0.8rem 4rem !important;
        height: 3.6rem;
        background-color: transparent !important;
        background-image: url('../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    & > span > span:nth-of-type(2) {
        left: 0;
        background-color: transparent;
        color: #fff;
        button {
            visibility: visible;
            border: none !important;
            width: auto !important;
            // &:after {
            //     content: 'Submit';
            //     font-size: 1.3rem;
            //     font-weight: 600;
            //     transition: all 0.3s;
            // }
            // &:hover:after {
            //     color: #fff;
            // }
            & > span {
                display: none !important;
            }
        }
    }
}

.toolbar {
    padding: 0;
    margin: 0 0 3rem 0;
    & > div > span {
        display: flex;
        align-items: center;
    }
    button {
        display: inline-flex;
        height: 5.6rem;
        padding: 2rem 1.6rem 2rem 2.4rem;
        color: #808191;
        border-radius: 1.2rem;
        align-items: center;
        font-weight: 600;
        span {
            opacity: 0.4;
            width: 1.5rem;
            height: 1.5rem;
            margin: 0 1rem 0 0;
            transition: all 0.3s;
            svg {
                color: transparent;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &:hover {
            background: #3f8cff;
            color: #fff;
            span {
                opacity: 1;
            }
            a {
                color: #fff;
            }
        }
    }
    .sports,
    .organizations {
        margin-left: 1.6rem;
        & > div {
            padding: 1.8rem 4rem 1.8rem 2.2rem !important;
        }
    }
}

div.card {
    margin-bottom: 3rem;
    border-radius: 2.4rem;
    overflow: hidden;
    border: none;
    background-color: #242731 !important;
    &:hover .description:after {
        opacity: 1;
    }
    & > div:nth-of-type(2) {
        padding: 2rem;
        cursor: pointer;
    }
    .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        & > div {
            height: inherit;
        }
    }
    .imageHigh {
        height: 24rem;
        img {
            object-fit: cover;
        }
        & > div {
            height: inherit;
        }
    }
    .imageMedium {
        background: #fff;
        padding: 1rem;
        height: 17rem;
        & > div {
            height: inherit;
        }
    }
    .meta {
        color: #fff;
        & > div:nth-of-type(1) {
            & > div:nth-of-type(1) {
                font-size: 1.8rem;
                line-height: 2.4rem;
                min-height: 4.8rem;
                margin: 0 0 1.6rem 0;
                text-overflow: unset;
                white-space: unset;
            }
        }
    }
    .footer {
        display: flex;
        align-items: center;
        margin: 2.5rem -2rem 0 -2rem;
        padding: 2.2rem 2rem 0.2rem 2rem;
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: #808191;
        font-weight: 500;
        .ico {
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            background: #0049c6;
            margin-right: 0.6rem;
        }
    }
}
.description {
    &:after {
        content: '';
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: 2rem;
        bottom: 2rem;
        background-image: url('../../resources/images/ico-edit.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0.4;
        transition: all 0.3s;
    }
    & > span {
        margin-right: 0.5rem;
    }
    & > img {
        height: 3.2rem;
        width: auto;
        margin: 0 0.7rem 0 0;
    }
    ul {
        display: flex;
        justify-content: flex-start;
        list-style: none;
        margin: 1.2rem 0 3.5rem 0;
        padding: 0;
        li {
            padding: 0 1.5rem 0 1.3rem;
            position: relative;
            color: #808191;
            font-size: 1.2rem;
            line-height: 1.6rem;
            white-space: nowrap;
            &:before {
                content: '';
                display: block;
                width: 0.8rem;
                height: 0.8rem;
                position: absolute;
                left: 0;
                top: 0.4rem;
                border-radius: 50%;
            }
            &.place:before {
                background: #0049c6;
            }
            &.height:before {
                background: #7fba7a;
            }
            &.weight:before {
                background: #2b9cc5;
            }
        }
    }
    .misc {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .number {
            flex: 1 0 4rem;
            max-width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background: #4f5055;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.3rem;
            line-height: 2rem;
            color: #fff;
            font-weight: 600;
        }
        p {
            margin: 0 0 0 1rem;
            font-size: 1.3rem;
            line-height: 2rem;
            color: #fff;
            font-weight: 600;
        }
    }
}

.teams {
    margin: 0 0 5.5rem 0;
    & > div {
        padding: 0;
        border-bottom: none !important;
    }
    .navi {
        margin: 0 0 3rem 0;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        & > div:last-of-type {
            margin-left: auto;
        }
        .teamsButtons {
            margin-right: 2rem;
            button {
                margin: 0 1rem 1rem 0;
                padding: 2rem 1.4rem;
            }
        }
        .teamName {
            font-size: 2.4rem;
            line-height: 3.2rem;
            font-weight: 500;
            color: #fff;
            margin-top: 1.2rem;
            margin-right: 4rem;
            margin-bottom: 0;
        }
        button {
            height: 5.6rem;
            border-radius: 1.2rem;
            padding: 2rem 4.5rem;
            display: inline-block;
            background-color: #242731;
            line-height: 1.6rem;
            font-size: 1.4rem;
            color: #808191;
            font-weight: 600;
            border: none;
            transition: all 0.3s;
            &:hover {
                color: #fff;
            }
            &.manage {
                display: flex;
                padding: 2rem 1.6rem 2rem 2.4rem;
                color: #808191;
                span {
                    order: 2;
                    opacity: 0.4;
                    width: 1.5rem;
                    height: 1.5rem;
                    margin: 0 0 0 3rem;
                    transition: all 0.3s;
                    svg {
                        color: transparent;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                &:hover {
                    background: #3f8cff;
                    color: #fff;
                    span {
                        opacity: 1;
                    }
                    a {
                        color: #fff;
                    }
                }
                a {
                    color: #808191;
                }
            }
            &[ant-click-animating-without-extra-node='true'] {
                background: #3f8cff;
                border-color: #3f8cff;
                span {
                    opacity: 1;
                }
            }
        }
    }
    .card {
        .edit {
            display: none;
        }
    }
    .list {
        border-radius: 1.2rem;
        overflow: hidden;
        background: #242731;
        margin: 0 0 0.7rem 0;
        padding: 0.3rem 0;
        border-bottom: none;
        & > div {
            align-items: center;
        }
        .description {
            display: flex;
            justify-content: space-between;
            .misc p {
                white-space: nowrap;
            }
            ul {
                margin: 0 0.2rem 0 0;
            }
            button {
                background: #1b1d21;
                border: none;
                font-weight: 600;
                font-size: 1.3rem;
                padding: 0.4rem 1.5rem;
                line-height: 1.6rem;
                border-radius: 0.6rem;
                height: 2.4rem;
                transition: all 0.3s;
                &:hover {
                    color: #fff;
                    background: #3f8cff;
                }
            }
        }
        .description:after {
            display: none;
        }
        .avatar {
            border-radius: 0;
            margin: -0.3rem 0;
        }
        .playerName {
            display: flex;
            align-items: center;
            h3 {
                font-size: 1.8rem;
                line-height: 2.4rem;
                font-weight: 500;
                margin-right: 2rem;
            }
        }
        .playerStats {
            display: flex;
            align-items: center;
            padding-right: 2.4rem;
        }
        .image {
            border-radius: 0;
        }
    }
}

.mobileFilter {
    & > div:nth-of-type(2) {
        background: #1a1a1a;
        & > div:nth-of-type(1) {
            background: #1a1a1a;
            & > div:nth-of-type(1) {
                background: #1a1a1a;
                & > div:nth-of-type(2) {
                    background: #1a1a1a;
                    padding: 5rem 1rem 2rem 2rem;
                }
            }
        }
    }
    ul {
        border-right: none;
        color: transparent;
        background: transparent;
        li {
            color: #fff;
            margin: 1rem 0;
            & > div {
                padding: 0 !important;
            }
            ul li div {
                padding: 0 1rem !important;
                margin: 0;
            }
        }
    }
}

/* rwd */
@media only screen and (max-width: 1230px) {
    .search input {
        width: 38rem !important;
    }
    .teams .navi {
        flex-wrap: wrap;
        & > div:nth-of-type(1) {
            flex: 1 0 100%;
            max-width: 100%;
        }
        .teamName {
            margin: 0 0 1rem 0;
        }
    }
}

@media only screen and (max-width: 1150px) {
    .teams .list {
        .description {
            flex-direction: column;
        }
        .playerName {
            margin-bottom: 1rem;
        }
    }
}

@media only screen and (max-width: 1050px) {
    .toolbar {
        & > div {
            display: block;
            & > div {
                margin-bottom: 2rem;
            }
        }
        button,
        .sports,
        .organizations {
            margin-left: 0;
            margin-right: 1.6rem;
        }
    }
}

@media only screen and (max-width: 850px) {
    .search input {
        width: 32rem !important;
    }
    .toolbar {
        margin-bottom: 1.4rem;
        & > div > span {
            flex-wrap: wrap;
        }
        .organizations,
        .sports,
        button {
            margin-bottom: 1.6rem;
        }
    }
    .teams .navi {
        button {
            padding: 2rem 2.5rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .teams {
        .navi {
            button {
                padding: 2rem 4.5rem;
            }
            button.manage {
                padding: 2rem 2rem;
                span {
                    margin: 0 0 0 1rem;
                }
                span + span {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .teams .navi {
        button {
            padding: 2rem 2.5rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .teams .navi {
        & > div:last-of-type {
            margin-left: 0;
        }
    }
}

.loadMore {
    text-align: center;
    margin: 4rem 0 0 0;
    button {
        background: #242731;
        height: 5.6rem;
        border-radius: 1.2rem;
        padding: 1.8rem 2.2rem;
        color: #808191;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
        border: none;
        transition: all 0.3s;
        &:hover {
            background: #3f8cff;
            color: #fff;
        }
    }
}

.deleteButton {
    float: right;
}

.coachTitle {
    font-size: 1.7rem;
    color: #fff;
}
