.registrationForm{ padding:6rem 0 10rem 0;
  .row{ max-width:130rem; margin:0 auto; padding:0 3rem 0 6rem;
    .flex{ display:flex; justify-content:space-between; align-items:flex-start;
      aside{ flex:1 0 38rem; max-width:38rem; position:relative; z-index:2; }
      .content{ flex:1; max-width:100%; padding-left:6rem; position:relative; z-index:2; }
    }
  }

  aside{ background:rgba(0,0,0,0.2); border-radius:1.2rem; overflow:hidden; }

  .content{
    .header{ padding:3rem 0 0 0;
      h1{ font-family:'agency-fb', sans-serif; font-size:5.8rem; line-height:6.4rem; color:#fff; font-weight:700; text-transform:uppercase; margin:0 0 2rem 0; }
      p{ font-family:Akrobat, sans-serif; font-size:2rem; line-height:3rem; color:#fff; max-width:52rem; margin:2rem 0; }
    }
  }
}


/* checkboxes */
.checkboxes{ overflow:hidden; border-radius:0.8rem; margin:0 0 6rem 0;
  .checkbox{
    &>div:nth-of-type(1){ background:#fff; padding:1.2rem 4rem 1.2rem 1.6rem; position:relative;
      &>span{ display:none !important; }
      &:after{ content:''; width:0.6rem; height:1.2rem; background:url('../../../../resources/images/ico-more.svg') no-repeat center; background-size:contain; position:absolute; right:2.5rem; top:2rem; transform:rotate(90deg); }
      &[aria-expanded="true"]:after{ transform:rotate(270deg); }
    }
    &>div:nth-of-type(2){ background:transparent;
      p{ margin:2rem 0; font-size:1.4rem; line-height:2.4rem; }
      p:first-of-type{ margin-top:0; }
      p:last-of-type{ margin-bottom:0; }
    }
    label{ font-size:1.6rem; line-height:2rem; color:#1f212b; font-weight:400; }
    input{ width:1.6rem; height:1.6rem; border:2px solid #e1e1e1 !important; border-radius:0.4rem; opacity:1; }
  }
}


/* table */
.eventtable {
  overflow-x: auto;
  div{ background:transparent; }
  table {
    background: transparent;
    margin: 0 0 0 0;
    thead {
        tr {
            th {
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: #fff;
                border-bottom: none;
                padding: 2rem 1.5rem;
                background: rgba(0,0,0,0.2);
                &:first-of-type {
                    border-top-left-radius: 0.4rem !important;
                    border-bottom-left-radius: 0.4rem !important;
                }
                &:last-of-type {
                    border-top-right-radius: 0.4rem !important;
                    border-bottom-right-radius: 0.4rem !important;
                }
            }
        }
    }
    tbody {
        & > tr {
          vertical-align:top;
            & > td {
                padding: 1.5rem 1.5rem;
                font-size:1.5rem;
                font-weight:300;
                border-bottom: 1px solid rgba(250,250,250,.1);
                a {
                    color: inherit;
                    &:hover {
                        color: inherit;
                        text-decoration: underline;
                    }
                }
                strong{ font-weight:500; display:block; }
                strong + button{ margin-top:0.4rem; }
            }
            &:hover > td{
              background:transparent !important;
            }
        }
    }
  }
}

.tableRow{ padding:1.2rem 0; display:flex; justify-content:flex-end;
  border-bottom: 1px solid rgba(250,250,250,.1);
  .btnMain{ height:4.6rem; border-radius:0.4rem; border:none; background:#3f8cff; font-size: 1.4rem; line-height: 1.6rem; padding: 1.5rem 2.4rem; color: #fff; font-weight: 500; margin:0 1.2rem 0 0; transition: all 0.3s;
    &:hover {
      color: #3f8cff;
      background: #fff;
    }
  }
}

.summaryRow{ padding:1.2rem 1.5rem; display:flex; align-items:flex-start;justify-content:space-between; border-bottom: 1px solid rgba(250,250,250,.1);
  p{ font-size:1.6rem; line-height:2.2rem; font-weight:500; color:#fff; margin:2rem 0; }
  p+p{ font-size:1.8rem; }
}

.balanceRow{ padding:1.2rem 1.5rem; display:flex; align-items:flex-start;justify-content:flex-end;
  p{ font-size:2.1rem; line-height:2.4rem; font-weight:500; color:#fff; margin:2rem 0; }
  p + p{ margin:2rem 0 2rem 7rem; }
}


/* terms */
.terms{ margin:14rem 0 0 0;
  p{ font-size:1.4rem; line-height:2rem; color:#808191; font-weight:500; }
}


/* buttons */
.buttons{ margin:4rem 0 0 0; display:flex; align-items:center; flex-wrap:wrap;
  button:last-child{ margin-right:0; }
  .btnMain{ height:4.6rem; border-radius:0.4rem; border:none; background:#3f8cff; font-size: 1.4rem; line-height: 1.6rem; padding: 1.5rem 2.4rem; color: #fff; font-weight: 500; margin:0.5rem 1.2rem 0.5rem 0; transition: all 0.3s;
    &:hover {
      color: #3f8cff;
      background: #fff;
    }
  }
  .btnSecondary{ height:4.6rem; border-radius:0.4rem; border:none; background:#153456; font-size: 1.4rem; line-height: 1.6rem; padding: 1.5rem 2.4rem; color: #fff; font-weight: 500; margin:0.5rem 1.2rem 0.5rem 0; transition: all 0.3s;
    &:hover {
      color: #153456;
      background: #fff;
    }
  }
}



/* rwd */
@media only screen and (max-width: 1050px){
  .registrationForm .row .flex aside{ flex:1 0 30rem; max-width:30rem; }
}


@media only screen and (max-width: 950px){
  .registrationForm .row .flex{ display:block;
    aside{ max-width:100%; margin:0 0 4rem 0; display:flex; align-items:stretch; }
    .content{ max-width:100%; padding:0; }
  }
}


@media only screen and (max-width: 900px){
	.registrationForm .row{ padding:0 3rem; }
}


@media only screen and (max-width: 750px){
	.registrationForm .row{ padding:0 2rem; }
}


@media only screen and (max-width: 600px){
  .registrationForm{
    .content .header{
      h1{ font-size:4.8rem; line-height:5.4rem; }
      p{ font-size:1.8rem; line-height:2.8rem; }
    }
    .row .flex{ display:block;
      aside{ display:block; }
    }
  }
}


@media only screen and (max-width: 400px){
  .registrationForm{
    .content .header{
      h1{ font-size:4.0rem; line-height:4.8rem; }
      p{ font-size:1.6rem; line-height:2.4rem; }
    }
    .form{
      h2{ font-size:2.2rem; line-height:3.0rem; }
      h3{ font-size:1.8rem; line-height:2.6rem; }
    }
  }
  .balanceRow p + p{ margin:2rem 0 2rem 4rem; }
}