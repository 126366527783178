@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

@import url('https://p.typekit.net/p.css?s=1&k=igm8sqo&ht=tk&f=30684.30685&a=32276116&app=typekit&e=css');
@font-face {
    font-family: 'agency-fb';
    src: url('https://use.typekit.net/af/9ce0b8/00000000000000003b9ae623/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/9ce0b8/00000000000000003b9ae623/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/9ce0b8/00000000000000003b9ae623/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

.header {
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;
        .headerBackgroundImage {
            background-image: url('../../resources/images/headerEvents.jpg');
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .headerBackground + div {
        padding: 0;
    }

    // button {
    //     height: 5.6rem;
    //     border-radius: 1.2rem;
    //     border: 1px solid #555;
    //     font-size: 1.4rem;
    //     font-weight: 600;
    //     margin: 0 0 0 0.8rem;
    //     display: flex;
    //     align-items: center;
    //     span {
    //         width: 2.2rem;
    //         height: 2.2rem;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         margin: 0 1rem 0 0;
    //         svg {
    //             display: block !important;
    //             width: 2.2rem;
    //             height: 2.2rem;
    //             z-index: 3;
    //             color: transparent;
    //         }
    //     }
    //     &:hover,
    //     &[ant-click-animating-without-extra-node='true'] {
    //         background: #3f8cff;
    //         border: 1px solid #3f8cff !important;
    //         color: #fff;
    //     }
    // }

    .back {
        width: 4.4rem;
        height: 4.4rem;
        margin: 1rem 0 0 2rem;
        background-image: none !important;
        span {
            width: 4.4rem;
            height: 4.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                display: block !important;
                width: 2.4rem;
                height: 2.4rem;
                z-index: 3;
                color: transparent;
            }
        }
    }
}

.search,
.mobileSearch {
    & > span > span {
        border: none;
    }
    input {
        width: 50rem !important;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #fff;
        font-weight: 600;
        padding: 0.8rem 2rem 0.8rem 4rem !important;
        height: 3.6rem;
        background-color: transparent !important;
        background-image: url('../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    & > span > span:nth-of-type(2) {
        left: 0;
        background-color: transparent;
        color: #fff;
        button {
            visibility: visible;
            border: none !important;
            width: auto !important;
            & > span {
                display: none !important;
            }
        }
    }
}

.mobileSearch {
    display: none;
}

.name {
    p:nth-of-type(1) {
        font-size: 2.1rem;
        line-height: 2.6rem;
        margin: 0;
    }
    p:nth-of-type(2) {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin: 0;
    }
}

h1 {
    font-family: 'agency-fb', sans-serif;
    font-size: 4.8rem;
    line-height: 5.4rem;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 0 0;
    position: relative;
    z-index: 2;
}

.tableHeader {
    margin: -3rem 0 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filters {
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #808191;
        margin: 1rem 0 1rem 0;
        display: flex;
        align-items: center;
        height: 5.6rem;
        white-space: nowrap;
    }
    .filter {
        margin: 1rem 0 1rem 2rem;
        & > div {
            background: #242731 !important;
        }
        & > span {
            background: #242731 !important;
        }
    }
    .flex {
        display: flex;
        align-items: flex-start;
        .flex {
            flex-wrap: wrap;
        }
    }
}

.search {
    flex: 1;
    max-width: 50rem;
    & > span > span {
        border: none;
    }
    span {
        border-color: transparent !important;
        border: none !important;
    }
    input {
        width: 100% !important;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #fff;
        font-weight: 600;
        padding: 0.8rem 2rem 0.8rem 4rem !important;
        height: 3.6rem;
        background-color: transparent !important;
        background-image: url('../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    & > span > span:nth-of-type(2) {
        left: 0;
        background-color: transparent;
        color: #fff;
        button {
            visibility: visible;
            border: none !important;
            width: auto !important;
            & > span {
                display: none !important;
            }
        }
    }
}

.table {
    padding: 0 0 4rem 0;
    overflow-x: auto;
    table {
        background: #1f2128;
        margin: 0 0 0 0;
        thead {
            tr {
                th {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    color: #fff;
                    border-bottom: none;
                    padding: 2rem 1.5rem;
                }
            }
        }
        tbody {
            & > tr {
                & > td {
                    padding: 1.5rem 1.5rem;
                    border-bottom: none;
                    a {
                        color: inherit;
                        text-decoration: none;
                        &:hover {
                            color: inherit;
                            text-decoration: underline;
                        }
                    }
                }
                &:nth-of-type(odd) td {
                    background: #1f2128;
                }
                &:nth-of-type(even) td {
                    background: #242731;
                }
                &:last-of-type {
                    td:first-of-type {
                        border-bottom-left-radius: 1.2rem;
                    }
                    td:last-of-type {
                        border-bottom-right-radius: 1.2rem;
                    }
                }
            }
        }
    }

    ul {
        li {
            font-weight: 600;
            a {
                transition: all 0.3s;
            }
        }
    }

    .username {
        color: #3f8cff;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.1rem;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: #3f8cff;
        }
    }

    .email {
        color: #808191;
        font-size: 1.3rem;
        line-height: 2.1rem;
    }

    .family {
        font-size: 1.3rem;
        color: #3f8cff;
        line-height: 2.1rem;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: #3f8cff;
        }
    }
    a {
        text-decoration: underline;
        color: inherit;
        &:hover {
            text-decoration: none;
        }
    }
}

.modal {
    width: 478px;
    height: 600px;
}

/* rwd */
@media only screen and (max-width: 1250px) {
    .tableHeader {
        display: block;
        margin: -3rem 0 2rem 0;
        .filters {
            margin: 0 2rem 1rem 0;
        }
        .filter {
            margin: 0 2rem 1rem 0;
            &:last-child {
                margin: 0;
            }
        }
    }
    .search {
        margin: 0 0 2rem 0;
    }
}

@media only screen and (max-width: 767px) {
    .tableHeader {
        margin: 0 0 2rem 0;
    }
    h1 {
        font-size: 2.4rem;
        line-height: 2.8rem;
    }
}
