@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        font-size: 1.4rem;
        line-height: 2rem;
        color: #808191;
        margin: 0 2rem 0 0;
        a {
            color: inherit;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .headerSelect {
        font-size: 1rem;
        letter-spacing: 1px;
        font-weight: 300;
        .addUser {
            height: 4.4rem;
            & > div {
                height: 4.4rem !important;
                background: transparent !important;
                border: 1px solid rgba(228, 228, 228, 0.1) !important;
                line-height: 2rem;
                padding: 1.1rem 2rem 1.1rem 1.6rem !important;
                transition: all 0.3s;
                & > span {
                    color: #fff;
                }
                &:hover {
                    background-color: rgba(255, 255, 255, 0.05) !important;
                }
            }
            & > span {
                background: transparent !important;
                right: 1.6rem !important;
            }
        }
        .downloadExcel {
            margin-right: 1.5rem;
            height: 4.5rem;
            border-radius: 1.2rem;
            border: 1px solid rgba(228, 228, 228, 0.1);
            font-size: 1.4rem;
            line-height: 2.4rem;
            color: #fff;
            font-weight: 700;
            transition: all 0.3s;
            span {
                width: 1.7rem;
                height: 1.7rem;
                margin-right: 0.7rem;
                color: transparent;
                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            &:hover {
                background-color: #3f8cff;
                color: #fff;
            }
        }
    }
}

.table {
    background: transparent;
    margin: 2rem 0 5rem 0;
    border-radius: 0.8rem;
    table {
        background: #1f2128;
        margin: 0 0 0 0;
        thead {
            tr {
                th {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    color: #fff;
                    border-bottom: none;
                    padding: 2rem 1.5rem;
                }
            }
        }
        tbody {
            & > tr {
                & > td {
                    padding: 1.5rem 1.5rem;
                    border-bottom: none;
                    a {
                        color: inherit;
                        text-decoration: none;
                        &:hover {
                            color: inherit;
                            text-decoration: underline;
                        }
                    }
                }
                &:nth-of-type(odd) td {
                    background: #1f2128;
                }
                &:nth-of-type(even) td {
                    background: #242731;
                }
                &:last-of-type {
                    td:first-of-type {
                        border-bottom-left-radius: 1.2rem;
                    }
                    td:last-of-type {
                        border-bottom-right-radius: 1.2rem;
                    }
                }
            }
        }
    }

    .name {
        color: #3f8cff;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.1rem;
        text-decoration: none;
        &:hover {
            color: #3f8cff;
            text-decoration: underline;
        }
    }
    a {
        text-decoration: underline;
        color: inherit;
        &:hover {
            text-decoration: none;
        }
    }
}

.modal {
    width: 478px;
    height: 600px;
}

.players {
    font-size: 2.1rem;
    line-height: 3.2rem;
    margin: 5rem 0 2rem 0;
    font-weight: 500;
}

.playersTableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6rem 0 2rem 0;
    .players {
        margin: 0 3rem 0 0;
    }
    .flex {
        display: flex;
        align-items: center;
    }
    .search {
        height: 3.2rem;
        & > span {
            & > span {
                border: none;
                padding: 0;
                box-shadow: none !important;
                input {
                    height: 3.2rem;
                    width: 100%;
                    max-width: 22rem;
                    padding: 0 0 0 3.2rem;
                    background-color: transparent !important;
                    background-image: url('../../../resources/images/ico-search.svg');
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-size: 2rem auto;
                }
            }
            & > span:nth-of-type(2) {
                display: none;
            }
        }
    }
    .filters {
        display: flex;
        align-items: center;
        .button {
            height: 4.4rem;
            border-radius: 2.2rem;
            padding: 1.4rem 2rem;
            display: inline-block;
            background-color: #242731;
            line-height: 1.6rem;
            font-size: 1.4rem;
            color: #808191;
            font-weight: 600;
            border: none;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
            }
            &:disabled {
                opacity: 0.5;
            }
        }
        .totalPlayers {
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 600;
            margin: 0 2rem 0 0;
            color: #808191;
            white-space: nowrap;
        }
        .filter {
            width: 4.4rem;
            height: 4.4rem;
            border-radius: 0.8rem;
            border: none;
            background-color: #242731;
            margin: 0 1rem 0 0;
            transition: all 0.3s;
            &:hover {
                background: #3f8cff;
            }
        }
    }
}

.actions {
    border: none;
    padding: 0 1rem;
    height: 3rem;
    span {
        font-size: 3rem;
        height: 3rem;
        color: #fff;
    }
    &:hover,
    &:focus {
        background: transparent !important;
    }
}

/* rwd */
@media only screen and (max-width: 1100px) {
    .playersTableHeader {
        flex-direction: column;
        align-items: flex-start;
        .filters {
            margin-top: 2rem;
            justify-content: space-between;
            width: 100%;
            .filter {
                margin-left: auto;
            }
        }
        & > .flex:nth-of-type(2) {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 767px) {
    .familyNav {
        .flex {
            .back {
                height: 2.8rem;
            }
            h1 {
                font-size: 2.4rem;
                line-height: 2.8rem;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .playersTableHeader {
        .filters {
            flex-wrap: wrap;
            justify-content: flex-start;
            .totalPlayers {
                flex: 1 0 100%;
                margin: 0 0 2rem 0;
            }
            .filter {
                margin-left: 0;
            }
        }
    }
}
