body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ql-container {
    font-family: 'IBMPlexSans' !important;

    font-size: 14px !important;
}

.ql-editor {
    min-height: 100px;
}

.ql-bubble .ql-editor {
    padding: 0;
    color: #fff !important;

    min-height: auto;

    margin-bottom: 28px;
}

.ql-bubble .ql-tooltip {
    display: none;
}
