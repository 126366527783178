.selected {
    background-color: #3f8cff !important;
    color: #fff !important;
}

.teamName {
    font-size: 25px;
    display:flex;
    align-items:center;
    button{ margin-left:1rem;
        &:hover{ background-color:#3f8cff; color:#fff; border-color:#3f8cff; }
    }
}
