.layout {
    min-height: 100vh;
    background: #1d1e23;

    /** TODO: @Michal - we have restored main section styles **/

    .main {
        background-color: #1d1e23;

        .spinner {
            margin-top: 35rem;

            span {
                font-size: 64px;

                i {
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
}

body{ height:unset !important; }


/* layout */
.pageLayout{ padding-top:14.6rem; background:#00183a; }
.row{ max-width:138rem; height:inherit; margin:0 auto; padding:0 3rem 0 6rem; position:relative; z-index:5; }
body > div:nth-of-type(1){ height:auto !important; overflow:hidden; }

.pageContent{ width:148rem; margin:0 auto; position:relative; z-index:2; padding-left:3rem;
    .line{ width:1px; position:absolute; left:3rem; top:36.5rem; bottom:-100000vh; background:rgba(255,255,255,0.3);
        p{ font-family:Akrobat, sans-serif; font-size:2.8rem; line-height:3.6rem; font-weight:400; letter-spacing:0.2em; color:rgba(255,255,255,.5); position:absolute; white-space:nowrap; transform:rotate(-90deg); transform-origin:left; margin:0; position:absolute; left:0.2rem; top:-4.5rem; }
    }
}
.gradient{ background:radial-gradient(circle, rgba(1,45,108,1) 0%, rgba(1,45,108,0) 70%, rgba(1,45,108,0) 100%); width:300rem; height:300rem; margin-left:-150rem; position:absolute; left:100%; top:-100rem; border-radius:50%; z-index:0; }
		



/* header */
header.head{ padding:2rem 0; position:absolute; left:0; top:0; right:0; z-index:50; font-family:Akrobat, sans-serif; transition:all .3s;
	.row{ display:flex; justify-content:space-between; align-items:center; max-width:100%;
		.logo{ flex:1 0 9.7rem; max-width:9.7rem; height:10.7rem; transition:all .3s;
			a{ display:block; text-indent:-999rem; width:100%; height:100%; background:url('../../resources/images/top100-logo.png') no-repeat center; background-size:contain; }
		}
		.mainMenu{ flex:1; padding:0 0 0 2rem;
			.scroll{
				.content{ display:flex; justify-content:space-between; align-items:center; width:100%;
					.menuContainer{ width:100%; }
					ul.menu{ margin:0 0; padding:0; list-style:none; display:flex; justify-content:flex-start; align-items:center; width:100%;
						&>li{ margin:0 2.2rem; padding:0; position:relative;
							&:nth-last-of-type(4){ margin-left:auto; }
							
							a, button{ color:#fff; text-transform:uppercase; font-size:1.8rem; line-height:2.2rem; font-weight:600; position:relative; padding:1.1rem 0; white-space:nowrap;
								&:hover{
									&:before{ left:-0.5rem; right:50%; }
									&:after{ right:-0.5rem; left:50%; }
								}
								&:before, &:after{ content:''; display:block; height:0; border-bottom:1px solid #fff; position:absolute; top:4.2rem; transition:all .3s; }
								&:before{ left:-50%; right:150%; }
								&:after{ right:-50%; left:150%; }
							}

							&:nth-last-of-type(2){ 
								a{ font-family:agency-fb, sans-serif; padding:1.1rem 1.6rem; font-weight:700; background:#bd1c2e; border-radius:0.4rem; transition:all .3s;
									&:hover{ color:#bd1c2e; background:#fff; }
									&:before, &:after{ display:none; }
								}
							}

							&:nth-last-of-type(1){ margin-right:0;
								button{ border:none; padding:0.5rem 0;
									&:before, &:after{ top:3.5rem; opacity:1; }
								}
							}

							&>.subMenu{ position:absolute; left:-1.5rem; top:3.4rem; padding:0.5rem 1.5rem; background:#fff; display:none; list-style:none; margin:0; min-width:14rem; border-radius:.5rem; flex-direction:column;
								li{ padding:0; margin:1.5rem 0;
									a{ color:#141F4E; font-size:1.6rem; line-height:2rem; font-weight:600; transition:all .3s;
										&:hover{ color:#bd1c2e; }
										&:before, &:after{ display:none; }
									}
								}
							}
							&:hover .subMenu{ display:block; }
							&.hasChildren{
								&>a{ padding-right:2.5rem; background:url('../../resources/images/ico-submenu.svg') no-repeat right 1.8rem; background-size:1.4rem auto;
									&:before, &:after{ display:none; }
								}
							}
						}
					}
				}
			}
		}
	}
}

.scrolled{
	.mobileTrigger{ top:1.5rem;
		&.opened{ top:2.3rem; }
	}
	header.head{ padding:2rem 0; background:#00183a;
		.logo{ flex:1 0 5.8rem; max-width:5.8rem; height:6.4rem; }
	}
}

.mobileTrigger{ display:none; height:3.7rem; width:4.5rem; cursor:pointer; padding:0; position:absolute; right:2rem; top:3.5rem; z-index:110; transition:all .3s;
	div{ width:2.5rem; height:2px; background:#fff; margin:.8rem 1rem; transition:all .3s; }
	&.opened{ position:fixed; top:3rem;
		div{ position:absolute; top:2.1rem; left:0; background:#fff; border:none; -ms-transform:rotate(45deg); -moz-transform:rotate(45deg); -webkit-transform:rotate(45deg); transform:rotate(45deg); }
		div + div{ display:none; }
		div + div + div{ display:block; position:absolute; top:2.1rem; left:0; -ms-transform:rotate(-45deg); -moz-transform:rotate(-45deg); -webkit-transform:rotate(-45deg); transform:rotate(-45deg); }
	}
}



/* footer */
footer{ z-index:3; position:relative; font-family:Akrobat, sans-serif;
	.row{ max-width:142rem; display:flex; justify-content:space-between; align-items:center; position:relative; z-index:3; }
}
footer.pageFooter{ margin:0 0 0 0; border-bottom:1px solid #C51134; overflow:hidden; position:relative; background-color:#00183a;
	.gradient{ width:200rem; height:200rem; background:radial-gradient(circle, rgba(0,51,122,1) 0%, rgba(0,51,122,0) 100%); position:absolute; top:50%; left:50%; margin:-100rem 0 0 -100rem; }
	.logo{ display:flex; align-items:center; justify-content:space-between; flex:1 0 38rem; max-width:38rem; background:#00183A; margin-left:-6rem; flex-wrap:wrap; padding:3rem 4rem 3.5rem 4rem;
		a.footerLogo{ display:block; flex:1 0 7.8rem; max-width:7.8rem; width:7.8rem; height:8.5rem; background:url('../../resources/images/top100-logo.png') no-repeat center; background-size:contain; text-indent:-999rem; }
		.menuSocial{
			ul{ display:flex; list-style:none; margin:0 -1rem; padding:0;
				li{ margin:0 1rem;
					a{ display:block; min-width:2.2rem; height:2.2rem; background-repeat:no-repeat; background-position:center; text-indent:-999rem; transition:all .3s;
						&:hover{ transform:scale(1.2); }
						&[href*="facebook"]{ background-image:url('../../resources/images/social-facebook.svg'); background-size:auto 2.1rem; }
						&[href*="instagram"]{ background-image:url('../../resources/images/social-instagram.svg'); background-size:auto 2.1rem; }
						&[href*="twitter"]{ background-image:url('../../resources/images/social-twitter.svg'); background-size:auto 1.8rem; }
						&[href*="youtube"]{ width:2.7rem; background-image:url('../../resources/images/social-youtube.svg'); background-size:auto 1.8rem; }
					}
				}
			}
		}
		&>div{ display:flex; align-items:center; justify-content:space-between; width:100%; }
		nav{ flex:1 0 100%; max-width:100%; margin:4.5rem 0 0 0;
			ul{ margin:0; padding:0; list-style:none;
				li{ margin:2rem 0; padding:0 0 2rem 4rem; position:relative; font-family:agency-fb, sans-serif; font-size:2.1rem; line-height:2.4rem; margin:0; color:#fff; font-weight:700; text-transform:uppercase;
					p{margin: 0;}
					a{ font-family:Akrobat, sans-serif; font-size:1.6rem; color:#fff; font-weight:400; text-transform:none;
						&:hover{ text-decoration:underline; }
					}
					&:before{ content:''; display:block; background-repeat:no-repeat; width:2.5rem; height:2.7rem; background-size:contain; position:absolute; left:0; top:0; }
					&:nth-of-type(1):before{ background-image:url('../../resources/images/ico-headquarters.svg'); background-position:.4rem top; }
					&:nth-of-type(2):before{ background-image:url('../../resources/images/ico-testing.svg'); background-position:left top; }
				}
			}
		}
	}
	.menuFooter{ padding:4rem 0 4rem 6rem;
		&>ul{ margin:0 -4rem; padding:0; list-style:none; display:flex; justify-content:flex-end;
			&>li{ padding:0 4rem; margin:0;
				a{ font-size:1.8rem; line-height:2.6rem; color:#fff; font-weight:800; text-transform:uppercase; white-space:nowrap;
					&:hover{ text-decoration:underline; }
				}
				a[href="#"]{ cursor:default; }
				a[href="#"]:hover{ text-decoration: none; }
				ul{ margin:1.2rem 0 0 0; padding:0; list-style:none;
					li{ padding:0; margin:.5rem 0;
						a{ font-size:1.6rem; line-height:2.6rem; font-weight:400; text-transform:none; color:rgba(255,255,255,.7);
							&:hover{ text-decoration:underline; }
						}
						ul{ margin:0 0 0 1rem; }
					}
				}
			}
		}
	}
}
footer.footerLegal{ padding:2.5rem 0; background:#00183A;
	.row{
		p{ font-size:1.3rem; line-height:2.2rem; color:#fff; margin:0; }
		a{ display:block; height:2rem; width:9.8rem; background:url('../../resources/images/logo-premier.png') no-repeat center; background-size:contain; }
	}
}



/* rwd */
@media only screen and (min-width: 1500px){	
	footer.pageFooter .gradient{width:160vw; margin: -100rem 0 0 -130rem;}
}


@media only screen and (min-width: 1381px){	
	#head #mainMenu{ position:static !important; display:block !important; }
}


@media only screen and (max-width: 1380px){
	.pageLayout{ padding-top:13.6rem; }
	header.head{ padding:1.5rem 0;
		.mobileTrigger{ display:block; }
		.row{
			.mainMenu{ position:fixed; width:100%; top:0; height:100vh; right:-31rem; max-width:30rem; z-index:50; background:rgba(20, 31, 78, 0.95); padding:10rem 0 0 0; margin:0;
				.scroll{ overflow-x:hidden; height:100%; overflow-y:auto; padding:0 3rem 0 3rem;
					.content{ min-height:100%; display:flex; flex-direction:column; align-items:flex-start; justify-content:center; padding:0 0 2rem 0;
						.menuContainer{
							&>ul.menu{ display:block; margin:0; padding:0 0 5rem 0; text-align:left;
								&>li{ margin:5rem 0; height:auto;
									a{ font-size:1.8rem; color:#fff; height:auto; width:100%; text-align:left;
										&:hover{ border-bottom:none;
											&:before{ display:none; }
											&:after{ display:none; }
										}
									}
									& > .subMenu{ display:block; position:static; background:transparent; padding:0;
										&>li{ margin:1.5rem 0;
											&>a{ color:#fff; }
										}
									}
									&.hasChildren > a{ background:none; padding-right:0; }
								}
							}
						}
					}
				}
			}
		}
	}
}


@media only screen and (max-width: 1350px){
	footer.pageFooter{
		.row{ justify-content:flex-start; align-items:stretch; }
		.logo{ flex-direction:column; flex-wrap:nowrap;
			nav{ flex:auto; width:100%; }
		}
		.menuFooter > ul{ flex-wrap:wrap; justify-content:flex-start; max-width:66rem; margin:-2rem -4rem;
			li{ flex:1 0 33.33%; max-width:33.33%; margin:2rem 0; }
		}
	}
}


@media only screen and (max-width: 1000px){
	footer.pageFooter .menuFooter > ul li{ flex:1 0 50%; max-width:50%; }
}


@media only screen and (max-width: 900px){
	.pageContent{ display:none; }
	header.head .row{ padding:0 3rem; }
	footer.pageFooter .row{ padding:0 3rem; }
	footer.pageFooter .logo{ margin-left:-4rem; }
}


@media only screen and (max-width: 750px){
	header.head .row{ padding:0 2rem; }
	footer.pageFooter .row{ padding:0 2rem; }

	footer.pageFooter{
		.row{ flex-direction:column;
			.logo{ flex:auto; width:auto; max-width:unset; margin:0 -4rem; align-items:flex-start;
				&>div{ max-width:27rem; }
			}
		}
		.menuFooter{ padding:4rem 0 4rem 0;
			& > ul{ max-width:unset;
				li{ flex:1 0 33.33%; max-width:33.33%; }
			}
		}
	}
}


@media only screen and (max-width: 600px){
	footer.pageFooter .menuFooter > ul li{ flex:1 0 50%; max-width:50%; }
}


@media only screen and (max-width: 400px){
	footer.pageFooter .menuFooter > ul li{ flex:1 0 100%; max-width:100%; }
	footer.footerLegal .row{ display:block;
		p{ margin:0 0 10px 0; }
	}
}