.input {
    margin-bottom: 3rem;
    .select {
        width: 54rem;
    }
}

.tabs {
    //margin-top: 2rem;
    //padding-bottom: 6rem;
    & > div:nth-of-type(1) {
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        margin: 0;
        &:before {
            display: none;
        }
        & > div:nth-of-type(1) {
            margin: 0.6rem 0.8rem 0 0;
            height: 6rem;
            border: none !important;
            & > div {
                width: auto;
                & > div {
                    font-size: 1.6rem;
                    font-weight: 500;
                    text-align: center;
                    width: 100%;
                    color: #ffffff;
                    margin-left: 0;
                    margin-right: 2rem;
                    padding: 0 1rem;
                    background-color: transparent !important;
                }
            }
        }
    }
    & > div:nth-of-type(2) > div > div {
        margin-top: 2rem;
    }
}
