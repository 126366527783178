.form {
    max-width: 75rem;
    margin: 0 0 8rem 0;

    h2 {
        margin: 1rem 0 4rem 0;
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 500;
        color: #fff;
    }

    .marginTop {
        margin-top: 1.4rem;
    }

    .buttons {
        margin: 4rem 0 0 0;
    }

    .remove {
        margin-top: 1.5rem;
        svg {
            width: 14px;
            height: 14px;
        }
    }
}

.label {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-weight: bold;
}

.labelContent {
    font-size: 1.7rem;
}
