@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
        font-size: 4.8rem;
        line-height: 6.4rem;
        color: #fff;
        font-weight: 700;
        margin: 0 0 0.5rem 0;
        position: relative;
        z-index: 2;
    }
    p{
        font-size:1.8rem;
        line-height:2.4rem;
        margin:0;
        font-weight:500;
    }
}
.eventStatus {
    padding-left:4rem;
    p{
        margin:0 0 0.2rem 0;
    }
    .upcoming {
        font-size: 16px;
        color: #7fba7a;
        float: right;
        font-weight:600;
    }
    .completed {
        font-size: 16px;
        color: #8e80ed;
        float: right;
        font-weight:600;
    }
}


h2{
    font-size:2.1rem;
    line-height:3.2rem;
    font-weight:600;
    margin:5rem 0 1rem 0;
}


.divider {
    margin: 2rem 4rem 3.5rem 4rem;
    background: #e4e4e4;
    opacity: 0.1;
    min-width: auto;
    width: auto;
}


.checkbox {
    border: none;
    margin-bottom: 2rem;
}


.name{
    p:nth-of-type(1) {
        font-size: 2.1rem;
        line-height: 2.6rem;
        margin: 0;
    }
    p:nth-of-type(2) {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin: 0;
    }
}

.tableHeader {
    margin-bottom: 3rem;
    display: flex;
    align-items:center;
    justify-content:space-between;
    .buttons{ display:flex; align-items:center; }
    button{
        font-weight:600;
    }

    .addUser { 
        height:5.6rem !important;
        background: transparent !important;
        border: 1px solid rgba(228, 228, 228, 0.1) !important;
        line-height:2rem;
        border-radius:1.2rem;
        padding:1.7rem 1.6rem 1.7rem 1.6rem !important;
        transition:all .3s;
        &:hover{ background-color:rgba(255,255,255,.05) !important; }
        &>span{ margin-left:0.7rem; }
    }
    & > span {
        background: transparent !important;
        right:1.6rem !important;
    }
}

.button {
    float: right;
    display:flex;
    align-items:center;
    min-width: 140px;
    height: 56px;
    background: #242731;
    border-radius: 12px;
    margin-left: 1rem;
    border:none;
    &:hover {
        color: white;
        background-color: #3f8cff;
    }
    svg{
        width:2rem;
        height:2rem;
    }
}

.buttonQR {
    float: right;
    height: 56px;
    min-width: 140px;
    border-radius: 12px;
    margin-left: 1rem;
    background: #242731;
    border-radius: 12px;
    border:none;
    &:hover {
        color: white;
        background-color: #3f8cff;
    }
}

.plus {
    margin-right: 1rem;
}

.search {
    max-width: 60rem;
    & > span > span {
        border: none;
        background:rgba(255,255,255,0.02);
        border-radius:0.8rem !important;
    }
    span{ border-color:transparent !important; border:none !important; }
    input {
        width: 100% !important;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #fff;
        font-weight: 600;
        padding: 0.8rem 2rem 0.8rem 4rem !important;
        height: 3.6rem;
        background-color: transparent !important;
        background-image: url('../../../../resources/images/ico-search.svg');
        background-repeat: no-repeat;
        background-position: left center;
    }
    & > span > span:nth-of-type(2) {
        left: 0;
        background-color: transparent;
        color: #fff;
        button {
            visibility: visible;
            border: none !important;
            width: auto !important;
            & > span {
                display: none !important;
            }
        }
    }
}

.table {
    background: transparent;
    margin: 2rem 0 8rem 0;
    border-radius: 0.8rem;
    overflow:hidden;
    &>div>div>div{ background:transparent; }
    thead {
        tr {
            th {
                background: #2d3038;
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: #fff;
                border-bottom: none;
                padding: 2rem 1.5rem;
                &:before {
                    display: none;
                }
            }
        }
    }
    tbody {
        & > tr {
            & > td {
                padding: 1.5rem 1.5rem;
                border-bottom: none;
                & > div {
                    margin: 0;
                }
                a {
                    color: inherit;
                    &:hover {
                        color: inherit;
                        text-decoration: underline;
                    }
                }
                input[type='text'],
                input[type='email'],
                input[type='number'],
                input {
                    background-color: transparent !important;
                    border: 1px solid transparent;
                    padding: 0 1rem;
                    height: 3.4rem;
                    border-radius: 0.4rem;
                }
            }
            &:nth-of-type(odd) td {
                background: #1f2128;
            }
            &:nth-of-type(even) td {
                background: #23252c;
            }
            &:last-of-type {
                td:first-of-type {
                    border-bottom-left-radius: 1.2rem;
                }
                td:last-of-type {
                    border-bottom-right-radius: 1.2rem;
                }
            }
        }
    }
}
/* rwd */
@media only screen and (max-width: 1400px) {
    .search{ width:34rem; }
}


@media only screen and (max-width: 1200px) {
    .tableHeader{ display:block;
        .addUser{ margin:0 1rem 1rem 0; }
        .buttons{ margin-top:2rem; flex-wrap:wrap; }
        .button, .buttonQR{ margin:0 1rem 1rem 0; }
    }
}


@media only screen and (max-width: 900px) {
    .header h1 {
        font-size: 3rem;
        line-height: 4rem;
    }
    .subheader h2 {
        font-size: 1.4rem;
    }
}


@media only screen and (max-width: 767px) {
    .header {
        margin-left: -14.6rem;
        margin-top: -1.3rem;
        height: 8rem;
        width: 100vw;
        padding: 0;
        position: relative;
        background: #1d1e23;
        z-index: 2;
        button.back {
            background-image: none !important;
            width: 4.4rem;
            height: 4.4rem;
            margin: 1rem 0 0 2rem;
            span {
                width: 4.4rem;
                height: 4.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    display: block !important;
                    width: 2.4rem;
                    height: 2.4rem;
                    z-index: 3;
                    color: transparent;
                }
            }
        }
        h1 {
            width: 100%;
            text-align: center;
            padding-right: 6.4rem;
            margin-top: 1rem;
        }
    }
}


@media only screen and (max-width: 500px) {
    .header {
        h1 {
            font-size: 2.6rem;
            line-height: 3.4rem;
            padding-right: 3.9rem;
        }
    }
}

@media only screen and (max-width: 450px) {
    .wristbands .wristband {
        flex-direction: column;
        align-items: flex-start;
        .checkbox{ margin-bottom:1rem; }
        .qr {
            margin: 0 0 1rem 0;
        }
    }
}

/* print */
.print {
    background-color: #fff !important;
}

@media only print {
    @page {
        size: 11in 1in;
        margin: 0 0 0 0;
    }
    body {
        height: auto;
        width: auto;

        #root {
            height: auto;
            width: auto;
        }
    }

    .wristbands {
        width: 11in;
        margin: 0 auto;
        .wristband {
            height: 1in;
            justify-content: space-between;
            border-bottom: none;
            .xxx {
                display: block;
                height: 100%;
                img {
                    height: 100%;
                    width: auto;
                }
            }
            .logo {
                display: block;
            }
            .qr {
                height: auto;
                margin-right: 0;
                & > div {
                    width: 100% !important;
                    height: 100%;
                }
                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }
            .name {
                position: relative;
                p:nth-of-type(1) {
                    font-weight: 600;
                    font-size: 2.1rem;
                    line-height: 2.6rem;
                    margin: 0;
                }
                p:nth-of-type(2) {
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    margin: 0;
                }
                p:nth-of-type(3) {
                    display: block;
                }
                &:after {
                    content: '';
                    width: 1px;
                    height: 0.56in;
                    background: #898989;
                    position: absolute;
                    top: 50%;
                    bottom: 0;
                    right: 0;
                    margin-top: -0.28in;
                }
            }
            .event {
                display: block;
                padding-left: 1.5%;
                p {
                    font-size: 1.3rem;
                    line-height: 1.6rem;
                    margin: 0;
                }
            }
            & > div:nth-of-type(2) {
                img {
                    float: right;
                }
            }

            & > div:nth-of-type(2) {
                flex: 1 0 23.7%;
                max-width: 23.7%;
                padding-right: 2%;
            }
            .event {
                flex: 1 0 22%;
                max-width: 22%;
                padding-right: 2%;
            }
            .logo {
                flex: 1 0 8.3%;
                max-width: 8.3%;
                padding-right: 1.5%;
                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }
            .qr {
                flex: 1 0 8.3%;
                max-width: 8.3%;
                padding-right: 1%;
            }
            .name {
                flex: 1 0 19.2%;
                max-width: 19.2%;
            }
            & > div:last-of-type {
                flex: 1 0 18.5%;
                max-width: 18.5%;
            }
        }
        .wristband,
        .wristband p {
            font-family: 'Source Sans Pro', sans-serif;
            color: #000 !important;

            &.hidden {
                display: none;
            }
        }

        .loadMore {
            display: none;
        }
    }
    .checkbox {
        display: none;
    }
}

.modal {
    width: 478px;
    height: 600px;
}

.eventStatus {
    font-size: 1rem;
    letter-spacing:1px;
    text-transform:uppercase;
    font-weight:300;
}
