.header {
    .buttons {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 -1rem 0;
        .avatar {
            flex: 1;
            position: relative;
            font-size: 2.7rem;
            display: flex;
            align-items: center;
            .image {
                width: 5.6rem;
                height: 5.6rem;
                flex: 1 0 5.6rem;
                max-width: 5.6rem;
                margin-right: 1.5rem;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
            .parentName {
                line-height: 3rem;
            }
        }
        button {
            height: 4.4rem;
            border-radius: 1.2rem;
            border: 1px solid rgba(228, 228, 228, 0.1);
            font-size: 1.4rem;
            font-weight: 600;
            margin: 0 0 0 -1px;
            &:hover,
            &[ant-click-animating-without-extra-node='true'] {
                background: #3f8cff;
                border: 1px solid #3f8cff !important;
                color: #fff;
            }
            &.share,
            &.upload {
                margin: 0 0 0 0.8rem;
                display: flex;
                align-items: center;
                span {
                    width: 1.7rem;
                    height: 1.7rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0.7rem 0 0;
                    svg {
                        display: block !important;
                        width: 1.7rem;
                        height: 1.7rem;
                        z-index: 3;
                        color: transparent;
                    }
                }
            }
        }
    }
}

.parents {
    margin: 3rem 0 0 0;

    h3 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .table {
        .actions {
            span {
                font-size: 3rem;
                color: white;
            }
        }

        .name {
            color: #3f8cff;
        }
    }
}

.addUser {
    height: 4.4rem;
    margin-left: 1rem;
    & > div {
        height: 4.4rem !important;
        background: transparent !important;
        border: 1px solid rgba(228, 228, 228, 0.1) !important;
        line-height: 2rem;
        padding: 1.1rem 2rem 1.1rem 1.6rem !important;
        transition: all 0.3s;
        & > span {
            color: #fff !important;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.05) !important;
        }
    }
    & > span {
        background: transparent !important;
        right: 1.6rem !important;
    }
}

/* rwd */
@media only screen and (max-width: 1050px) {
    .header .buttons {
        display: block;
        .avatar {
            margin: 0 0 2rem 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .header {
        button {
            background-image: none !important;
        }
    }
}
