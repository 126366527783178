.header {
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;
        .headerBackgroundImage {
            // background-image: url('../../resources/images/headerEvents.jpg'); // loaded dynamically
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    nav {
        display: flex;
        justify-content: flex-end;
        button {
            opacity: 0;
            height: 5.6rem;
            border-radius: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.4rem;
            line-height: 2.4rem;
            font-weight: 500;
            padding: 0 3rem;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
                border: 1px solid #3f8cff;
            }
            span {
                margin: 0 1rem 0 0;
                svg {
                    width: 2.1rem;
                    height: 2.1rem;
                }
            }
        }
    }
    h1 {
        font-size: 3.6rem;
        line-height: 4rem;
        color: #fff;
        font-weight: 500;
        margin: 6rem 0 0 0;
        position: relative;
        z-index: 2;
    }
    p {
        font-size: 1.6rem;
        line-height: 2.6rem;
        color: #fff;
        font-weight: 300;
        margin: 1rem 0 0 0;
        position: relative;
        z-index: 2;
    }
}

.tabs {
    & > div:before {
        display: none;
    }
    & > div:nth-of-type(1) > div > div > div {
        width: 15.6rem;
        margin-left: 0 !important;
        margin-right: 0.8rem !important;
        height: 5.6rem;
        border-radius: 1.2rem !important;
        background: #242731 !important;
        border: none !important;
        & > div {
            font-size: 1.4rem;
            font-weight: 500;
            text-align: center;
            width: 100%;
            color: #808191;
        }
        &:hover > div {
            color: #fff;
        }
        &:nth-of-type(1){ padding:0; width:auto;
            &>div{ background:#242731; border-radius:1.2rem; }
        }
    }
    .tab {
        header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 4.5rem 0 2rem 0;
            h2 {
                margin: 0;
                font-size: 2.4rem;
                line-height: 3.2rem;
                color: #fff;
                font-weight: 500;
            }
            button {
                height: 4.4rem;
                border-radius: 1.2rem;
                border: 1px solid rgba(228, 228, 228, 0.1);
                display: flex;
                align-items: center;
                font-weight: 700;
                span {
                    width: 1.8rem;
                    height: 1.8rem;
                    margin-right: 1rem;
                    svg {
                        width: 1.8rem;
                        height: 1.8rem;
                    }
                }
                &:hover {
                    background: #3f8cff;
                    color: #fff;
                    border: 1px solid #3f8cff;
                }
            }
            .nav {
                display: flex;
                button {
                    background: #242731;
                    color: #808191;
                    border: none;
                    font-weight: 500;
                    transition: all 0.3s;
                    width: 12rem;
                    justify-content: center;
                    border-radius: 1.2rem;
                    &:hover,
                    &[ant-click-animating-without-extra-node='true'] {
                        color: #fff;
                        background: #3f8cff;
                    }
                }
                button.switch {
                    height: 2.2rem;
                    border-radius: 1.1rem;
                    width: 4.4rem;
                    background: #34353b;
                    transform: scale(1.4);
                    transform-origin: right;
                    span {
                        margin-left: -8rem;
                        width: 3rem;
                        font-size: 0.8rem;
                        text-align: right;
                    }
                }
            }
        }
        .positionsCol {
            margin-top: 10rem;
        }
        .positions {
            & > div:nth-of-type(1) {
                label {
                    margin-bottom: 1rem !important;
                    font-size: 1.4rem;
                    font-weight: 500;
                }
            }
            & > div:nth-of-type(2) {
                label {
                    margin: 0 1rem 1rem 0;
                    padding: 0 1rem;
                    height: 4.4rem;
                    border-radius: 2.2rem;
                    font-size: 1.4rem;
                    color: #fff;
                    font-weight: 500;
                    display: inline-flex;
                    align-items: center;
                    border: 1px solid #45474d;
                    transition: all 0.3s;
                    & > span:nth-of-type(1) {
                        position: absolute;
                        left: -999rem;
                    }
                    span {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 2.4rem;
                            margin-right: 0.7rem;
                            font-weight: 600;
                        }
                    }
                    &:hover {
                        background: #3f8cff;
                    }
                }
            }
        }
        .formRow {
            display: block;
            label:after {
                display: none;
            }
            .jersey input {
                max-width: 10rem;
            }
            .jersey > div {
                padding-top: 1.2rem !important;
            }
            textarea {
                width: 100%;
            }
            textarea,
            input {
                font-weight: 500 !important;
            }
        }
        .form {
            max-width: 106rem;
            .check {
                background: transparent !important;
                margin-top: 2.2rem;
            }
            .save{ margin-top:4rem; }
        }

        .avatar {
            display: flex;
            justify-content: flex-start;
            margin: 3.5rem 0 2rem 0;
            .picture {
                flex: 1 0 21rem;
                max-width: 21rem;
                & > div {
                    width: 100%;
                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 50%;
                    }
                }
                .upload {
                    height: 4rem;
                    font-size: 1.4rem;
                    font-weight: 600;
                    border-radius: 1.2rem;
                    background: #32343a;
                    margin-bottom: 24px;
                    & > span {
                        padding: 0;
                    }
                    &:hover {
                        border-color: #777;
                    }
                }
            }
            .misc {
                max-width: 80rem;
                flex: 1;
            }
        }
        h3 {
            margin: 5rem 0 3rem 0;
            font-size: 2.4rem;
            line-height: 3.2rem;
            color: #fff;
            font-weight: 500;
        }
    }
    .sportSelect{
        &> div{ background:#242731 !important;
            &>span:nth-of-type(2){ padding-right:2.5rem;
                &:after{ background-color:#242731; }
            }
        }
        &>span{ background-color:#242731; }
    }
}

.sportTabs {
    margin: 4rem 0 0 0;
    & > div:before {
        display: none;
    }
    & > div:nth-of-type(1) {
        max-width: 58.3333%;
        padding-right: 2rem;
    }
    & > div > div > div > div {
        background-color: transparent !important;
        padding: 0 !important;
        font-size: 2.2rem;
        line-height: 3.2rem;
        color: #808191;
        font-weight: 500;
        border: none !important;
        margin: 0 2rem 0 0;
        transition: all 0.3s;
        &:hover {
            color: #fff;
        }
    }
    .tab {
        margin-right: 4rem;
        display: flex;
        align-items: center;
    }
    button.remove {
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        right: 1rem;
        background: url('../../resources/images/ico-remove.svg') no-repeat center;
        background-size: 0.8rem auto;
        opacity: 0.5;
        transition: all 0.3s;
        &:hover {
            opacity: 1;
        }
        &:focus {
            margin-left: -1px;
        }
        span {
            text-indent: -999rem;
        }
    }
    button.addSport {
        width: 4.5rem;
        height: 4.4rem;
        border-radius: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        &:hover {
            background: #177ddc;
            border-color: #177ddc;
        }
        span {
            width: 1.8rem;
            height: 1.8rem;
            display: block;
            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

.buttons {
    margin: 1rem 0 10rem 0;
    & > div > div > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button.save {
            height: 5.6rem;
            border-radius: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.4rem;
            line-height: 2.4rem;
            font-weight: 500;
            background: transparent;
            border: 1px solid rgba(228, 228, 228, 0.1);
            padding: 0 3rem;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
                border: 1px solid #3f8cff;
            }
            span {
                margin: 0 1rem 0 0;
                svg {
                    width: 2.1rem;
                    height: 2.1rem;
                }
            }
        }
        a {
            button {
                font-size: 1.4rem;
                &:hover {
                    background: transparent;
                }
            }
        }
    }
}

/* mobile */
.mobileHeaderBackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 33rem;
    .headerBackgroundImage {
        background-image: url('../../resources/images/headerEvents-mob.jpg');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
    .headerBackgroundGradient {
        background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}
.topNavi {
    display: flex;
    justify-content: space-between;
    margin: 0 -0.5rem 2.5rem -0.5rem;
    .buttonDiv {
        flex: 1 0 50%;
        max-width: 50%;
        padding: 0 0.5rem;
        button {
            border: none;
            width: 100%;
            height: 5.6rem;
            background: #242731;
            color: #a0a1ab;
            border-radius: 1.2rem;
            font-size: 1.6rem;
            line-height: 2rem;
            padding: 1.8rem 2rem 1.8rem 5.6rem;
            position: relative;
            text-align: left;
            font-weight: 500;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
                &:before {
                    opacity: 1;
                }
            }
            &:before {
                content: '';
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                left: 1.7rem;
                top: 0;
                width: 3rem;
                height: 100%;
                opacity: 0.4;
            }
        }
        .events:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-document.svg');
            background-size: 1.8rem auto;
            background-position: 0.6rem center;
        }
        .tasks:before {
            background-color: transparent;
            background-image: url('../../resources/images/ico-tasks.svg');
            background-size: 3rem auto;
            background-position: center;
        }
    }
}

/* rwd */
@media only screen and (max-width: 992px) {
    .sportTabs > div:nth-of-type(1) {
        max-width: 100%;
        padding-right: 0;
    }
    .tabs .tab .positionsCol {
        margin-top: 0;
    }
}

@media only screen and (max-width: 850px) {
    .tabs > div:nth-of-type(1) > div > div > div {
        width: 13rem;
    }
}

@media only screen and (max-width: 767px) {
    .header {
        margin: 0 0 4rem 0;
        h1 {
            text-align: center;
            font-size: 2.4rem;
            line-height: 3rem;
            margin-top: 0;
        }
        p {
            text-align: center;
            font-size: 1.5rem;
            line-height: 2.1rem;
        }
    }
    .tabs {
        padding-bottom: 10rem;
        & > div:nth-of-type(1) > div > div > div {
            margin-right: 0.4rem !important;
            margin-left: 0.4rem !important;
        }
        & > div > div {
            justify-content: center;
        }
        .tab .form{
            .check {
                margin-top: 0;
            }
            .save{ margin-top:0; }
        }
    }
    .buttons {
        position: fixed;
        margin: 0;
        padding: 2rem 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #1d1e23;
        a {
            display: none;
        }
        & > div > div > div {
            justify-content: center;
            padding: 0 3rem;
            button.save {
                background: #3f8cff;
                width: 100%;
                &:hover {
                    background: #095cb5;
                    border-color: #095cb5;
                }
                span {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .sportTabs {
        & > div > div > div > div {
            font-size: 1.8rem;
            line-height: 2rem;
        }
    }
    .tabs .tab {
        header h2 {
            font-size: 2.1rem;
            line-height: 2.8rem;
        }
        h3 {
            font-size: 2.1rem;
            line-height: 2.8rem;
        }
        .avatar {
            flex-direction: column;
            align-items: center;
            .picture {
                margin: 0 0 2rem 0;
            }
            .misc {
                max-width: 100%;
                padding-left: 0;
                flex: auto;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .buttons {
        & > div > div > div {
            padding: 0 1.5rem;
        }
    }
}

@media only screen and (max-width: 450px) {
    .tabs {
        & > div:nth-of-type(1) > div > div > div {
            width: 10rem;
        }
    }
}

@media only screen and (max-width: 350px) {
    .tabs {
        & > div:nth-of-type(1) > div > div > div {
            width: 9rem;
            padding: 0.8rem 1rem !important;
        }
    }
}

.tabError {
    color: #ff3f5f !important;
}

.avatarTop {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 9;
    .picture {
        flex: 1 0 7.2rem;
        max-width: 7.2rem;
        width: 7.2rem;
        height: 7.2rem;
        span {
            display: block;
            width: inherit;
            height: inherit;
        }
        svg,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    h1 {
        margin: 0 0 0 1.8rem;
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 600;
    }
}
