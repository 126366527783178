.content{ position:relative; z-index:5; padding:10rem 0;
  div{ font-size:1.8rem; color:#fff;
    button{ height:5.6rem; border-radius:1.2rem; background:#3F8CFF; border:none; padding:1rem 3.5rem; font-weight:600; color:#fff; font-size:1.4rem; text-shadow:none; margin:1rem 0 0 0; transition:all .3s;
      &:hover{ color:#3F8CFF; background:#fff; }
    }
  }
  button[role="switch"]{ height:3.2rem; width:5.7rem; border-radius:1.6rem; margin:1rem 0 0 0; padding:0;
    &>div:nth-of-type(1){ width:2.6rem; height:2.6rem; margin:0.1rem;
      &:before{ border-radius:50%; }
    }
    &[aria-checked="false"]{ background:#141414;
      &:hover{ background:#141414; }
      &>div:nth-of-type(1){ width:2.6rem; height:2.6rem; margin:0.1rem;
        &:before{ background:#666666; }
      }
    }
    &[aria-checked="true"]{ 
      &:hover{ background:#3F8CFF; }
      &>div:nth-of-type(1){ left:2.7rem; }
    }
  }
}


/* buttons */
.buttons{ margin:4rem 0 0 0; display:flex; align-items:center; flex-wrap:wrap;
  button:last-child{ margin-right:0; }
  .btnMain{ height:4.6rem; border-radius:0.4rem; border:none; background:#3f8cff; font-size: 1.4rem; line-height: 1.6rem; padding: 1.5rem 2.4rem; color: #fff; font-weight: 500; margin:0.5rem 1.2rem 0.5rem 0; transition: all 0.3s;
    &:hover {
      color: #3f8cff;
      background: #fff;
    }
  }
  .btnSecondary{ height:4.6rem; border-radius:0.4rem; border:none; background:#153456; font-size: 1.4rem; line-height: 1.6rem; padding: 1.5rem 2.4rem; color: #fff; font-weight: 500; margin:0.5rem 1.2rem 0.5rem 0; transition: all 0.3s;
    &:hover {
      color: #153456;
      background: #fff;
    }
  }
}