.header {
    .headerBackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 42rem;
        .headerBackgroundImage {
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .headerBackgroundGradient {
            background: linear-gradient(180deg, rgba(31, 33, 40, 0.656315) 0%, #1d1e23 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
    .buttons {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 -1rem 0;
        .avatar {
            flex: 1;
            position: relative;
            font-size: 2.7rem;
            display: flex;
            align-items: flex-start;
            .image {
                width: 5.6rem;
                height: 5.6rem;
                flex: 1 0 5.6rem;
                max-width: 5.6rem;
                margin-right: 1.5rem;

                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
            .parentName {
                line-height: 3rem;
                margin: 0 0 1rem 0;
            }
            .email {
                font-size: 1.6rem;
                margin: 0;
            }
            .sport {
                font-size: 1.6rem;
                margin: 0;
            }
        }
        button {
            height: 4.4rem;
            border-radius: 1.2rem;
            border: 1px solid rgba(228, 228, 228, 0.1);
            font-size: 1.4rem;
            font-weight: 600;
            margin: 0 0 0 -1px;
            &:hover,
            &[ant-click-animating-without-extra-node='true'] {
                background: #3f8cff;
                border: 1px solid #3f8cff !important;
                color: #fff;
            }
            &.share,
            &.upload {
                margin: 0 0 0 0.8rem;
                display: flex;
                align-items: center;
                span {
                    width: 1.7rem;
                    height: 1.7rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0.7rem 0 0;
                    svg {
                        display: block !important;
                        width: 1.7rem;
                        height: 1.7rem;
                        z-index: 3;
                        color: transparent;
                    }
                }
            }
        }
    }

    & > button {
        height: 3.2rem;
        width: 3.2rem;
        padding: 0;
        background-image: url('../../resources/images/ico-edit.svg') !important;
        background-size: 1.6rem auto !important;
        background-position: 0.9rem center !important;
    }
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0 2rem 0;

    h2 {
        font-size: 2.1rem;
        line-height: 3.2rem;
        font-weight: 600;
        color: #fff;
        margin: 0 0 0 0;
    }

    button {
        height: 5.5rem;
        border-radius: 1.2rem;
        border: 1px solid rgba(228, 228, 228, 0.1);
        display: flex;
        align-items: center;
        span {
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
            svg {
                width: 2rem;
                height: 2rem;
            }
        }
        &:hover {
            background: #3f8cff;
            color: #fff;
            border: 1px solid #3f8cff;
        }
    }
}

.table {
    padding: 0 0 4rem 0;
    overflow-x: auto;
    table {
        background: #1f2128;
        margin: 0 0 0 0;
        min-width: 80rem;
        thead {
            tr {
                th {
                    font-size: 1.1rem;
                    line-height: 1.6rem;
                    color: #fff;
                    text-transform: uppercase;
                    border-bottom: none;
                    text-align: left;
                    padding: 2rem 1.5rem;
                }
            }
        }
        tbody {
            & > tr {
                & > td {
                    padding: 1.5rem 1.5rem;
                    border-bottom: none;
                    color: #fff;
                    text-align: left;
                    a {
                        color: inherit;
                        &:hover {
                            color: inherit;
                            text-decoration: underline;
                        }
                    }
                }
                &:nth-of-type(odd) td {
                    background: #1f2128;
                }
                &:nth-of-type(even) td {
                    background: #242731;
                }
                &:last-of-type {
                    td:first-of-type {
                        border-bottom-left-radius: 1.2rem;
                    }
                    td:last-of-type {
                        border-bottom-right-radius: 1.2rem;
                    }
                }
            }
        }
    }

    ul {
        li {
            font-weight: 600;
            a {
                transition: all 0.3s;
            }
        }
    }
}

.collegeName {
    font-size: 2.5rem;
}

.name {
    color: #3f8cff;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.1rem;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        color: #3f8cff;
    }
}

/* rwd */
@media only screen and (max-width: 400px) {
    .flex {
        flex-direction: column;
        align-items: flex-start;
        button {
            margin-top: 1.5rem;
        }
    }
}
