.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
        font-size: 3.6rem;
        line-height: 4rem;
        color: #fff;
        font-weight: 500;
        margin: 0 0 0 0;
        position: relative;
        z-index: 2;
    }
}
.subheader {
    width: 100%;
    h2{
        font-size:1.8rem;
        margin:0;
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4rem 0 6rem 0;
    flex:1 0 100% !important;
    max-width:100% !important;
    flex-wrap:wrap;
    &>div{ margin-bottom:0; }
    .save {
        height: 5.6rem;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1.2rem;
        border: none;
        font-weight: 600;
        color: #fff;
        padding: 1rem 2.5rem;
        & > span {
            width: 2.2rem;
            height: 2.2rem;
            margin: 0 1.2rem 0 0;
            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .back {
        height: 5.6rem;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1.2rem;
        border: none;
        font-weight: 600;
        color: #bbb;
        padding: 1rem 2.5rem;
        margin-left: -2.5rem;
        &:hover {
            color: #fff;
        }
    }
}

.form{ max-width:76rem;
    &>div{ width:auto; margin:0;
        &>div{ flex:1 0 50%; max-width:50%; }
    }
}


@media only screen and (max-width: 900px) {
    .header h1 {
        font-size: 3rem;
        line-height: 4rem;
    }
    .subheader  h2{
        font-size:1.4rem;
    }
}


@media only screen and (max-width: 767px) {
    .header{ display:none; }
    .form{ 
        &>div{ width:100%; margin-left:0 !important; margin-right:0 !important;
            &>div{ flex:1 0 100%; max-width:100%; padding:0 !important; }
        }
    }
}


@media only screen and (max-width: 450px) {
    .buttons{ justify-content:center;
        button{ margin:1rem; }    
    }
}