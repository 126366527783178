.registrationForm {
    padding: 6rem 0 20rem 0;
    .row {
        max-width: 130rem;
        margin: 0 auto;
        padding: 0 3rem 0 6rem;
        .flex {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            aside {
                flex: 1 0 38rem;
                max-width: 38rem;
                position: relative;
                z-index: 2;
            }
            .content {
                flex: 1;
                max-width: 100%;
                padding-left: 6rem;
                position: relative;
                z-index: 2;
            }
        }
    }

    aside {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 1.2rem;
        overflow: hidden;
    }

    .content {
        .header {
            padding: 3rem 0 0 0;
            h1 {
                font-family: 'agency-fb', sans-serif;
                font-size: 5.8rem;
                line-height: 6.4rem;
                color: #fff;
                font-weight: 700;
                text-transform: uppercase;
                margin: 0 0 2rem 0;
            }
            h2 {
                font-size: 1.2rem;
                line-height: 2rem;
                color: #808191;
                font-weight: 500;
                margin: 0 0 1rem 0;
            }
            p {
                font-family: Akrobat, sans-serif;
                font-size: 2rem;
                line-height: 3rem;
                color: #fff;
                max-width: 52rem;
                margin: 2rem 0;
            }
            button {
                height: 4.6rem;
                border-radius: 0.4rem;
                border: none;
                background: #3f8cff;
                font-size: 1.4rem;
                line-height: 1.6rem;
                padding: 1.5rem 4.4rem;
                color: #fff;
                font-weight: 500;
                margin: 0 0 0 0;
                transition: all 0.3s;
                &:hover {
                    color: #3f8cff;
                    background: #fff;
                }
            }
        }
    }

    .form {
        h2 {
            font-family: Akrobat, sans-serif;
            font-size: 3.2rem;
            line-height: 4.2rem;
            color: #fff;
            margin: 5rem 0 2rem 0;
            font-weight: 700;
        }
        h3 {
            font-family: Akrobat, sans-serif;
            font-size: 2.4rem;
            line-height: 3.4rem;
            color: #fff;
            margin: 4rem 0 2rem 0;
            font-weight: 700;
        }
        p {
            font-size: 1.2rem;
            line-height: 2rem;
            color: #808191;
            font-weight: 600;
        }

        .pass{
            &>div:nth-of-type(2) > div > div span{ border:none; padding:0; }
            &>div:nth-of-type(2) > div > span{ display:none !important; }
            input{ padding:1.8rem 2.3rem; }
            input + span{ margin-top:-0.5rem; }
        }

        .formRow24 {
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0 0 4rem 0;
        }
        .formRow12 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .formRow8 {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
        .formRow4 {
            flex: 0 0 16.66%;
            max-width: 16.66%;
        }

        label {
            font-family: Poppins, sans-serif;
            font-size: 1.2rem;
            line-height: 1.6rem;
            font-weight: 500;
            color: #b2b3bd;
            margin: 1rem 0 0.6rem 0 !important;
        }

        .input > div:nth-of-type(2) > div > div > div,
        .select > div:nth-of-type(2) > div > div > div > div,
        input[type='text'],
        input[type='password'],
        input[type='mail'],
        input[type='number'] {
            background-color: #fff !important;
            color: #1f2128;
            margin: 0 0 0.5rem 0;
        }

        .input > div:nth-of-type(2) > div > div > div > div input {
            color: #1f2128;
            font-weight: 600;
        }

        .select > div:nth-of-type(2) > div > div > div > span {
            background-color: transparent;
            & > span {
                filter: invert(1);
            }
        }

        .checkbox {
            color: #fff;
            font-weight: 600;
        }

        ::-webkit-input-placeholder {
            color: #1f2128;
        }
        :-ms-input-placeholder {
            color: #1f2128;
        }
        ::placeholder {
            color: #1f2128;
        }
    }
}

/* buttons */
.buttons {
    margin: 4rem 0 0 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .btnMain {
        height: 4.6rem;
        border-radius: 0.4rem;
        border: none;
        background: #3f8cff;
        font-size: 1.4rem;
        line-height: 1.6rem;
        padding: 1.5rem 2.4rem;
        color: #fff;
        font-weight: 500;
        margin: 0 1.2rem 0 0;
        transition: all 0.3s;
        &:hover {
            color: #3f8cff;
            background: #fff;
        }
    }
    .btnSecondary {
        height: 4.6rem;
        border-radius: 0.4rem;
        border: none;
        background: #153456;
        font-size: 1.4rem;
        line-height: 1.6rem;
        padding: 1.5rem 2.4rem;
        color: #fff;
        font-weight: 500;
        margin: 0 1.2rem 0 0;
        transition: all 0.3s;
        &:hover {
            color: #153456;
            background: #fff;
        }
    }
}

/* positions */
.registrationForm .form .positions {
    margin: 0 -0.5rem;
    max-width: 46rem;
    label {
        margin: 0.5rem !important;
        height: 4.4rem;
        border-radius: 2.2rem;
        border: 1px solid #45474d;
        display: inline-flex;
        align-items: center;
        padding: 0 1.5rem;
        transition: all 0.3s;
        & > span:nth-of-type(1) {
            position: absolute;
            left: -999rem;
        }
        & > span:nth-of-type(2) {
            color: #fff;
            font-size: 1.4rem;
            font-weight: 500;
            padding: 0;
            display: inline-flex;
            align-items: center;
            span {
                display: inline-block;
                margin: 0 1rem 0 0;
                font-size: 2.4rem;
                font-weight: 600;
            }
        }

        &:hover {
            background: #3f8cff;
            border-color: #3f8cff;
        }
    }
}

/* rwd */
@media only screen and (max-width: 1050px) {
    .registrationForm .row .flex aside {
        flex: 1 0 30rem;
        max-width: 30rem;
    }
}

@media only screen and (max-width: 950px) {
    .registrationForm .row .flex {
        display: block;
        aside {
            max-width: 100%;
            margin: 0 0 4rem 0;
            display: flex;
            align-items: stretch;
        }
        .content {
            max-width: 100%;
            padding: 0;
        }
    }
}

@media only screen and (max-width: 900px) {
    .registrationForm .row {
        padding: 0 3rem;
    }
}

@media only screen and (max-width: 750px) {
    .registrationForm .row {
        padding: 0 2rem;
    }
}

@media only screen and (max-width: 600px) {
    .registrationForm {
        .content .header {
            h1 {
                font-size: 4.8rem;
                line-height: 5.4rem;
            }
            p {
                font-size: 1.8rem;
                line-height: 2.8rem;
            }
        }
        .form {
            h2 {
                font-size: 2.6rem;
                line-height: 3.6rem;
            }
            h3 {
                font-size: 2rem;
                line-height: 3rem;
            }

            .formRow8 {
                flex: 1 0 50%;
                max-width: 50%;
            }
            .formRow4 {
                flex: 1 0 33.33%;
                max-width: 33.33%;
            }
        }
        .row .flex {
            display: block;
            aside {
                display: block;
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .registrationForm .form {
        .formRow12 {
            flex: 1 0 100%;
            max-width: 100%;
        }
        .formRow8 {
            flex: 1 0 100%;
            max-width: 100%;
        }
        .formRow4 {
            flex: 1 0 100%;
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 400px) {
    .registrationForm {
        .content .header {
            h1 {
                font-size: 4rem;
                line-height: 4.8rem;
            }
            p {
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
        }
        .form {
            h2 {
                font-size: 2.2rem;
                line-height: 3rem;
            }
            h3 {
                font-size: 1.8rem;
                line-height: 2.6rem;
            }
        }
    }
}

.googleTerms {
    margin-top: 9rem;
    margin-bottom: -9rem;
}
