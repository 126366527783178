.search {
  & > span > span {
      border: none;
      background:rgba(255,255,255,0.02);
      border-radius:0.8rem !important;
      width:100%;
      display:flex;
      &::before{ content:unset; }
  }
  span{ border-color:transparent !important; border:none !important; }
  input {
      width: 100% !important;
      font-size: 1.4rem;
      line-height: 2rem;
      color: #fff;
      font-weight: 600;
      padding: 0.8rem 2rem 0.8rem 4rem !important;
      height: 3.6rem;
      background-color: transparent !important;
      background-image: url('../../../../../resources/images/ico-search.svg');
      background-repeat: no-repeat;
      background-position: left center;
  }
  & > span > span:nth-of-type(2) {
      left: 0;
      background-color: transparent;
      color: #fff;
      button {
          visibility: visible;
          border: none !important;
          width: auto !important;
          & > span {
              display: none !important;
          }
      }
  }
}


.userList {
  display:flex;
  justify-content:space-between;
  .user{ display:flex; align-items:center; margin:0 2rem 0 0;
      p{ margin:0; 
          span{ display:block; margin-top:0.2rem; }
      }
      .avatar { flex:1 0 6.4rem; max-width:6.4rem; min-width:6.4rem;
          margin-right: 1rem;
      }
      a{
        &:hover{ color:inherit; text-decoration:underline; }
      }
      .email {
        display:block;
          color: #808191;
      }
  }
  .addUserButton{ background:rgba(255, 255, 255, 0.04);
    &:hover{ background:rgba(255, 255, 255, 0.06); }
  }
}



/* rwd */
@media only screen and (max-width: 550px) {
  .userList{ flex-direction:column;
      .user{ margin:0; }
  }
  .addUserButton{ max-width:15rem; margin:1rem 0 0 7.5rem; }
}


@media only screen and (max-width: 400px) {
  .btn{ padding:1rem 3rem !important; }
}