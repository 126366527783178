@import '~antd/dist/antd.dark.less';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

/* app */
html,
body {
    margin: 0;
    background: #000;
    font-size: 62.5% !important;
    color: #fff;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

#root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    position: relative;
}

body p {
    color: #fff;
}

.ant-layout {
    background: transparent;
}

a {
    outline: none;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.divider {
    margin: 2rem 4rem;
    background: #e4e4e4;
    opacity: 0.1;
    min-width: auto;
    width: auto;
}

/* main menu */
.ant-menu.ant-menu-dark {
    background: transparent;

    li {
        padding: 0 2rem !important;
        font-weight: 500;

        .ant-menu-item-group-title {
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: #b2b3bd;
            font-weight: 500;
            padding: 0;
            margin: 1rem 2rem 1.6rem 2rem;
        }

        ul {
            li.ant-menu-item,
            li.ant-menu-submenu {
                color: transparent;
                min-height: 5.2rem;
                font-size: 1.4rem;
                line-height: 2rem;
                border-radius: 1.2rem;
                transition: all 0.3s;
                display: flex;
                align-items: center;

                .anticon {
                    opacity: 0.4;
                    width: 2rem;
                    height: 2rem;
                    color: transparent;
                    transition: all 0.3s;

                    svg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .ant-menu-title-content {
                    color: #808191;
                    margin-left: 1.5rem;
                }

                .ant-menu-submenu-title {
                    padding: 0 !important;
                    .ant-menu-submenu-arrow {
                        right: 0;
                    }
                }

                &:hover {
                    color: transparent;

                    & > .ant-menu-title-content {
                        color: #fff;
                    }

                    & > .anticon {
                        opacity: 1;
                        color: transparent;
                    }
                }

                &.ant-menu-item-selected {
                    background: #3f8cff;
                    .anticon {
                        opacity: 1;
                    }
                    .ant-menu-title-content {
                        color: #fff;
                    }
                }

                ul li span:hover {
                    color: #fff !important;
                }
            }
            li.ant-menu-submenu {
                flex-direction: column;
                & > div {
                    position: relative;
                    overflow: visible;
                    &:before {
                        content: '';
                        width: 0.4rem;
                        background: #6c5dd3;
                        position: absolute;
                        left: -4.5rem;
                        top: 0;
                        bottom: 0;
                        transition: all 0.3s;
                    }
                }
                &:hover,
                &.ant-menu-submenu-open {
                    color: transparent;

                    & > div > .ant-menu-title-content {
                        color: #fff;
                    }

                    & > div > .anticon {
                        opacity: 1;
                        color: transparent;
                    }
                }
                &.ant-menu-submenu-open {
                    & > div {
                        &:before {
                            left: -4rem;
                        }
                    }
                }
                .ant-menu-sub {
                    background: transparent;
                    border-top: 1px solid rgba(255, 255, 255, 0.05);
                    margin: 0 0 2rem 0;
                    li {
                        min-height: 4rem;
                        &.ant-menu-item-selected {
                            background: transparent;
                            span {
                                color: #5f75ee;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* drawer menu */
.ant-drawer {
    .ant-drawer-content-wrapper {
        .ant-drawer-content {
            background: #262830;
        }
        .ant-drawer-wrapper-body {
            .ant-drawer-header {
                border-bottom: none;
                padding: 0 2.6rem;
                background: #262830;
            }
            .ant-drawer-body {
                padding: 0;
                background: #262830;
            }
        }
    }
}

/* and message */
.ant-message {
    .ant-message-notice {
        .ant-message-notice-content {
            font-size: 2rem;
        }
        .anticon {
            font-size: 2rem;
            margin-right: 1.5rem;
        }
    }
}

/* ant select */
.ant-select {
    font-weight: 600;
    .ant-select-selector {
        .ant-select-selection-search {
            input {
                color: #808191;
                font-size: 1.4rem;
                line-height: 2rem;
                font-weight: 600;
                height: 2rem;
            }
        }
        .ant-select-selection-placeholder {
            color: #808191;
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 600;
        }
    }
    .ant-select-arrow {
        background-color: #32343a;
        right: 2.4rem;
        span {
            width: 100%;
            height: 100%;
            background-color: transparent;
            background-image: url('resources/images/antSelect-arrow.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            svg {
                display: none;
            }
        }
    }
    .ant-select-clear {
        background-color: #242731;
        span {
            width: 100%;
            height: 100%;
            background-color: transparent;
            background-image: url('resources/images/antSelect-clear.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            svg {
                display: none;
            }
        }
    }

    &.placeholder-select {
        .ant-select-selection-placeholder {
            color: #fff;
        }
    }
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 1.2rem;
    background: #32343a;
    height: 5.6rem;
    padding: 1.8rem 2.2rem;
    .ant-select-selection-item {
        line-height: 2rem;
    }
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: #32343a;
}

/* ant multi select */
.ant-select-multiple {
    .ant-select-selector {
        padding: 2.8rem 4.2rem 1.2rem 2.2rem !important;
        min-height: 5.6rem;
        height: auto !important;
        background-color: #32343a;
        background-image: url('resources/images/antSelect-arrow.svg') !important;
        background-repeat: no-repeat !important;
        background-size: 1.2rem auto !important;
        background-position: right 2.4rem top 2.4rem !important;
        line-height: 2.8rem;
    }
}

.ant-picker {
    border: none;
    border-radius: 1.2rem;
    background: #32343a;
    height: 5.6rem;
    padding: 1.8rem 2.2rem;
    width: 100%;
    .ant-picker-clear {
        width: 2.4rem;
        height: 2.4rem;
        background: #32343a;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: -2px;
    }
    .ant-select-selection-item {
        line-height: 2rem;
    }
    .ant-picker-suffix span {
        width: 2rem;
        height: 2rem;
        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
    .ant-select-selection-search-input {
    height: 2rem;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 2rem;
}

/* forms */
.ant-form-item-label > label {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #b2b3bd;
    margin: 0 0 0.6rem 0 !important;
    font-weight: 500;
    &:before {
        order: 2;
        margin-left: 0.2rem;
        font-size: 2rem !important;
        line-height: 0 !important;
    }
}

.ant-input-number {
    border: none;
    width: 100%;
}

input[type='text'],
input[type='password'],
input[type='mail'],
input[type='number'] {
    height: 5.6rem;
    background-color: #32343a !important;
    border-radius: 1.2rem;
    padding: 1.8rem 2.3rem;
    border: none;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2rem;
}

textarea {
    height: 17rem !important;
    background-color: #32343a !important;
    border-radius: 1.2rem !important;
    border: none;
    padding: 1.8rem 2.3rem !important;
    border: none !important;
    font-size: 1.6rem !important;
    font-weight: 600 !important;
    line-height: 2rem !important;
}

.ant-input-number-out-of-range input {
    color: #fff;
}

button[type='submit'] {
    height: 4.4rem;
    background: #3f8cff;
    border-radius: 1.2rem;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 1rem 2.4rem;
}

input:focus,
button:focus,
textarea:focus,
select:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
    color: #c33;
}

.ant-radio-group {
    display: flex;
    .ant-radio-button-wrapper {
        height: 4.4rem;
        background: #242731;
        color: #808191;
        border: none;
        font-weight: 500;
        transition: all 0.3s;
        width: 12rem;
        border-radius: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before {
            display: none !important;
        }
        &.ant-radio-button-wrapper-checked {
            color: #fff;
            background: #3f8cff;
        }
        &:hover {
            color: #fff;
        }
    }
}

/* checkbox */
input[type='checkbox'] + .ant-checkbox-inner {
    border: 2px solid #fff;
    &:after {
        margin: -1px 0 0 -1px;
    }
}
.ant-checkbox-checked {
    &:after {
        transform: scale(0.8);
    }
    input[type='checkbox'] + .ant-checkbox-inner {
        background: transparent;
    }
}
.positionsCheckbox .ant-checkbox-wrapper-checked {
    background: #3f8cff;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    margin: 0 !important;
    background: #fff;
    content: '';
}

/* tabs */
.ant-tabs {
    .ant-tabs-nav {
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                .ant-tabs-tab-active {
                    background: #3f8cff !important;
                    .ant-tabs-tab-btn {
                        color: #fff;
                    }
                }
            }
        }
    }
}
.sportsTabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
    .ant-tabs-tab {
        padding-bottom: 0.6rem !important;
        position: relative;
    }
    .ant-tabs-tab-active {
        background: transparent !important;
        &:after {
            content: '';
            height: 2px;
            background: #fff;
            position: absolute;
            left: 0;
            right: 4rem;
            bottom: 0;
        }
    }
}

/* popover */
.ant-popover-content {
    .ant-popover-inner {
        border-radius: 1rem;
        background: #32343a;
        .ant-popover-inner-content {
            padding: 1.5rem 2rem;
        }
        span {
            display: none;
        }
        .ant-popover-message-title {
            font-weight: 500;
            color: #fff;
            padding-left: 0;
            margin-bottom: 1rem;
        }
        .ant-btn {
            height: 3rem;
            padding: 0 1.5rem;
            border-radius: 0.5rem;
            border: none;
            background: #1b1d21;
            &:hover {
                background: #10101b;
                color: #fff;
            }
            &.ant-btn-primary {
                background: #3f8cff;
                &:hover {
                    background: #095cb5;
                }
            }
        }
    }
}

/* pagination */
.ant-table-pagination.ant-pagination {
    margin: 3rem 0 0 0;
    li {
        border: none;
        button {
            border: none;
        }
        a {
            font-weight: 500;
            text-decoration: none;
        }
        &.ant-pagination-item-active {
            border-color: #3f8cff;
            background: #3f8cff;
            a {
                color: #fff;
            }
            &:hover a {
                text-decoration: none;
            }
        }
        &:hover {
            button .anticon {
                color: #3f8cff;
            }
            a {
                text-decoration: underline;
                color: #fff;
            }
        }
    }
}
.ant-pagination.mini .ant-pagination-item,
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next,
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
    height: 3rem;
    min-width: 3rem;
    a {
        height: inherit;
        line-height: 3rem;
    }
}

/* collapse */
.ant-collapse-content {
    border-top: none;
    border-bottom: 1px solid rgba(228, 228, 228, 0.1);
    padding: 1.6rem;
    .ant-collapse-content-box {
        padding: 0;
        p,
        li {
            margin: 0;
            font-size: 1.4rem;
            line-height: 2.4rem;
            color: #fff;
            font-weight: 500;
        }
    }
}

/* ant image */
.ant-image {
    .ant-image-mask {
        border-radius: 50%;
        font-size: 1.6rem;
        span {
            margin-right: 1rem;
        }
    }
}

/* modal */
.ant-modal {
    &.summaryModal {
        .ant-modal-content {
            .ant-modal-confirm-body {
                .ant-modal-confirm-content {
                    text-align: left !important;
                    margin-left: 1rem;
                }
            }
        }
    }
    .ant-modal-content {
        background: #242731;
        border-radius: 2.4rem;
        overflow: hidden;
        .ant-modal-close-x {
            display: flex;
            justify-content: center;
            align-items: center;
            .anticon-close {
                display: block;
                width: 2rem;
                height: 2rem;
                background-image: url('resources/images/ico-clear.svg');
                background-size: 1.6rem auto;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 0.6;
                transition: all 0.3s;
                &:hover {
                    opacity: 1;
                }
            }
            svg {
                display: none;
            }
        }
        .ant-modal-body {
            padding: 2.5rem 2.5rem 2.5rem 2.5rem;
            .antUpload {
                margin-bottom: 0;
                .ant-upload-drag {
                    margin-bottom: -8rem;
                }
                .ant-form-item-control-input-content {
                    padding-bottom: 2rem;
                    font-size: 1.4rem;
                    line-height: 6rem;
                    font-weight: 500;
                    border: 1px dashed rgba(255, 255, 255, 0.46);
                    border-radius: 1rem;
                    text-align: center;
                    background: #282932;
                }
                .ant-upload-select {
                    width: 11rem;
                    height: 11rem;
                    margin: 0 0 1.5rem 0;
                }
                .ant-btn-circle {
                    width: 11rem;
                    height: 11rem;
                    margin-bottom: 4rem;
                    & > span > svg {
                        width: 2.6rem;
                        height: 2.6rem;
                    }
                }
                .ant-upload-btn {
                    padding: 4rem 0;
                }
            }
        }
        .ant-modal-footer {
            border-top: none;
            padding: 2.5rem 2.5rem 2.5rem 2.5rem;
            margin-top: -2.5rem;
            button {
                height: 5.6rem;
                border-radius: 1.2rem;
                border: none;
                padding: 1rem 5rem;
                font-size: 1.4rem;
                font-weight: 600;
                background: #1b1d21;
                &:hover {
                    background: #10101b;
                    color: #fff;
                }
                &.ant-btn-primary {
                    background: #3f8cff;
                    color: #fff;
                    &:hover {
                        background: #095cb5;
                    }
                }
            }
        }
        .ant-modal-confirm-body {
            text-align: center;
            margin: 2rem 0 4rem 0;
            .anticon {
                display: none;
                text-align: center;
            }
            span {
                font-size: 2rem;
            }
        }

        .ant-modal-confirm-btns {
            float: none;
            display: flex;
            justify-content: center;
            button {
                height: 5.6rem;
                border-radius: 1.2rem;
                border: none;
                padding: 1rem 5rem;
                font-size: 1.4rem;
                font-weight: 600;
                background: #1b1d21;
                &:hover {
                    background: #10101b;
                    color: #fff;
                }
                &.ant-btn-primary {
                    background: #3f8cff;
                    color: #fff;
                    &:hover {
                        background: #095cb5;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .ant-modal .ant-modal-content .ant-modal-body {
        & > .ant-row-stretch {
            display: block;
        }
        .antUpload {
            margin-bottom: 2rem;
            .ant-btn-circle {
                margin-bottom: 3rem;
            }
            .ant-upload-drag {
                padding: 2rem 0;
            }
        }
    }
}
@media only screen and (max-width: 550px) {
    .ant-modal .ant-modal-content .ant-modal-footer {
        text-align: center;
        margin: -3rem 0 0 0;
        button {
            margin: 1rem;
        }
    }
}

/* InputNumber */
.ant-input-number.stepHidden .ant-input-number-handler-wrap {
    display: none;
}

/* affiliate profile */
.affiliateProfile {
    .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-active {
        background: transparent !important;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 4px;
            background: #3f8cff;
            border-radius: 2px;
        }
    }
}

/* leaderboard */
.leaderboard.gradient {
    .bg {
        background: radial-gradient(123% 122% at 17.75% 13.33%, #2d275e 1.49%, #340534 51.56%, #ae233c 100%);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
    & > section {
        background-color: transparent;
        & > aside {
            background-color: transparent !important;
            & > div > div > button:nth-of-type(2) {
                background-color: transparent !important;
            }
        }
        & > section {
            background-color: transparent !important;
            & > div {
                border-bottom: none !important;
            }
        }
    }
}
.leaderboardTabs.ant-tabs {
    margin-top: -1rem;
    .ant-tabs-nav {
        &:before {
            display: none;
        }
        .ant-tabs-nav-wrap .ant-tabs-nav-list {
            .ant-tabs-tab {
                background: transparent !important;
                padding: 0 0 0.6rem 0 !important;
                position: relative;
                border: none;
                margin: 0 4.4rem 0 0;
                & > div {
                    font-size: 1.8rem;
                }
                &:hover > div {
                    color: #fff;
                }
            }
            .ant-tabs-tab-active {
                &:after {
                    content: '';
                    height: 4px;
                    background: #3f8cff;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 2px;
                }
            }
        }
    }
}

/* table */
.ant-table > .ant-table-container > .ant-table-content {
    overflow-x: auto;
}

/* event live leaderboard */
.eventLiveLeaderboard.gradient {
    height: 100vh;
    overflow: hidden;
    .bg {
        background: radial-gradient(123% 122% at 17.75% 13.33%, #2d275e 1.49%, #340534 51.56%, #ae233c 100%);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
    & > section {
        background-color: transparent;
        & > aside {
            background-color: transparent !important;
            & > div > div > button:nth-of-type(2) {
                background-color: transparent !important;
            }
        }
        & > section {
            background-color: transparent !important;
            & > div {
                border-bottom: none !important;
            }
        }
    }
    .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-active {
        background: transparent !important;
        .ant-tabs-tab-btn {
            color: #eb1740;
        }
    }
    .ant-table {
        background: transparent;
    }
    .ant-table-thead > tr > th {
        background: rgba(0, 0, 0, 0.25);
        color: #fff;
        text-transform: uppercase;
        font-size: 1.9rem;
        font-weight: 600;
        padding: 2.5rem 2rem;
        border: none;
        &:before {
            display: none;
        }
    }
    .ant-table-tbody > tr {
        & > td {
            padding: 0 2rem;
            border: none;
            color: #fff;
            font-size: 3rem;
            line-height: 3.6rem;
            font-weight: 600;
            &:first-of-type {
                & > a {
                    display: block;
                    height: 7.5rem;
                    width: 7.5rem;
                    & > div {
                        width: 100%;
                        height: 100%;
                        & > img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            &:nth-of-type(2) {
                & > a {
                    display: block;
                    height: 7.5rem;
                    width: 7.5rem;
                    & > div {
                        width: 100%;
                        height: 100%;
                        & > img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            a:hover {
                color: #fff;
            }
        }
        &:nth-of-type(even) > td {
            background: rgba(0, 0, 0, 0.15);
        }
        &:nth-of-type(odd):hover > td {
            background: transparent;
        }
        &:nth-of-type(even):hover > td {
            background: rgba(0, 0, 0, 0.15);
        }
    }
}

// recaptcha

.grecaptcha-badge {
    visibility: hidden;
}

// dropdown
.ant-select-item-option-content {
    overflow: visible;
    text-overflow: unset;
    white-space: unset;
}

// error notification
.ant-notification-notice-error {
    background: #440000;
    .ant-notification-notice-message {
        font-weight: 600;
        color: #fff;
    }
    .ant-notification-notice-description {
        color: #fff;
    }
    a {
        color: inherit;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

// rwd
@media only screen and (max-width: 1220px) {
    .leaderboardTabs.ant-tabs {
        .ant-tabs-nav {
            flex-direction: column;
            align-items: flex-start;
            .ant-tabs-extra-content {
                margin: 2rem 0 0 0;
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .leaderboardTabs.ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
        .ant-tabs-tab {
            margin-right: 2rem;
            & > div {
                font-size: 1.4rem;
            }
        }
        .ant-tabs-tab-active:after {
            height: 2px;
        }
    }
}

@media only screen and (min-width: 2000px) {
    .eventLiveLeaderboard.gradient {
        .ant-table-thead > tr > th {
            font-size: 2.7rem;
            padding: 3.5rem 3.5rem;
        }
        .ant-table-tbody > tr {
            & > td {
                padding: 0 3.5rem;
                font-size: 4rem;
                line-height: 4.8rem;
                &:first-of-type {
                    & > a {
                        height: 10rem;
                        width: 10rem;
                    }
                }
                &:nth-of-type(2) {
                    & > a {
                        height: 10rem;
                        width: 10rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 3000px) {
    .eventLiveLeaderboard.gradient {
        .ant-table-thead > tr > th {
            font-size: 3.8rem;
            padding: 5rem 5rem;
        }
        .ant-table-tbody > tr {
            & > td {
                padding: 0 5rem;
                font-size: 6rem;
                line-height: 7.2rem;
                &:first-of-type {
                    & > a {
                        height: 15rem;
                        width: 15rem;
                    }
                }
                &:nth-of-type(2) {
                    & > a {
                        height: 15rem;
                        width: 15rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1700px) {
    .eventLiveLeaderboard.gradient {
        .ant-table-thead > tr > th {
            font-size: 1.6rem;
            padding: 1.5rem 1.5rem;
        }
        .ant-table-tbody > tr {
            & > td {
                padding: 0 1.5rem;
                font-size: 2.6rem;
                line-height: 3.2rem;
                &:first-of-type {
                    & > a {
                        height: 6rem;
                        width: 6rem;
                    }
                }
                &:nth-of-type(2) {
                    & > a {
                        height: 6rem;
                        width: 6rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-height: 1050px) {
    .eventLiveLeaderboard.gradient .ant-table-tbody > tr {
        & > td:first-of-type > a {
            height: 5rem;
            width: 5rem;
        }
        & > td {
            font-size: 2rem;
            list-height: 2.6rem;
        }
    }
}

@link-color: @white;