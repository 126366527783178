.form{ max-width:75rem; margin:0 0 8rem 0;
  h1{ margin:7rem 0 3rem 0; font-size:4.8rem; line-height:6.4rem; font-weight:700; }
  h2{ margin:6rem 0 2rem 0; font-size:2.4rem; line-height:3.2rem; font-weight:500; color:#fff; }
  .select{
    &>div{ padding:1.8rem 2.2rem !important; height:5.6rem !important; }
    &>span:nth-of-type(2){ padding-right:2.4rem; }
  }
  .multiSelect{
    &>div{ padding:1.2rem 2.2rem !important; height:5.6rem !important; background-color:#32343a !important; }
    &>span:nth-of-type(2){ padding-right:2.4rem; }
  }
  .marginTop{ margin-top:1.9rem; }
  .labels{
    label{ margin:0 0 2rem 0; display:inline-block; }
  }
  .switcherCol{ margin:0 0 2.4rem 0; display:flex; justify-content:space-between; align-items:center; color:#fff; font-size:1.4rem; font-weight:500;
    button{ height:3.2rem; width:5.7rem;
      &>div:nth-of-type(1){ width:2.6rem; height:2.6rem; margin:0.1rem;
        &:before{ border-radius:50%; }
      }
      &[aria-checked="false"]{ background:#141414;
        &>div:nth-of-type(1){ width:2.6rem; height:2.6rem; margin:0.1rem;
          &:before{ background:#666666; }
        }
      }
      &[aria-checked="true"]{ 
        &>div:nth-of-type(1){ left:2.7rem; }
      }
    }
  }
  .number{ background-color:transparent;
    input[disabled]{ background-color:#22242a !important; }
  }
  .wysiwyg{
    &>div>div>div>div>div{
      &>div:nth-of-type(1){ border:none; background-color:#22242a; border-top-left-radius:1.2rem; border-top-right-radius:1.2rem; position:relative; z-index:5; }
      &>div:nth-of-type(2){ border:none; background-color:#32343A; border-bottom-left-radius:1.2rem; border-bottom-right-radius:1.2rem; height:20rem;
        p, ul li, ol li{ font-family:'Poppins', sans-serif !important; font-size:1.4rem; }
        h1{ margin:2rem 0; font-size:2.8rem; line-height:3.6rem; }
        h2{ font-family:'Poppins', sans-serif !important; margin:2rem 0; font-size:2.2rem; line-height:2.8rem; }
        h3{ font-family:'Poppins', sans-serif !important; margin:2rem 0; font-size:1.8rem; line-height:2.4rem; }
      }
    }
  }
  .buttons{ margin:4rem 0 0 0; display:flex; align-items:center; flex-wrap:wrap; }
  .btnMain{ height:4.6rem; border-radius:0.4rem; border:none; background:#3f8cff; font-size: 1.4rem; line-height: 1.6rem; padding: 1.5rem 2.4rem; color: #fff; font-weight: 500; margin:0 1.2rem 0 0; border-radius:1.2rem; transition: all 0.3s;
    &:hover {
      color: #3f8cff;
      background: #fff;
    }
  }
  .btnSecondary{ height:4.6rem; border-radius:0.4rem; border:none; background:#32343A; font-size: 1.4rem; line-height: 1.6rem; padding: 1.5rem 2.4rem; color: #fff; font-weight: 500; margin:0 1.2rem 0 0; border-radius:1.2rem; transition: all 0.3s;
    &:hover {
      color: #32343A;
      background: #fff;
    }
  }
}