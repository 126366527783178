.loadMore {
    text-align: center;
    margin: 4rem 0 0 0;
    button {
        background: #242731;
        height: 5.6rem;
        border-radius: 1.2rem;
        padding: 1.8rem 2.2rem;
        color: #808191;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
        border: none;
        transition: all 0.3s;
        &:hover {
            background: #3f8cff;
            color: #fff;
        }
    }
}

.header {
    display: flex;
    align-items: flex-start;

    h1 {
        flex: 1;
        font-size: 4.8rem;
        line-height: 6.4rem;
        color: #fff;
        font-weight: 700;
        margin: 0 0 2rem 0;
    }

    .selector {
        display: flex;
        align-items: center;

        label {
            font-size: 1.7rem;
            font-weight: 600;
            margin-right: 1.7rem;
        }

        .seasons {
            display: flex;
            align-items: center;
        }
    }
}

.seasonsList {
    padding: 0 0 6rem 0;
    ul li {
        border-bottom: none !important;
        padding: 0.8rem 0;
    }
}
