.header {
    width: 100%;
    display: flex;
    align-items: center;

    p {
        font-size: 4rem;
        line-height: 5rem;
        color: #ffffff;
        font-weight: 600;
        margin: 0.5rem 0;
    }
    button {
        margin-left: 1.5rem;
        height: 5.6rem;
        border-radius: 1.2rem;
        border: 1px solid rgba(228, 228, 228, 0.1);
        font-size: 1.4rem;
        line-height: 2.4rem;
        color: #fff;
        font-weight: 700;
        transition: all 0.3s;
        span {
            width: 1.7rem;
            height: 1.7rem;
            margin-right: 0.7rem;
            color: transparent;
            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &:hover {
            background-color: #3f8cff;
            color: #fff;
        }
    }
}

.headerButtons {
    margin-left: auto;
}

.subheader {
    width: 100%;
    h2 {
        font-size: 1.8rem;
        margin: 0;
    }
}

.selected span {
    color: #5f75ee !important;
}

.unselected span {
    color: #808191 !important;
}

.select {
    margin-left: 1.5rem;
}

.table {
    & > div > div > div {
        background: transparent;
    }
    & > div > div > ul {
        margin-bottom: 8rem !important;
    }
    table {
        margin-top: 2rem;
        thead {
            background: rgba(35, 37, 44, 0.3);
            th {
                color: #fff;
                font-size: 1.2rem;
                font-weight: 500;
                letter-spacing: 1px;
                text-transform: uppercase;
                background: #291733;
                border: none;
                padding: 1.7rem 1.7rem;
                white-space: break-spaces;
                &:before {
                    display: none;
                }
                &:first-of-type {
                    border-bottom-left-radius: 1.2rem !important;
                    border-top-left-radius: 1.2rem !important;
                    width: 10rem !important;
                    min-width: 10rem !important;
                    max-width: 10rem !important;
                }
                &:nth-of-type(2) {
                    width: 10.2rem !important;
                    min-width: 10.2rem !important;
                    max-width: 10.2rem !important;
                }
                &:last-of-type {
                    border-bottom-right-radius: 1.2rem !important;
                    border-top-right-radius: 1.2rem !important;
                }
                .rankMob {
                    display: none;
                }
            }
        }
        tbody {
            tr {
                td {
                    border-bottom: none;
                    padding: 2rem 1.5rem;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    font-weight: 500;
                    &:first-of-type {
                        border-bottom-left-radius: 1.2rem !important;
                        border-top-left-radius: 1.2rem !important;
                        width: 10rem !important;
                        min-width: 10rem !important;
                        max-width: 10rem !important;
                    }
                    &:last-of-type {
                        border-bottom-right-radius: 1.2rem !important;
                        border-top-right-radius: 1.2rem !important;
                    }
                    &:nth-of-type(2) {
                        padding: 0 1.5rem;
                        width: 10.2rem !important;
                        min-width: 10.2rem !important;
                        max-width: 10.2rem !important;
                        & > a {
                            height: 100%;
                            display: block;
                            & > div {
                                height: 100%;
                                display: block;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                        & > div {
                            height: 100%;
                            display: block;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                    a:hover {
                        color: #fff;
                        text-decoration: underline;
                    }
                    .flag {
                        width: 2.4rem;
                        svg {
                            width: 2.4rem;
                        }
                    }
                    .rank {
                        display: inline-block;
                        width: 4rem;
                    }
                }
                &:hover {
                    &:nth-of-type(odd) td {
                        background-color: #3e152c;
                    }
                    &:nth-of-type(even) td {
                        background-color: #2b1828;
                    }
                }
            }
            tr:nth-of-type(odd) td {
                background-color: #3e152c;
            }
            tr:nth-of-type(even) td {
                background-color: #2b1828;
            }
        }
    }
}

/* rwd */
@media only screen and (max-width: 1200px) {
    .select {
        margin-left: 0;
        margin-right: 1.5rem;
    }
}

@media only screen and (max-width: 900px) {
    .header p {
        font-size: 3rem;
        line-height: 4rem;
    }
}

@media only screen and (max-width: 767px) {
    .share {
        background-image: url('../../resources/images/ico-share.svg') !important;
    }
}

@media only screen and (max-width: 550px) {
    .table table {
        tbody tr {
            td {
                font-size: 1.4rem;
                .rank {
                    width: 3.5rem;
                }
                .flag {
                    width: 1.8rem;
                    svg {
                        width: 1.8rem;
                    }
                }
            }
            td:first-of-type {
                padding-right: 0.6rem;
                width: 8rem !important;
                min-width: 8rem !important;
                max-width: 8rem !important;
            }
            td:nth-of-type(2) {
                padding: 0 0.5rem;
                width: 5rem !important;
                min-width: 5rem !important;
                max-width: 5rem !important;
                a > div,
                div {
                    width: 5rem !important;
                }
            }
        }
        thead tr {
            th:first-of-type {
                width: 8rem !important;
                min-width: 8rem !important;
                max-width: 8rem !important;
            }
            th {
                .rankMob {
                    display: inline;
                }
                .rankDes {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .table table {
        tbody tr {
            td:first-of-type {
                width: 5rem !important;
                min-width: 5rem !important;
                max-width: 5rem !important;
            }
        }
        thead tr {
            th:first-of-type {
                width: 5rem !important;
                min-width: 5rem !important;
                max-width: 5rem !important;
            }
        }
    }
}

@media only screen and (max-width: 360px) {
    .select {
        width: 12rem !important;
    }
}

.hidden {
    display: none;
}

.affiliateButton {
    height: 4.4rem;
    border-radius: 1.2rem;
    border: 1px solid #555;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0 0 1rem -1px;
    float: right;
    &:hover,
    &[ant-click-animating-without-extra-node='true'] {
        background: #3f8cff;
        border: 1px solid #3f8cff !important;
        color: #fff;
    }
}

.seasonsDropdown {
    margin-left: 2rem;
    & > div {
        background-color: transparent !important;
    }
    & > span {
        background-color: transparent !important;
    }
}
