.header {
    h1 {
        font-size: 3.6rem;
        line-height: 4rem;
        font-weight: 500;
        margin: 0;
    }
}

.form {
    max-width: 76rem;
    h1 {
        display: none;
    }
    .space {
        width: 100%;
        display: block;
    }
}

.buttons {
    margin: 1rem 0 10rem 0;
    & > div > div > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button.save {
            height: 5.6rem;
            border-radius: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.4rem;
            line-height: 2.4rem;
            font-weight: 500;
            background: transparent;
            border: 1px solid rgba(228, 228, 228, 0.1);
            padding: 0 3rem;
            transition: all 0.3s;
            &:hover {
                color: #fff;
                background: #3f8cff;
                border: 1px solid #3f8cff;
            }
            span {
                margin: 0 1rem 0 0;
                svg {
                    width: 2.1rem;
                    height: 2.1rem;
                }
            }
        }
        a button {
            font-size: 1.4rem;
            &:hover {
                background: transparent;
            }
        }
    }
}

/* RWD */
@media only screen and (max-width: 767px) {
    .header h1 {
        display: none;
    }
    .form h1 {
        display: block;
    }
}
