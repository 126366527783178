@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .headerSelect {
        font-size: 1rem;
        letter-spacing: 1px;
        font-weight: 300;
        .addUser { height:4.4rem;
            & > div {
                height:4.4rem !important;
                background: transparent !important;
                border: 1px solid rgba(228, 228, 228, 0.1) !important;
                line-height:2rem;
                padding:1.1rem 2rem 1.1rem 1.6rem !important;
                transition:all .3s;
                &>span{ color:#fff; }
                &:hover{ background-color:rgba(255,255,255,.05) !important; }
            }
            & > span {
                background: transparent !important;
                right:1.6rem !important;
            }
        }
    }
}


.familyNav{  margin:0 0 4rem 0;
    .flex{ display:flex; margin:0 0 2rem 0;
        h1 {
            font-family:'agency-fb',sans-serif;
            font-size: 4.8rem;
            line-height: 5.4rem;
            color: #fff;
            font-weight: 700;
            text-transform:uppercase;
            margin: 0 0 0 0;
            position: relative;
            z-index: 2;
        }

        .back {
            width: 2rem;
            height: 5.4rem;
            margin: 0 1rem 0 0;
            background-image: none !important;
            border:none;
            span {
                width: 2rem;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                svg {
                    display: block !important;
                    width: 1rem;
                    height: 1.4rem;
                    z-index: 3;
                    color: transparent;
                }
            }
            &:hover{ background-color:transparent; }
        }
    }
}

.table {
    background: transparent;
    margin: 2rem 0 5rem 0;
    border-radius: 0.8rem;
    table {
        background: #1f2128;
        margin: 0 0 0 0;
        thead {
            tr {
                th {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    color: #fff;
                    border-bottom: none;
                    padding: 2rem 1.5rem;
                }
            }
        }
        tbody {
            & > tr {
                & > td {
                    padding: 1.5rem 1.5rem;
                    border-bottom: none;
                    a {
                        color: inherit;
                        &:hover {
                            color: inherit;
                            text-decoration: underline;
                        }
                    }
                }
                &:nth-of-type(odd) td {
                    background: #1f2128;
                }
                &:nth-of-type(even) td {
                    background: #242731;
                }
                &:last-of-type {
                    td:first-of-type {
                        border-bottom-left-radius: 1.2rem;
                    }
                    td:last-of-type {
                        border-bottom-right-radius: 1.2rem;
                    }
                }
            }
        }
    }

    .name {
        color: #3F8CFF;
        font-weight:600;
        font-size:1.5rem;
        line-height:2.1rem;
        text-decoration:none;
        &:hover{ color: #3F8CFF; text-decoration:underline; }
    }
    a{
        text-decoration: underline;
        color:inherit;
        &:hover{ text-decoration:none; }
    }
}

.modal {
    width: 478px;
    height: 600px;
}

.players {
    font-size: 2.1rem;
    line-height:3.2rem;
    margin-bottom: 2rem;
    font-weight:500;
}

.actions {
    border:none;
    padding:0 1rem;
    height:3rem;
    span {
        font-size: 3rem;
        height:3rem;
        color: #fff;
    }
    &:hover, &:focus{ background:transparent !important; }
}


/* rwd */
@media only screen and (max-width: 767px) {
    .familyNav{
        .flex{
            .back{ height:2.8rem; }
            h1{ font-size:2.4rem; line-height:2.8rem; }
        }
    }
}