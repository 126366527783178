.container {
    background: rgba(30, 47, 110, 1);
    background: linear-gradient(32.79deg, #c51f36 -10.13%, #1e2f6e 74.71%);
    min-height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0 1.4rem;

    img {
        width: 15.4rem;
        height: auto;
        margin: 7rem 0 4rem 0;
    }
}

.panel {
    max-width: 47rem;
    width: 100%;
    position: relative;
    margin-bottom: 20rem;

    .shadow {
        position: absolute;
        left: 3rem;
        top: 1rem;
        bottom: 0.5rem;
        right: 3rem;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.5);
        z-index: 0;
    }

    .body {
        border-radius: 4rem;
        background: #242731;
        position: relative;
        z-index: 1;
        padding: 4.8rem;

        h1 {
            font-size: 4rem;
            line-height: 6rem;
            letter-spacing: -1px;
            font-weight: 600;
            margin: 0 0 2rem 0;
        }

        .link,
        .password {
            padding: 0;
            color: #3f8cff;
            font-size: 1.4rem;
            line-height: 16px;
            margin: 0 0 4rem 0;
            height: auto;
            display: inline-block;
            border: none;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        .password {
            margin: 0 0 1rem 0;
        }
    }
}

@media only screen and (max-width: 500px) {
    .container img {
        max-width: 8.7rem;
        margin: 5rem 0 1.4rem 0;
    }
    .panel .body {
        padding: 5rem 2rem;
    }
}
