.head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4.5rem 3.5rem;
    position: relative;
    height: 20rem;
    .orgLogo {
        flex: 1 0 28.5rem;
        max-width: 28.5rem;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    h1 {
        font-size: 7rem;
        line-height: 10rem;
        color: #fff;
        font-weight: 600;
        margin: 0 0 0 4.5rem;
    }
    .top100 {
        flex: 1 0 10rem;
        max-width: 10rem;
        margin-left: auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.tabs {
    padding: 0 0 3.5rem 0;
    height: calc(100vh - 20rem);
    & > div:nth-of-type(1) {
        position: absolute;
        top: 4.5rem;
        right: 17.5rem;
        margin: 0;
        height: 11rem;
        max-width: calc(100vw - 110rem);
        &:before {
            display: none;
        }
        & > div:nth-of-type(1) {
            align-self: center;
            & > div:nth-of-type(1) {
                & > div {
                    background: transparent;
                    border: none;
                    color: #fff;
                    font-size: 2.5rem;
                    line-height: 3rem;
                    white-space: nowrap;
                    font-weight: 500;
                    padding: 0;
                    margin: 0 3rem !important;
                }
            }
        }
        & > div:nth-of-type(2) {
            svg{ width:3rem; height:auto; }
        }
    }
    & > div:nth-of-type(2) {
        padding: 0 3.5rem;
        overflow: hidden;
    }
}

@media only screen and (min-width: 3000px) {
    .head {
        padding: 11rem 8rem;
        height: 44rem;
        .orgLogo {
            flex: 1 0 57rem;
            max-width: 57rem;
        }
        h1 {
            font-size: 14rem;
            line-height: 14rem;
            margin: 0 0 0 9rem;
        }
        .top100 {
            flex: 1 0 20rem;
            max-width: 20rem;
        }
    }
    .tabs {
        padding: 0 0 7rem 0;
        height: calc(100vh - 44rem);
        & > div:nth-of-type(1) {
            top: 11rem;
            right: 36rem;
            height: 22rem;
            max-width: calc(100vw - 213rem);
            & > div:nth-of-type(1) > div:nth-of-type(1) > div {
                margin: 0 6rem !important;
                font-size: 5rem;
                line-height: 6.4rem;
            }
            & > div:nth-of-type(2){ margin-left:2rem;
                svg{ width:4rem; }
            }
        }
    }
}


@media only screen and (max-width: 1700px) {
    .head {
        padding: 3rem 3rem;
        height: 14rem;
        .orgLogo {
            flex: 1 0 24rem;
            max-width: 24rem;
        }
        h1 {
            font-size: 3rem;
            line-height: 3rem;
            margin: 0 0 0 3rem;
        }
        .top100 {
            flex: 1 0 6rem;
            max-width: 6rem;
        }
    }
    .tabs {
        padding: 0 0 7rem 0;
        height: calc(100vh - 14rem);
        & > div:nth-of-type(1) {
            top: 6rem;
            right: 10rem;
            height: 2rem;
            max-width: calc(100vw - 68rem);
            & > div:nth-of-type(1) > div:nth-of-type(1) > div {
                margin: 0 3rem !important;
                font-size: 2rem;
                line-height: 3.4rem;
            }
            & > div:nth-of-type(2){
                svg{ width:2rem; }
                button{ padding:2px 16px; }
            }
        }
        & > div:nth-of-type(2) {
            padding: 0 3rem;
        }
    }
}

@media only screen and (max-height: 1050px) {
    .tabs{ padding:0 0 2rem 0;  height: calc(100vh - 15rem); }
}