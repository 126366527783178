.modal > div:nth-of-type(2) {
    padding: 1rem;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .remove {
        padding: 0 !important;
        display: flex;
        align-items: center;
        background: transparent !important;
        color: #b2b3bd;
        font-weight: 400 !important;
        span {
            width: 1.9rem;
            height: 2.3rem;
            margin: 0 1rem 0 0;
            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .actionButtons {
        display: flex;
    }
}

.player {
    display: flex;
    justify-content: space-between;
    .photo {
        flex: 1 0 16.3rem;
        max-width: 16.3rem;
        & > div {
            margin-bottom: 1rem;
        }
        .image {
            img {
                width: 100%;
                height: auto;
                border-radius: 1.6rem;
            }
            & > div > div > div > div > div {
                border-radius: 1.6rem !important;
            }
        }
        .upload {
            height: 4rem;
            font-size: 1.4rem;
            font-weight: 600;
            border-radius: 1.2rem;
            background: #32343a;
            & > span {
                padding: 0;
            }
            &:hover {
                border-color: #777;
            }
        }
    }
    .edit {
        flex: 1;
        padding-left: 4rem;
        & > div {
            margin-bottom: 1rem;
        }
        h2 {
            font-size: 2.4rem;
            line-height: 3rem;
            font-weight: 500;
            margin: 1.5rem 0 1rem 0;
            color: #fff;
        }
        .insideLabel {
            position: relative;
            & > div:nth-of-type(1) {
                position: absolute;
                top: 0.5rem;
                padding: 0 0 0 2.3rem;
                z-index: 2;
            }
            input {
                padding: 2.8rem 2.3rem 0.8rem 2.3rem;
            }
            .select {
                & > div {
                    padding: 2.8rem 2.3rem 0.8rem 2.3rem;
                }
            }
            .multipleSelect {
                & > div {
                    padding: 2.8rem 2.3rem 0.8rem 2.3rem;
                    min-height:5.6rem;
                    height:auto;
                    &:after{ height:16px; }
                    &>div>div>span{ padding:0; margin:0 1rem 0 0; background:transparent; height:2rem; }
                    &>div>div>div{ height:2rem; margin:0; }
                }
            }
        }
    }
}


/* rwd */
@media only screen and (max-width: 767px) {
    .player{ margin:0 -1rem;
        .edit{ padding-left:0; }
    }
    .footer{ margin:0 -0.5rem; }
}


@media only screen and (max-width: 550px) {
    .footer{ margin:0 -2rem; }
}


@media only screen and (max-width: 450px) {
    .footer{
        .actionButtons{
            button{ padding:1rem 3rem !important; }
        }
    }
}


@media only screen and (max-width: 340px) {
    .footer{
        .actionButtons{
            button{ padding:1rem 2rem !important; }
        }
    }
}